@charset "utf-8";
@use "global" as g;

html {
	background-color: nth(g.$subColor, 1);
	body#page_products & {
		background-color: #fff;
	}
}

/* link
------------------------------------------------------------*/
a {
	color: nth(g.$baseFontLinkColor, 1);
	text-decoration: underline;
	&:hover {
		text-decoration: none;
	}
}
a,button{
	&:hover {
		transition: opacity 0.3s ease;
		@include g.tabletPmin{
			opacity:0.8;
		}
	}
}

/* Fonts
------------------------------------------------------------*/
body{
	color: nth(g.$baseFontColor, 1);
	@include g.fz;
	font-family:g.$baseFontFamily;
	line-height:g.$baseLineHeight;
	letter-spacing:g.$baseLetterSpacing;
	overflow-wrap : break-word;
	-webkit-text-size-adjust: 100%;
	word-wrap : break-word;
	//IE11以下は別のフォントを指定する場合
	// &.IE {
	// 	font-family: "メイリオ",Verdana, "Hiragino Kaku Gothic ProN",  Meiryo, sans-serif;
	// }
	@include g.tabletP {
		@include g.fz(g.$baseFontSp);
	}
}

input,button,textarea,select{
	color: currentColor;
	font-family:g.$baseFontFamily;
	line-height:g.$baseLineHeight;
	font-size: 100%;
	//IE11以下は別のフォントを指定する場合
	// .IE &{
	// 	font-family: "メイリオ",Verdana, "Hiragino Kaku Gothic ProN",  Meiryo, sans-serif;
	// }
}

/*
#styleguide
container

```
<div class="container">
左右にpaddingつきます
</div>
```
*/
.container {
	margin-left: auto;
	margin-right: auto;
	max-width:g.$container;
	padding-left:  g.$containerPadding;
	padding-right: g.$containerPadding;
	&.-large {
		max-width:g.$containerLarge;
	}
}

.section{
	& + .section{
		margin-top: 100px;
		@include g.tabletP{
			margin-top: 50px;
		}
	}
}

br{
	&.pc{
		@include g.tabletP{
			display: none;
		}
	}
	&.sp{
		@include g.tabletPmin{
			display: none;
		}
	}
}


[role="tabpanel"] {
	&[aria-hidden="true"] {
		display: none;
	}
	&[aria-hidden="false"] {
		display: block;
	}
}



a[href*="tel:"] {
	@include g.sphonePmin{
		pointer-events: none;
	}
}

body#page_products.-cleaning_machine [data-visible]:not([data-visible = "cleaning_machine"]),
body#page_products.-professional_cleaning [data-visible]:not([data-visible = "professional_cleaning"])
{
	display: none !important;
}