@charset "utf-8";

/*
recruit company
*/


@use "global" as g;
@use 'sass:math';

$_idParent:recruit;
$_id:company;
$_imgPath:'../img/#{$_idParent}/#{$_id}/';

body#page_#{$_idParent}.-#{$_id} {


	.myBlockSection{
		& + .myBlockSection{
			margin-top: 135px;
			@include g.tabletP{
				margin-top: 50px;
			}
		}
		.myBlockSection{
			& + .myBlockSection{
				@include g.tabletP{
				}
			}

		}
	}//.myBlockSection

	.myList{
		display: flex;
		flex-wrap: wrap;
		gap:20px 22px;
		margin-bottom: 20px;
		@include g.tabletP{
			gap:10px;
		}

		&__item{
			background-color: #fff;
			width: calc((100% - 22px*2)/3);
			padding-top: 34px;
			text-align: center;
			@include g.tabletP{
				width: calc((100% - 10px*1)/2);
				padding-top: 5vw;
			}

			&.-wide{
				width: calc(((100% - 22px*2)/3*2) + 22px);
				padding-bottom: 42px;
				@include g.tabletP{
					width: 100%;
					padding-bottom: 5vw;
				}
			}
		}

		&__title{
			@include g.fz(22);
			font-weight: 500;
			@include g.tabletP{
				@include g.fz(16);
			}
		}

		&__column{
			display: flex;
			flex-wrap: wrap;
			margin-top: 20px;

			&-item{
				width: 50%;
				position: relative;
				&:not(:last-child){
					border-right:solid 2px #dcdcdc;
				}

			}

			&-title{
				@include g.fz(18);
				font-weight: 500;
				@include g.tabletP{
					@include g.fz(14);
				}
			}
		}
	}//.myList

	.myBlock01{
		display: flex;
		flex-wrap: wrap;
		background-color: #fff;
		padding: 37px 82px;
		@include g.tabletPmin{
			gap:60px;
		}
		@include g.tabletP{
			padding: 15px;
		}

		&__detail{
			width: calc(100% - 60px - 52%);
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			text-align: center;
			@include g.tabletP{
				width: 100%;
			}

			&-inner{
				width: 100%;
			}

			&-title{
				@include g.fz(22);
				font-weight: 500;
				@include g.tabletP{
					@include g.fz(16);
				}
			}
			&-image{
			}
		}

		&__movie{
			width: 52%;
			@include g.tabletP{
				width: 100%;
			}


			&-inner{
				width: 100%;
				aspect-ratio: 16 / 9;

				iframe{
					width: 100%;
					height: 100%;
				}
			}

		}
	}//.myBlock01

	.myTxtLead{
		margin-bottom: 40px;
		font-weight: 500;
		text-align: center;
	}

	.myBlockChart{
		text-align: center;

		&__caption{
			margin-bottom: 10px;
			@include g.fz(18);
			font-weight: 500;
			@include g.tabletP{
				@include g.fz(14);
			}
		}
	}//.myBlockChart



}
