@charset "utf-8";

@use "global" as g;
@use 'sass:math';

#contents {}
.gContents {
	position: relative;
	overflow: hidden;
	z-index: 1;
	&.-products {
		color: g.$baseFontColorProducts;
	}
}
