@charset "utf-8";

/*
pop_series
*/


@use "global" as g;
@use 'sass:math';

$_idParent:products;
$_id:pop_series;
$_imgPath:'../img/#{$_idParent}/#{$_id}/';

body#page_#{$_idParent}.-#{$_id} {
	.myBlock {
		&__list {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			gap: 30px 100px;
			max-width: 700px;
			margin-top: 30px;
			margin-left: auto;
			margin-right: auto;
			@include g.tabletP{
				gap: 30px 30px;
			}
		}
		&__item {
			width: calc((100% - 100px) / 2);
			text-decoration: none;
			@include g.tabletP{
				width: calc((100% - 30px) / 2);
			}
		}
		&__ttl {
			@include g.fz(18);
			@include g.tabletP{
				@include g.fz(17);
			}
		}
	}//.myBlock

	.myTxtRed {
		color: red;
		margin-bottom: 20px;
	}
}
