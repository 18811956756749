@charset "utf-8";

/*
professional_cleaning personnelsaving
*/


@use "global" as g;
@use 'sass:math';

$_idParentTop:products;
$_idParent:professional_cleaning;
$_id:personnelsaving;
$_imgPath:'../img/#{$_idParentTop}/#{$_idParent}/#{$_id}/';

body#page_#{$_idParentTop}.-#{$_idParent}.-#{$_id} {

	.myBlock01{
		margin-bottom: 75px;
		@include g.tabletPmin{
			display: flex;
			flex-wrap: wrap;
			gap:80px;
		}
		@include g.tabletP{
			margin-bottom: 40px;
		}

		&__detail{
			@include g.tabletPmin{
				width: calc(100% - 42% - 80px);
			}

			@include g.tabletP{
				margin-bottom: 30px;
			}

			p + p{
				margin-top: 26px;
			}
		}

		&__image{
			text-align: center;
			@include g.tabletPmin{
				width: 42%;
			}
		}
	}//.myBlock01

	.myBlock02{
		margin-bottom: 75px;
		text-align: center;
		@include g.tabletP{
			margin-bottom: 40px;
		}
	}//.myBlock02

	.myBlock03{
		max-width: 904px;
		margin: 0 auto;
		padding: 24px 10px;
		border:solid 1px #b8b8b8;
		@include g.fz(18);
		text-align: center;
		@include g.tabletP{
			@include g.fz(15);
		}

		& + .mySection{
			margin-top: 100px;
			@include g.tabletP{
				margin-top: 50px;
			}
		}
	}//.myBlock03


}
