@charset "utf-8";

/*
privacy
*/


@use "global" as g;
@use 'sass:math';

$_id:privacy;
$_imgPath:'../img/#{$_id}/';

body#page_#{$_id} {
	.myTxtLead {
		$_: ".myTxtLead";
		margin-bottom: 80px;
		@include g.tabletP {
			margin-bottom: 54px;
		}
	}//.myTxtLead

	.myBlockSection {
		$_: ".myBlockSection";
		margin-bottom: 80px;
		@include g.tabletP {
			margin-bottom: 54px;
		}
		&__lead {
			margin-bottom: 30px;
			@include g.tabletP {
				margin-bottom: 20px;
			}
		}
	}//.myBlockSection

	.myListOrder {
		$_: ".myListOrder";
		display: table;
		line-height: 1.4;
		&__item {
			counter-increment: myListOrder;
			display: table-row;
			&::before {
				content: counter(myListOrder) ".";
				display: table-cell;
				padding-right: 1em;
			}
			&:not(:last-child) {
				&::after {
					content: "";
					display: block;
					height: 0.75em;
				}
			}
		}
		& #{$_} {
			counter-reset: myListOrder;
			padding-top: 0.75em;
			padding-left: 40px;
			@include g.tabletP {
				padding-left: 28px;
			}
		}
	}//.myListOrder

	.myBlockContact {
		$_: ".myBlockContact";
		&__lead {
			@include g.fz(18);
			font-weight: bold;
			text-align: center;
			margin-bottom: 30px;
			@include g.tabletP {
				@include g.fz(15);
				margin-bottom: 20px;
			}
		}
	}//.myBlockContact
}