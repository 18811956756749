@charset "utf-8";

/*
#overview
modWysiwyg
*/

@use "global" as g;
@use 'sass:math';

/*
#styleguide
.modWysiwygHeader

```
<div class="modWysiwygHeader">
	<p class="modWysiwygHeader__image"><img src="/assets/img/common/wysiwyg/img01.webp" alt=""></p>
	<div class="modWysiwygHeader__head">
		<h1 class="modWysiwygHeader__title">記事タイトル記事タイトル記事タイトル記事タイトル</h1>
		<div class="modListTag01">
			<p class="modListTag01__item">民間企業事業所</p>
			<p class="modListTag01__item">病院</p>
		</div>
	</div>
	<div class="modWysiwygHeader__head">
		<h1 class="modWysiwygHeader__title">記事タイトル記事タイトル記事タイトル記事タイトル</h1>
		<p class="modWysiwygHeader__date">2023.00.00</p>
		<p class="modWysiwygHeader__category02">お知らせ</p>
	</div>
	<div class="modWysiwygHeader__category">
		<p class="modWysiwygHeader__category-title">洗濯物</p>
		<p class="modWysiwygHeader__category-txt">フェイタオル・バスタオル・おしぼり・食事用エプロン・パジャマ・私服・オムツカバー・清拭タオル</p>
	</div>
</div><!-- /.modWysiwygHeader -->
```
*/
.modWysiwygHeader {
	$_: &;
	margin-bottom: 59px;
	@include g.tabletP {
		margin-bottom: 40px;
	}
	&__head {
		margin-bottom: 34px;
		@include g.tabletP {
			margin-bottom: 23px;
		}
	}
	&__image {
		margin-bottom: 54px;
		@include g.tabletP {
			margin-bottom: 36px;
		}
		img {
			width: 100%;
		}
	}
	&__title {
		@include g.fz(30);
		line-height: math.div(40, 30);
		margin-bottom: 23px;
		@include g.tabletP {
			@include g.fz(20);
			margin-bottom: 19px;
		}
	}
	&__category {
		&-title {
			border-bottom: 1px solid #B8B8B8;
			@include g.fz(20);
			line-height: math.div(30, 20);
			padding-bottom: 10px;
			@include g.tabletP {
				@include g.fz(17);
				margin-bottom: 9px;
			}
			&:not(:last-child) {
				margin-bottom: 17px;
				@include g.tabletP {
					margin-bottom: 14px;
				}
			}
		}
	}

	&__category02 {
		display: inline-block;
		border:solid 1px nth(g.$baseFontColor,1);
		padding: 5px ;
		min-width: 95px;
		@include g.fz(13);
		font-weight: 500;
		line-height: 1.2;
		text-align: center;
		@include g.tabletP{
			padding: 3px ;
			min-width: 60px;
			@include g.fz(10);
			line-height: 1;
		}
	}

	&__date{
		display: inline-block;
		margin-right: 5px;
		color: nth(g.$mainColor,1);
		font-weight: 500;
		font-family: g.$fontAlpha;
		line-height: 1.5;
	}

}//.modWysiwygHeader

/*
#styleguide
.modWysiwygBody

```
<p>
	　特別養護老人ホーム、知的・身体障がい者入所施設、児童養護施設など、入居者数の多い福祉施設の洗濯・乾燥作業を効率よく済ませるためには、業務用の洗濯・乾燥機は欠かせません。<br>
	　洗濯容量に応じて20～100㎏まで幅広く対応するラインナップは、大幅な時間効率の改善はもちろん、機械の耐久性や洗浄・乾燥力に優れ、多くの福祉施設の皆様から感謝の声を頂いております。<br>
	　特別養護老人ホーム、知的・身体障がい者入所施設、児童養護施設など、入居者数の多い福祉施設の洗濯・乾燥作業を効率よく済ませるためには、業務用の洗濯・乾燥機は欠かせません。
</p>
<ul>
	<li>リスト</li>
	<li>リスト</li>
	<li><a href="#">リスト</a></li>
</ul>
<ol>
	<li>リスト</li>
	<li>リスト</li>
	<li><a href="#">リスト</a></li>
</ol>
<p><a href="#">リンクテキスト</a></p>
<table class="-sizeAuto">
	<tr>
		<th></th>
		<td>投入重量</td>
		<td>1日の運転回数</td>
		<td>必要台数</td>
	</tr>
	<tr>
		<th>洗濯機26kg</th>
		<td>20.8kg</td>
		<td>11回</td>
		<td>2台</td>
	</tr>
	<tr>
		<th>乾燥機26kg</th>
		<td>20.8kg</td>
		<td>11回</td>
		<td>2台</td>
	</tr>
	<tr>
		<th>汚物除去機8kg</th>
		<td>6.4kg</td>
		<td>3回</td>
		<td>1台</td>
	</tr>
</table>
```
*/
.modWysiwygBody {
	$_: &;
	ul {
		padding-left: 20px;
		@supports (-moz-user-select: all) {
			& {
				padding-left: 1rem;
			}
		}
		li {
			list-style-type: revert;

		}
	}
	ol {
		counter-reset: wysiwyg_ol;
		li {
			counter-increment: wysiwyg_ol;
			&::before {
				content: counter(wysiwyg_ol) ". ";
			}
		}
	}
	a:not(.modBtn01):not(.modBtn02):not(.modBtn03:not(.-inline)) {
		color: nth(g.$mainColor, 1);
		@at-root #{$_}.-products a:not(.modBtn01):not(.modBtn02):not(.modBtn03:not(.-inline)) {
			color: nth(g.$mainColorProducts, 1);
		}
	}
	table {
		// ._table.scss で定義
		& + p {
			@include g.fz(14);
			margin-top: 15px;
			@include g.tabletP {
				@include g.fz(12);
				margin-top: 13px;
			}
		}
	}
}//.modWysiwygBody

/*
#styleguide
.modWysiwygBlock01

```
<div class="modWysiwygBlock01">
	<p class="modWysiwygBlock01__txt modWysiwygEditor">テキスト（TinyMCE）が入りますテキスト（TinyMCE）が入りますテキスト（TinyMCE）が入りますテキスト（TinyMCE）が入りますテキスト（TinyMCE）が入ります</p>
	<div class="modWysiwygBlock01__imagebox">
		<p class="modWysiwygBlock01__image"><img src="/assets/img/common/wysiwyg/img02_01.webp" alt=""></p>
		<p class="modWysiwygBlock01__caption">キャプションキャプションキャプションキャプションキャプションキャプション</p>
	</div>
</div>

<div class="modWysiwygBlock01 -sizeMedium">
	<div class="modWysiwygBlock01__imagebox">
		<p class="modWysiwygBlock01__image"><img src="/assets/img/common/wysiwyg/img04_02.webp" alt=""></p>
		<p class="modWysiwygBlock01__caption">キャプションキャプションキャプションキャプションキャプションキャプション</p>
	</div>
</div>

<div class="modWysiwygBlock01 -sizeSmall">
	<div class="modWysiwygBlock01__imagebox">
		<p class="modWysiwygBlock01__image"><img src="/assets/img/common/wysiwyg/img04_03.webp" alt=""></p>
		<p class="modWysiwygBlock01__caption">キャプションキャプションキャプションキャプションキャプションキャプション</p>
	</div>
</div>
```
*/
.modWysiwygBlock01 {
	$_: &;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 100%;
	height: 100%;
	margin-left: auto;
	margin-right: auto;
	&.-sizeMedium {
		@include g.tabletPmin {
			width: 80%;
		}
	}
	&.-sizeSmall {
		@include g.tabletPmin {
			width: 60%;
		}
	}
	&:not(:last-child) {
		margin-bottom: 60px;
		@include g.tabletP {
			margin-bottom: 40px;
		}
	}
	&__txt {
		margin-bottom: 18px;
		@include g.tabletP {
			margin-bottom: 15px;
		}
	}
	&__image {
		img {
			width: 100%;
		}
	}
	&__caption {
		@include g.fz(15);
		margin-top: 18px;
		@include g.tabletP {
			@include g.fz(13);
			margin-top: 15px;
		}
	}
}//.modWysiwygBlock01

/*
#styleguide
.modWysiwygBlock02

```
<div class="modWysiwygBlock02">
	<div class="modWysiwygBlock02__body">
		<p class="modWysiwygBlock02__txt modWysiwygEditor">テキストが入りますテキストテキストが入りますテキストテキストが入りますテキストテキストが入りますテキストテキストが入りますテキストテキストが入りますテキストテキストが入りますテキスト</p>
	</div>
	<p class="modWysiwygBlock02__image"><img src="/assets/img/common/wysiwyg/img03.webp" alt=""></p>
</div>

<div class="modWysiwygBlock02 -imageRight">
	<div class="modWysiwygBlock02__body">
		<p class="modWysiwygBlock02__txt modWysiwygEditor">テキストが入りますテキストテキストが入りますテキストテキストが入りますテキストテキストが入りますテキストテキストが入りますテキストテキストが入りますテキストテキストが入りますテキスト</p>
	</div>
	<p class="modWysiwygBlock02__image"><img src="/assets/img/common/wysiwyg/img03.webp" alt=""></p>
</div>
```
*/
.modWysiwygBlock02 {
	$_: &;
	margin-left: auto;
	margin-right: auto;
	@include g.tabletPmin {
		display: flex;
		justify-content: space-between;
	}
	&:not(:last-child) {
		margin-bottom: 60px;
		@include g.tabletP {
			margin-bottom: 40px;
		}
	}
	&__imagebox {
		@include g.tabletPmin {
			max-width: 486px;
			width: percentage(math.div(486px, 1000px));
			#{$_}:not(.-imageRight) & {
				order: -1;
			}
		}
	}
	&__image {
		img {
			width: 100%;
		}
	}
	&__caption {
		@include g.fz(15);
		margin-top: 18px;
		@include g.tabletP {
			@include g.fz(13);
			margin-top: 15px;
		}
	}
	&__body {
		@include g.tabletPmin {
			max-width: 425px;
			width: percentage(math.div(487px, 1000px));
		}
	}
}//.modWysiwygBlock02
/*
#styleguide
.modWysiwygGrid

```
```
*/
.modWysiwygGrid {
	$_: &;
	display: grid;
	gap: 27px 27px;
	grid-template-columns: repeat(1, 1fr);
	width: 100%;
	@include g.tabletPmin {
		&.-column02 {
			grid-template-columns: repeat(2, 1fr);
		}
		&.-column03 {
			grid-template-columns: repeat(3, 1fr);
		}
	}
	@include g.tabletP {
		&.-column02Sp {
			grid-template-columns: repeat(2, 1fr);
			column-gap: 18px;
		}
		&.-column03Sp {
			grid-template-columns: repeat(3, 1fr);
			column-gap: 15px;
		}
	}
	&:not(:last-child) {
		margin-bottom: 60px;
		@include g.tabletP {
			margin-bottom: 40px;
		}
	}
	&__item {
		display: block;
		text-decoration: none;
		color: inherit;
	}
}//.modWysiwygGrid

/*
#styleguide
.modWysiwygBlockMovie

```
<div class="modWysiwygBlockMovie"><iframe width="560" height="315" src="https://www.youtube.com/embed/us6beVqLCoA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></div>
```
*/
.modWysiwygBlockMovie {
	$_: &;
	position: relative;
	margin-left: auto;
	margin-right: auto;
	@include g.tabletPmin {
		width: 80%;
	}
	&::before {
		content: "";
		display: block;
		position: relative;
		padding-top: percentage(math.div(440, 800));
		z-index: 1;
	}
	&:not(:last-child) {
		margin-bottom: 60px;
		@include g.tabletP {
			margin-bottom: 40px;
		}
	}
	& > * {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 2;
	}
}//.modWysiwygBlockMovie