@charset "utf-8";

/*
recruit development
*/


@use "global" as g;
@use 'sass:math';

$_idParent:recruit;
$_id:development;
$_imgPath:'../img/#{$_idParent}/#{$_id}/';

body#page_#{$_idParent}.-#{$_id} {

	.myTxtLead{
		margin-bottom: 80px;
		font-weight: 500;
		@include g.tabletP{
			margin-bottom: 30px;
		}
	}//.myTxtLead

	.myBlockSection{
		& + .myBlockSection{
			margin-top: 100px;
			@include g.tabletP{
				margin-top: 50px;
			}
		}
		.myBlockSection{
			& + .myBlockSection{
				@include g.tabletP{
				}
			}

		}
	}//.myBlockSection

	.myList01{
		$_:'.myList01';
		&__title{
			margin-bottom: 20px;
			@include g.fz(26);
			font-weight: 500;
			line-height: math.div(30,26);
			@include g.tabletP{
				margin-bottom: 10px;
				@include g.fz(18);
			}
		}

		&__detail{
			& + #{$_}__title{
				margin-top: 30px;
			}
		}
	}//.myList01


}
