@charset "utf-8";

/*
#overview
modNav
*/

@use "global" as g;
@use 'sass:math';

/*
#styleguide
modNav01

```
<div class="modNav01">
	<ul class="modNav01__inner">
		<li class="modNav01__item -business">
			<a class="modNav01__link" href="#business">
				事業内容<span class="modNav01__icon"></span>
			</a>
		</li>
		<li class="modNav01__item -company">
			<a class="modNav01__link" href="#company">
				会社概要<span class="modNav01__icon"></span>
			</a>
		</li>
		<li class="modNav01__item -history">
			<a class="modNav01__link" href="#history">
				沿革<span class="modNav01__icon"></span>
			</a>
		</li>
	</ul>
</div><!-- /.modNav01 -->
```
*/

.modNav01 {
	$_: &;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 50px;
	max-width:g.$container;
	padding-left:  g.$containerPadding;
	padding-right: g.$containerPadding;

	&__inner {
		display: flex;
		justify-content: center;

		@include g.tabletP{
			flex-wrap: wrap;
			gap: 20px 0;
		}
		&:before {
			@include g.tabletP {
				content: "";
				display: block;
				width: calc(100% / 3);
				order: 1;
			}
		}
		&::after {
			@include g.tabletP{
				content: "";
				display: block;
				width: calc(100% / 3);
			}

		}
	}
	&__item {
		width: 20%;
		border-left: 1px solid #EBEBEB;
		@include g.tabletP{
			width: calc(100% / 3);
		}
		&:last-child {
			border-right: 1px solid #EBEBEB;
		}

		$_icon:(
			business:(
				dir:'common',
				name:'_business',
				width:50,
				height:57,
				width_sp:40,
				height_sp:47,
			),
			company:(
				dir:'common',
				name:'_company',
				width:46,
				height:57,
				width_sp:36,
				height_sp:47,
			),
			history:(
				dir:'common',
				name:'_history',
				width:53,
				height:57,
				width_sp:43,
				height_sp:47,
			),
			sustainability01:(
				dir:'sustainability',
				name:'01',
				width:62,
				height:57,
				width_sp:51,
				height_sp:47,
			),
			sustainability02:(
				dir:'sustainability',
				name:'02',
				width:55,
				height:55,
				width_sp:47,
				height_sp:47,
			),
			sustainability03:(
				dir:'sustainability',
				name:'03',
				width:81,
				height:62,
				width_sp:62,
				height_sp:47,
			),
			sustainability04:(
				dir:'sustainability',
				name:'04',
				width:74,
				height:54,
				width_sp:63,
				height_sp:47,
			),
			sustainability05:(
				dir:'sustainability',
				name:'05',
				width:34,
				height:49,
				width_sp:33,
				height_sp:47,
			),
			philosophy01:(
				dir:'philosophy',
				name:'01',
				width:48,
				height:57,
				width_sp:38,
				height_sp:47,
			),
			philosophy02:(
				dir:'philosophy',
				name:'02',
				width:52,
				height:50,
				width_sp:47,
				height_sp:47,
			),
	  	);

		@each $name,$data in $_icon {
		&.-#{$name}{
			#{$_}__link{
				&::before {
					background-image: url(../img/#{map-get( $data , dir )}/icon#{map-get( $data , name )}.svg);
					width: #{map-get( $data , width )}px;
					height: #{map-get( $data , height )}px;
					@include g.tabletP{
						width: #{map-get( $data , width_sp )}px;
						height: #{map-get( $data , height_sp )}px;
					}
				}
			}
		}
		}

	}

	&__link {
		display: block;
		position: relative;
		width: 100%;
		@include g.fz(17);
		font-weight: 500;
		line-height: math.div(30,17);
		letter-spacing: normal;
		color: #2b2f38;
		text-align: center;
		padding: 108px 0 78px;
		text-decoration: none;
		@include g.tabletP{
			padding: 70px 0 41px;
			@include g.fz(15);
		}
		@media(hover: hover) {
			&:hover{
				opacity: 1;
				color: nth(g.$mainColor,1);
				#{$_}__icon {
					background-color: currentColor;
					&::before {
						background-color: #fff;
					}
				}
			}
		}
		&::before {
			content: "";
			position: absolute;
			top: 28px;
			left: 0;
			right: 0;
			margin: auto;
			background-position: center;
			background-repeat: no-repeat;
			background-size: 100% auto;
			@include g.tabletP{
				top: 15px;
			}
		}
	}
	&__icon {
		background-color: #fff;
		border: 1px solid #fff;
		border-radius: 50%;
		position: absolute;
		bottom: 26px;
		right: 16px;
		right: 0;
		left: 0;
		width: 35px;
		height: 35px;
		margin: auto;
		@include g.tabletP {
			width: 24px;
			height: 24px;
			bottom: 10px;
		}
		&::before {
			content: "";
			background-color: currentColor;
			mask: url(#{g.$cmnPath}icon_arrow01.svg) no-repeat center;
			mask-size: 17px;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			@include g.tabletP {
				mask-size: 12px;
			}
		}
	}
}//.modNav01


/*
#styleguide
modNavAnchor01

```
<ul class="modNavAnchor01">
	<li class="modNavAnchor01__item"><a href="#product01" class="modNavAnchor01__link">洗濯乾燥機</a></li>
	<li class="modNavAnchor01__item"><a href="#product02" class="modNavAnchor01__link">乾燥機</a></li>
	<li class="modNavAnchor01__item"><a href="#product03" class="modNavAnchor01__link">洗濯機</a></li>
</ul>
```
*/
.modNavAnchor01 {
	@include g.tabletPmin {
		display: flex;
		justify-content: center;
		margin-bottom: 50px;
	}
	@include g.tabletP {
		max-width: 450px;
		margin: 0 auto 34px;
	}
	&__item {
		@include g.tabletPmin {
			max-width: 252px;
			width: calc(33.3333% - 11px);
			&:not(:last-child) {
				margin-right: 16.5px;
			}
		}
		@include g.tabletP {
			&:not(:last-child) {
				margin-bottom: 20px;
			}
		}
	}
	&__link {
		background-color: #fff;
		border: 1px solid currentColor;
		border-radius: 5px;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		color: inherit;
		@include g.fz(18);
		line-height: math.div(30, 28);
		text-decoration: none;
		min-height: 70px;
		padding: 10px 25px 10px;
		@include g.tabletP {
			@include g.fz(15);
			min-height: 47px;
			padding: 7px 20px 8px;
		}
		&::after {
			content: "";
			border-bottom: 1px solid currentColor;
			border-right: 1px solid currentColor;
			position: absolute;
			top: 0;
			bottom: 0;
			right: 20px;
			width: 8px;
			height: 8px;
			margin: auto;
			transform: rotate(45deg);
			@include g.tabletP {
				bottom: 2px;
				right: 16px;
				width: 7px;
				height: 7px;
			}
		}
	}
}//.modNavAnchor01