@charset "utf-8";

@use "global" as g;
@use 'sass:math';

#gNav {}
.gNav {
	$_: &;
	@include g.narrowPC {
		background-color: #fff;
	}
	&__inner {
		@include g.narrowPCmin {
			display: flex;
			align-items: center;
		}
		@include g.narrowPC {
			padding: 10px 0 43px;
		}
	}
	&__list {
		@include g.narrowPCmin {
			display: flex;
		}
		@include g.narrowPC {
			margin-bottom: 7px;
		}
	}
	&__item {
		@include g.fz(15);
		line-height: 1.4;
		@include g.narrowPCmin {
			&:not(:last-child) {
				margin-right: 2em;
			}
		}
		@include g.narrowPC {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
		}
	}
	&__link {
		display: flex;
		align-items: center;
		color: inherit;
		text-decoration: none;
		@include g.narrowPC {
			flex-grow: 1;
			padding: 10px 36px 10px;
		}
		.gHeader:not(.-products) &.-corporate {
			color: nth(g.$mainColor, 1);
		}
		body#page_products &.-products,
		body#page_download &.-download,
		body#page_maintenance &.-maintenance {
			color: nth(g.$mainColorProducts, 1);
		}
	}
	&__toggle {
		background: none;
		border: none;
		display: block;
		flex-shrink: 0;
		position: relative;
		width: 65px;
		@include g.narrowPCmin {
			display: none;
		}
		&::after {
			content: "";
			background:
				linear-gradient(to bottom, transparent 7px, currentColor 7px, currentColor 8px, transparent 8px),
				linear-gradient(to right, transparent 7px, currentColor 7px, currentColor 8px, transparent 8px)
			;
			position: absolute;
			top: 0;
			bottom: 0;
			right: 25px;
			width: 15px;
			height: 15px;
			margin: auto;
		}
		&[aria-expanded = "true"] {
			&::after {
				background:
					linear-gradient(to bottom, transparent 7px, currentColor 7px, currentColor 8px, transparent 8px)
				;
			}
		}
	}
	&__lang {
		display: grid;
		grid-template: auto / repeat(auto-fill, auto);
		grid-auto-flow: column;
		@include g.narrowPCmin {
			justify-content: center;
			margin-left: 30px;
			transform: translateY(2px);
		}
		@include g.narrowPC {
			justify-content: flex-start;
			padding: 0 36px;
		}
		&-item {
			color: #A7ABB3;
			@include g.fz(14);
			line-height: 1.4;
			.gHeader.-products & {
				color: #8B8C91;
			}
			&:not(:last-child) {
				&::after {
					content: "\ff5c";
					margin: 0 0.25em;
				}
			}
		}
		&-link {
			color: g.$baseFontLinkColor;
			text-decoration: none;
			.gHeader.-products & {
				color: g.$baseFontLinkColorProducts;
			}
		}
	}
	&__contact {
		$_2: &;
		display: flex;
		gap: 10px;
		@include g.narrowPCmin {
			margin-left: 30px;
		}
		@include g.narrowPC {
			margin-top: 20px;
			padding: 0 36px;
		}
		&-item {
			display: flex;
			flex-shrink: 0;
			&.-item01 {
				img {
					width: 17px;
					@include g.narrowPC {
						width: 15px;
						transform: translateY(1.5px);
					}
				}
				#{$_2}-link {
					@include g.narrowPCmin {
						border-radius: 50%;
						width: 34px;
						height: 34px;
					}
					@include g.narrowPC {
						padding: 8px 16px 9px 17px;
					}
				}
			}
			&.-item02 {
				@include g.narrowPC {
					order: 1;
				}
				img {
					width: 16px;
					@include g.narrowPC {
						width: 14px;
					}
				}
				#{$_2}-link {
					border-radius: 50%;
					@include g.narrowPCmin {
						width: 34px;
						height: 34px;
					}
					@include g.narrowPC {
						width: 30px;
						height: 30px;
					}
				}
			}
		}
		&-link {
			background-color: currentColor;
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 10px;
			line-height: 1;
			.gHeader.-products & {
				color: nth(g.$mainColorProducts, 1);
			}
		}
		&-txt {
			color: #fff;
			@include g.fz(13);
			letter-spacing: 0;
			@include g.narrowPCmin {
				display: none;
			}
		}
	}

	body#page_top .gHeader:not(.-scroll) &{
		$_:'.gNav';

		#{$_}__link,
		#{$_}__lang-link
		{
			@include g.narrowPCmin {
				color:#fff;
				// &.-corporate {
				// 	color: nth(g.$mainColor, 1);
				// }
			}
		}

		#{$_}__lang-item{
			&:not(:last-child){
				&::after{
					@include g.narrowPCmin {
						color:#fff;
					}
				}
			}
		}

	}//#page_top


}//.gNav

.gNavChild {
	$_: &;
	display: none;
	width: 100%;
	@include g.narrowPCmin {
		display: none !important;
	}
	@include g.narrowPC {
		padding: 20px 0;
	}
	&__inner {
		background-color: nth(g.$subColorProducts, 4);
		padding: 20px 0;
	}
	&__link {
		display: block;
		color: inherit;
		text-decoration: none;
		@include g.narrowPC {
			padding: 10px 36px 10px;
		}
		body#page_products.-cleaning_machine &.-products_cleaning_machine {
			color: nth(g.$mainColorProducts, 1);
		}
	}
}//.gNavChild

.gNavSub {
	$_: &;
	background-color: nth(g.$subColor, 1);
	@include g.narrowPCmin {
		border-top: 1px solid #C8C8C8;
		border-bottom: 1px solid #C8C8C8;
		position: absolute;
		top: 100%;
		left: 0;
		width: 100%;
		z-index: 1;
	}
	.gHeader.-products & {
		background-color: nth(g.$subColorProducts, 2);
		color: #fff;
		@include g.narrowPCmin {
			border-top-color: nth(g.$subColorProducts, 2);
			border-bottom-color: nth(g.$subColorProducts, 2);
		}
	}
	&__inner {
		@include g.narrowPCmin {
			display: flex;
			justify-content: center;
			min-height: 59px;
		}
		@include g.narrowPC {
			margin-left: -#{g.$containerPadding};
			margin-right: -#{g.$containerPadding};
			padding: 14px 0 19px;
		}
	}
	&__item {
		@include g.fz(15);
		line-height: 1.4;
		@include g.narrowPCmin {
			display: flex;
		}
	}
	&__link,
	&__toggle {
		display: flex;
		align-items: center;
		position: relative;
		color: inherit;
		text-decoration: none;
		width: 100%;
		padding: 10px 2em;
		@include g.narrowPC {
			padding: 9px 36px;
		}

		body#page_top &.-index,
		body#page_philosophy &.-philosophy,
		body#page_about_us &.-about_us,
		body#page_our_strengths &.-our_strengths,
		body#page_sustainability &.-sustainability,

		body#page_recruit.-index &.-index,
		body#page_recruit.-message &.-message,
		body#page_recruit.-company &.-company,
		body#page_recruit.-development &.-development,
		body#page_recruit.-tasuki_relay &.-tasuki_relay,
		body#page_recruit.-requirements &.-requirements,
		body:not(#page_products) &[aria-expanded = "true"] {
			color: nth(g.$mainColor, 1);
			@include g.narrowPCmin {
				&::before {
					content: "";
					background-color: currentColor;
					position: absolute;
					bottom: -1px;
					left: 0;
					right: 0;
					width: 26px;
					height: 3px;
					margin: auto;
				}
			}
		}

		body#page_products.-case &.-case,
		body#page_products.-index &.-index,
		body#page_products.-list &.-list,
		body#page_products.-product &.-product,
		body#page_products.-strength &.-strength,
		body#page_products.-topics &.-topics,
		body#page_products.-cell_linen_system &.-proposal,
		body#page_products.-personnelsaving &.-proposal,
		body#page_products.-monodukuri &.-monodukuri,
		&[aria-expanded = "true"] {
			@include g.narrowPCmin {
				&::before {
					content: "";
					background-color: currentColor;
					position: absolute;
					bottom: -1px;
					left: 0;
					right: 0;
					width: 26px;
					height: 3px;
					margin: auto;
				}
			}
		}
	}
	&__link {
		&.-blank {
			&::after {
				content: "";
				background: url(#{g.$cmnPath}icon_blank.svg) no-repeat center;
				background-size: 12px;
				width: 12px;
				height: 9px;
				margin-left: 9px;
			}
		}
	}
	&__toggle {
		background: none;
		border: none;
		@include g.narrowPC {
			position: relative;
			&::after {
				content: "";
				background:
					linear-gradient(to bottom, transparent 7px, currentColor 7px, currentColor 8px, transparent 8px),
					linear-gradient(to right, transparent 7px, currentColor 7px, currentColor 8px, transparent 8px)
				;
				position: absolute;
				top: 0;
				bottom: 0;
				right: 25px;
				width: 15px;
				height: 15px;
				margin: auto;
			}
		}
		&[aria-expanded = "true"] {
			@include g.narrowPCmin {
				background: linear-gradient(to top, currentColor 2px, transparent 2px) no-repeat center bottom / 26px;
			}
			@include g.narrowPC {
				&::after {
					background:
						linear-gradient(to bottom, transparent 7px, currentColor 7px, currentColor 8px, transparent 8px)
					;
				}
			}
		}
	}

	body#page_top &{
		$_:'.gNavSub';
		transition:background-color 0.3s ease;

	}//#page_top


	body#page_top .gHeader:not(.-scroll) &{
		$_:'.gNavSub';
		@include g.narrowPCmin {
			background-color: transparent;
			border-top-color: #fff;
			border-bottom-color: #fff;
			color: #fff;
		}

		#{$_}__link{
			@include g.tabletP{
				color: nth(g.$baseFontColor,1);
			}
		}
	}//#page_top

}//.gNavSub

.gNavSubChild {
	$_: &;
	display: none;
	@include g.narrowPCmin {
		position: absolute;
		top: 100%;
		left: -50vw;
		right: -50vw;
		width: 100vw;
		margin: auto;
		padding: 62px 0 62px;
		.gHeader.-products & {
			background-color: nth(g.$subColorProducts, 3);
		}
		&::before {
			content: "";
			background-color: currentColor;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 1px;
			transform-origin: center 0;
			transform: scaleY(.5);
		}
	}
	@include g.narrowPC {
		padding: 12px 0;
	}
	&__outer {
		@include g.narrowPC {
			.gHeader.-products & {
				background-color: nth(g.$subColorProducts, 3);
			}
		}
	}
	&__inner {
		@include g.narrowPCmin {
			display: flex;
			justify-content: center;
		}
		@include g.narrowPC {
			margin-left: -#{g.$containerPadding};
			margin-right: -#{g.$containerPadding};
		}
	}
	&__title {
		flex-shrink: 0;
		@include g.fz(17);
		line-height: 1.4;
		margin-right: 100px;
		@include g.narrowPC {
			display: none;
		}
		&-inner {
			color: inherit;
			text-decoration: none;
		}
	}
	&__list {
		@include g.narrowPCmin {
			display: grid;
			grid-template: auto / repeat(4, auto);
			gap: 14px 0;
			justify-content: space-between;
			flex-basis: 100%;
			max-width: 764px;
		}
		@include g.narrowPC {
			padding: 11px 0;
		}
	}
	&__group {
		@include g.narrowPC {
			padding: 9px 36px 10px;
		}
		&-title {
			@include g.fz(16);
			line-height: 1.4;
			@include g.narrowPCmin {
				margin-bottom: 15px;
			}
		}
	}
	&__item {
		@include g.fz(15);
		line-height: 1.4;
		#{$_}__group & {
			@include g.narrowPCmin {
				&:not(:last-child) {
					margin-bottom: 14px;
				}
			}
		}
		&-image {
			text-align: center;
			margin-bottom: 10px;
			@include g.narrowPC {
				display: none;
			}
		}
		&-txt {
			display: flex;
			@include g.narrowPCmin {
				&::before {
					content: "";
					border-top: 1px solid currentColor;
					border-right: 1px solid currentColor;
					display: inline-block;
					width: 8px;
					height: 8px;
					margin-right: 10px;
					transform: translateY(7px) rotate(45deg);
				}
			}
			@include g.narrowPC {
				padding: 9px 36px 10px;
				#{$_}__group & {
					margin-left: -36px;
					margin-right: -10px;
					padding-left: 56px;
				}
			}
		}
	}
	&__link {
		display: block;
		position: relative;
		color: inherit;
		text-decoration: none;
		height: 100%;
	}
}//.gNavSubChild

.gNavSide {
	$_: &;
	display: flex;
	align-items: flex-end;
	position: fixed;
	top: 0;
	left: 52px;
	height: 100%;
	padding-bottom: 21px;
	transition: opacity .3s, visibility .3s, color .3s;
	z-index: 2;
	@include g.max(1500) {
		opacity: 0;
		visibility: hidden;
	}
	&__item {
		&:not(:last-child) {
			margin-bottom: 20px;
		}
	}
	&__link {
		color: inherit;
		line-height: 1;
		text-decoration: none;
		@include g.tabletPmin {
			@media(hover: hover) {
				&:hover {
					.gHeader:not(.-products) & {
						opacity: 1;
						color: nth(g.$mainColor, 1);
					}
				}
			}
		}

		body#page_philosophy &.-philosophy,
		body#page_about_us &.-about_us,
		body#page_our_strengths &.-our_strengths,
		body#page_sustainability &.-sustainability
		{
			color: nth(g.$mainColor, 1);
		}
	}
	&__titleJp,
	&__titleEn {
		display: block;
	}
	&__titleJp {
		@include g.fz(10);
		margin-bottom: 5px;
	}
	&__titleEn {
		@include g.fz(18);
		font-family: g.$fontAlpha;
		font-weight: 500;
	}

	body#page_top .gHeader:not(.-scroll) + &,
	&:not(.-active) {
		opacity: 0;
		visibility: hidden;
	}
	&.-locked {
		position: absolute;
	}
}//.gNavSide

.gNavSideChild {
	$_: &;
	@include g.fz(13);
	font-weight: 500;
	line-height: 1.4;
	margin-top: 10px;
	padding-left: 10px;
	&__item {
		&:not(:last-child) {
			margin-bottom: 7px;
		}
		sub {
			@include g.fz(10);
			vertical-align: baseline;
		}
	}
	&__link {
		color: inherit;
		text-decoration: none;
	}
}//.gNavSideChild
