@charset "utf-8";

/*
#overview
modTitle
*/

@use "global" as g;
@use 'sass:math';

/*
#styleguide
.modTitlePage01

```
<div class="modTitlePage01">
	<div class="container">
		<h1 class="modTitlePage01__inner">タイトル</h1>
	</div>
</div><!-- /.modTitlePage01 -->
```
*/
.modTitlePage01{
	color: nth(g.$mainColor, 1);
	line-height: math.div(30, 28);
	padding: 60px 0 92px;
	@include g.tabletP {
		padding: 40px 0 62px;
	}
	&__jp {
		@include g.fz(16);
		font-weight: 500;
		@include g.tabletP {
			@include g.fz(13);
		}
		&:not(:last-child) {
			margin-bottom: 2px;
		}
	}
	&__en {
		@include g.fz(60);
		font-family: g.$fontAlpha;
		font-weight: 600;
		@include g.tabletP {
			@include g.fz(40);
		}
	}
}//.modTitlePage01

/*
#styleguide
.modTitle01

```
<header class="modTitle01">
	<h2 class="modTitle01__jp">お知らせ</h2>
	<p class="modTitle01__en">News</p>
</header>
<header class="modTitle01 -center">
	<h2 class="modTitle01__jp">お知らせ</h2>
	<p class="modTitle01__en">News</p>
</header>
```
*/
.modTitle01{
	margin-bottom: 40px;
	color: nth(g.$mainColor, 1);
	&.-center{
		text-align: center;
	}
	&__jp {
		@include g.fz(16);
		line-height: math.div(45, 16);
		font-weight: 500;
		@include g.tabletP {
			@include g.fz(13);
		}
	}
	&__en {
		@include g.fz(70);
		font-weight: 600;
		line-height: 0.8;
		font-family: g.$fontAlpha;
		@include g.tabletP {
			@include g.fz(40);
		}
	}


}//.modTitle01


/*
#styleguide
.modTitle02

```
<header class="modTitle02">
	<h2 class="modTitle02__txt">
		<span class="modTitle02__txt-en">Company Profile</span>
		<span class="modTitle02__txt-ja">会社概要</span>
	</h2>
</header><!-- /.modTitle02 -->
<header class="modTitle02">
	<p class="modTitle02__icon">
		<img src="/assets/img/sustainability/icon01.svg" alt="" width="62">
	</p>
	<h2 class="modTitle02__txt">
		<span class="modTitle02__txt-en">Company Profile</span>
		<span class="modTitle02__txt-ja">会社概要</span>
	</h2>
</header><!-- /.modTitle02 -->
```
*/

.modTitle02 {
	text-align: center;
	margin-bottom: 50px;
	@include g.tabletP{
		margin-bottom: 30px;
	}

	&__icon{
		margin-bottom: 24px;
		@include g.tabletP{
			margin-bottom: 15px;
		}
	}

	&__txt {
		&-ja {
			display: block;
			@include g.fz(45);
			font-weight: 500;
			letter-spacing: normal;
			line-height: 1.4;
			@include g.tabletP{
				margin-top: 5px;
				@include g.fz(26);
			}
		}
		&-en {
			display: block;
			@include g.fz(16);
			color: g.$mainColor;
			font-family: g.$fontAlpha;
			font-weight: 600;
			line-height: 1.2;
			@include g.tabletP{
				@include g.fz(13);
			}
		}
	}
}//.modTitle02

/*
#styleguide
.modTitle03

```
<div class="modTitle03">
	<h3 class="modTitle03__txt">
		<span class="modTitle03__txt-en">On Premises Laundry</span>
		<span class="modTitle03__txt-ja">自社設備</span>
	</h3>
	<p class="modTitle03__lead">自社設備用ランドリーの 多彩なニーズに応える。</p>
</div><!-- /.modTitle03 -->
```
*/

.modTitle03 {
	margin-bottom: 20px;
	&__txt {
		margin-bottom: 17px;
		@include g.tabletP{
			margin-bottom: 5px;
		}
		&-en {
			position: relative;
			display: block;
			@include g.fz(16);
			color: g.$mainColor;
			font-family: g.$fontAlpha;
			font-weight: 600;
			line-height: 1.2;
			letter-spacing: normal;
			padding-left: 33px;
			margin-bottom: 13px;
			@include g.tabletP{
				@include g.fz(13);
				padding-left: 25px;
				margin-bottom: 3px;
			}
			&::before {
				content: "";
				width: 20px;
				height: 3px;
				background: g.$mainColor;
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				margin: auto;
				@include g.tabletP {
					width: 15px;
					height: 2px;
				}
			}
		}
		&-ja {
			display: block;
			@include g.fz(40);
			font-weight: 500;
			letter-spacing: normal;
			line-height: 1.4;
			@include g.tabletP{
				@include g.fz(23);
			}
		}
	}
	&__lead {
		@include g.fz(26);
		font-weight: 500;
		letter-spacing: normal;
		color: g.$mainColor;
		@include g.tabletP{
			@include g.fz(17);
		}
	}
}//.modTitle03

/*
#styleguide
.modTitle04

```
<h2 class="modTitle04">製品仕様</h2>
<h2 class="modTitle04 -large">製品仕様</h2>
<h2 class="modTitle04 -left">製品仕様</h2>
<h2 class="modTitle04 -products">製品仕様</h2>

```
*/
.modTitle04 {
	@include g.fz(28);
	line-height: math.div(30, 28);
	text-align: center;
	margin-bottom: 34px;
	@include g.tabletP {
		@include g.fz(19);
		margin-bottom: 23px;
	}

	&.-left{
		text-align: left;
	}

	&.-products{
		color: nth(g.$baseFontColorProducts,1);
	}

	@at-root .modWysiwygBody.-products & {
		color: nth(g.$baseFontColorProducts,1);
	}

	&.-large{
		@include g.fz(40);
		line-height: 1;
		@include g.tabletP{
			@include g.fz(26);
		}
	}

}//.modTitle04

/*
#styleguide
.modTitle05

```
<h2 class="modTitle05">毎日の洗濯作業をより快適に、より効率的に</h2>
<h2 class="modTitle05 -products">毎日の洗濯作業をより快適に、より効率的に</h2>
```
*/
.modTitle05 {
	border-left: 4px solid nth(g.$mainColor, 1);
	@include g.fz(30);
	line-height: 1.5;
	margin-bottom: 48px;
	padding: 15px 0 15px 25px;
	@include g.tabletP {
		border-left-width: 3px;
		@include g.fz(20);
		margin-bottom: 32px;
		padding: 13px 0 13px 17px;
	}
	&:not(:first-child) {
		margin-top: 66px;
		@include g.tabletP {
			margin-top: 44px;
		}
	}
	&.-products {
		background-color: nth(g.$subColorProducts, 4);
		border-left-color: currentColor;
	}
	@at-root .modWysiwygBody.-products & {
		background-color: nth(g.$subColorProducts, 4);
		border-left-color: currentColor;
	}
}//.modTitle05

/*
#styleguide
.modTitle06

```
<h3 class="modTitle06">毎日の洗濯作業をより快適に、より効率的に</h3>
<h3 class="modTitle06 -products">毎日の洗濯作業をより快適に、より効率的に</h3>
```
*/
.modTitle06 {
	border-bottom: 1px solid nth(g.$mainColor, 1);
	border-left: 1px solid nth(g.$mainColor, 1);
	@include g.fz(26);
	line-height: 1.5;
	margin-bottom: 48px;
	padding: 6px 0 9px 27px;
	@include g.tabletP {
		@include g.fz(18);
		margin-bottom: 32px;
		padding: 5px 0 8px 19px;
	}
	&:not(:first-child) {
		margin-top: 48px;
		@include g.tabletP {
			margin-top: 32px;
		}
	}
	&.-products {
		border-bottom-color: nth(g.$subColorProducts, 2);
		border-left-color: nth(g.$subColorProducts, 2);
	}
	@at-root .modWysiwygBody.-products & {
		border-bottom-color: nth(g.$subColorProducts, 2);
		border-left-color: nth(g.$subColorProducts, 2);
	}
}//.modTitle06

/*
#styleguide
.modTitle07

```
<h4 class="modTitle07">毎日の洗濯作業をより快適に、より効率的に</h4>
<h4 class="modTitle07 -products">毎日の洗濯作業をより快適に、より効率的に</h4>
```
*/
.modTitle07 {
	border-left: 4px solid nth(g.$mainColor, 1);
	@include g.fz(22);
	line-height: 1.5;
	margin-bottom: 48px;
	padding: 1px 0 2px 25px;
	@include g.tabletP {
		border-left-width: 3px;
		@include g.fz(16);
		margin-bottom: 32px;
		padding: 0 0 1px 17px;
	}
	&:not(:first-child) {
		margin-top: 36px;
		@include g.tabletP {
			margin-top: 24px;
		}
	}
	&.-products {
		border-left-color: currentColor;
	}
	@at-root .modWysiwygBody.-products & {
		border-left-color: currentColor;
	}
}//.modTitle07

/*
#styleguide
.modTitleProductsPage01

```
<div class="modTitlePage">
	<div class="container">
		<h1 class="modTitlePage__jp">タイトルが入ります</h1>
		<span class="modTitlePage__en">Title</span>
	</div>
</div>
```
*/
.modTitleProductsPage01{
	padding: 54px 0 60px;
	@include g.tabletP {
		padding: 20px 0 25px;
	}
	&.-color02 {
		background-color: nth(g.$subColorProducts, 4);
	}
	&__inner {
		@include g.fz(38);
		line-height: math.div(40, 38);
		@include g.tabletP {
			@include g.fz(26);
		}
	}
}//.modTitleProductsPage01

/*
#styleguide
.modTitleProducts01

```
<div class="modTitleProducts01">
	<span class="modTitleProducts01__en">FEATURE</span>
	<h2 class="modTitleProducts01__jp">特長</h2>
</div>

<div class="modTitleProducts01">
	<span class="modTitleProducts01__sub">OPL（自家洗濯用ランドリー）</span>
	<span class="modTitleProducts01__en">PRODUCT LINEUP</span>
	<h2 class="modTitleProducts01__jp">製品ラインナップ</h2>
</div>
```
*/
.modTitleProducts01{
	display: flex;
	flex-direction: column;
	align-items: center;
	line-height: 1.5;
	margin-bottom: 33px;
	@include g.tabletP {
		margin-bottom: 23px;
	}
	&__sub {
		@include g.fz(20);
		margin-bottom: 1px;
		@include g.tabletP {
			@include g.fz(15);
		}
	}
	&__en {
		@include g.fz(45);
		font-family: g.$fontAlpha;
		font-weight: 300;
		margin-bottom: -1px;
		@include g.tabletP {
			@include g.fz(31);
		}
	}
	&__jp {
		@include g.fz(16);
		@include g.tabletP {
			@include g.fz(13);
		}
	}
	&.-mb0 {
		margin-bottom: 0;
	}
}//.modTitleProducts01