@charset "utf-8";

@use "global" as g;
@use 'sass:math';

.gBreadcrumb {
	line-height: 1;
	.gContents.-products & {
		background-color: nth(g.$subColorProducts, 2);
	}
	&__inner {
		padding: 19px 0 21px;
		@include g.tabletP {
			padding: 11px 0 14px;
		}
	}
	&__item {
		display: inline;
		@include g.fz(13);
		line-height: 1.4;
		.gContents.-products & {
			color: #fff;
		}
		@include g.tabletP {
			@include g.fz(12);
		}
		&:not(:last-child):after {
			content:'\2212';
			margin: 0 .75em 0 1em;
		}
		&.-home {
		}
	}
	&__link {
		color: inherit;
		text-decoration: none;
		&:hover{
		}
	}
}//.gBreadcrumb