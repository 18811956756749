@charset "utf-8";

/*
philosophy
*/


@use "global" as g;
@use 'sass:math';

$_id:philosophy;
$_imgPath:'../img/#{$_id}/';

body#page_#{$_id} {


	.myBlockSection{
		& + .myBlockSection{
			margin-top: 160px;
			@include g.tabletP{
				margin-top: 80px;
			}
		}
		.myBlockSection{
			& + .myBlockSection{
				margin-top: 100px;
				@include g.tabletP{
					margin-top: 50px;
				}
			}
			.myBlockSection{
				& + .myBlockSection{
					margin-top: 40px;
					@include g.tabletP{
						margin-top: 20px;
					}
				}

			}

		}
	}//.myBlockSection


	.myBlockMessage{

		&Head{
			position: relative;
			margin-bottom: 50px;
			@include g.tabletP{
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				gap:25px 0;
			}

			&__detail{

				@include g.tabletPmin{
					position: absolute;
					top: 20%;
					left: 70px;
				}
				@include g.tabletP{
					width: 100%;
					order:2;
					text-align: right;
				}

				&-job{
					font-weight: 500;
				}

				&-name{
					font-weight: 500;
					@include g.fz(30);
					line-height: math.div(50,30);
					@include g.tabletP{
						@include g.fz(24);
					}
				}
				&-en{
					margin-top: 10px;
					@include g.tabletP{
						img{
							width: 60%;
						}
					}
				}
			}

			&__image{
				text-align: center;
				@include g.tabletP{
					order:1;
				}
			}
		}

		&Lead{
			&__catch{
				margin-bottom: 24px;
				@include g.fz(40);
				line-height: math.div(50,40);
				@include g.tabletP{
					@include g.fz(24);
				}
			}

			&__txt{
				font-weight: 500;
				p + p{
					margin-top: 30px;
				}
			}
		}
	}//.myBlockMessage

	.myBlockStory01{
		text-align: center;
	}//myBlockStory01

	.myBlockMark{
		@include g.tabletPmin{
			display: flex;
			flex-wrap: wrap;
			gap:0 108px;
		}

		&__logo{
			background-color: #fff;
			text-align: center;
			@include g.tabletPmin{
				width: 50%;
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				align-items: center;
			}
			@include g.tabletP{
				padding: 60px 40px;
				margin-bottom: 20px;
			}
		}

		&__detail{
			@include g.tabletPmin{
				width: calc(100% - 108px - 50%);
			}
		}

		&__txt{
			margin-bottom: 26px;
			font-weight: 500;
		}

		&__list{

			&-item{
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				&:not(:last-child){
					margin-bottom: 10px;
				}
			}

			&-color{
				display: block;
				width: 71px;
				height: 30px;
				&.-color01{
					border:solid 1px #EBEBEB;
					background-color: #fff;
				}
				&.-color02{
					background-color: #404040;
				}
				&.-color03{
					background-color: nth(g.$mainColor,1);
				}
			}

			&-txt{
				font-weight: 500;

			}
		}


	}//.myBlockMark
}
