@charset "utf-8";

/*
products_professional_cleaning
*/


@use "global" as g;
@use 'sass:math';

$_idParent:products;
$_id:professional_cleaning;
$_imgPath:'../img/#{$_idParent}/#{$_id}/';

body#page_#{$_idParent}.-#{$_id} {

	$_root: &;

	.myBlockStrength {
		$_: '.myBlockStrength';
		&__txt {
			color: nth(g.$mainColorProducts, 1);
			@include g.fz(35);
			line-height: 1.4;
			text-align: center;
			margin: 0 auto 27px;
			@include g.tabletP {
				@include g.fz(24);
				margin-bottom: 18px;
			}
		}
		&__movie {
			position: relative;
			padding-top: percentage(math.div(565, 1000));
			& > * {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
		}
	}//.myBlockStrength

	.myBlockProduct {
		$_: '.myBlockProduct';
		background-color: #fff;
		padding: 108px 0 106px;
		@include g.tabletP {
			padding: 0 0 71px;
		}
		&__inner {
			max-width: 1900px;
			margin: 0 auto;
			@include g.tabletPmin {
				display: flex;
				flex-direction: row-reverse;
			}
			& > * {
				@include g.tabletPmin {
					flex-basis: 100%;
				}
			}
		}
		&__image {
			img {
				object-fit: cover;
				width: 100%;
				height: 100%;
			}
		}
		&__body {
			display: flex;
			align-items: center;
		}
		&__title {
			@include g.tabletPmin {
				white-space: nowrap;
			}
		}
		&__catch {
			@include g.fz(24);
			line-height: math.div(40, 24);
			text-align: center;
			margin-bottom: 23px;
			@include g.tabletP {
				@include g.fz(20);
				margin-bottom: 20px;
			}
		}
		&__txt {
			@include g.fz(15);
			line-height: math.div(30, 15);
			text-align: center;
			margin-bottom: 32px;
			@include g.tabletP {
				@include g.fz(13);
				margin-bottom: 22px;
			}
		}
		&__btn {
			display: flex;
			justify-content: center;
		}
	}//.myBlockProduct

	.myListProduct {
		$_: '.myListProduct';
		background-color: #fff;
		padding: 70px 0 118px;
		@include g.tabletP {
			padding: 47px 0 79px;
		}
		&__inner {
			display: grid;
			grid-template: auto / repeat(2, 1fr);
			gap: 64px 28px;
			justify-content: center;
			margin: 0 auto;
			@include g.sphoneP {
				grid-template-columns: repeat(1, 1fr);
				max-width: 450px;
			}
		}
		&__image {
			text-align: center;
			margin-bottom: 10px;
		}
		&__title {
			@include g.fz(22);
			line-height: math.div(30, 22);
			text-align: center;
		}
	}//.myListProduct

	.myListProductLineup {
		$_: '.myListProductLineup';
		margin-top: 30px;
		@include g.tabletPmin {
			display: flex;
		}
		&__group {
			width: 100%;
			@include g.tabletPmin {
				&:not(:only-child) {
					width: 50%;
				}
			}
			@include g.tabletP {
				&:not(:last-child) {
					margin-bottom: 30px;
				}
			}
			&-title {
				@include g.fz(18);
				line-height: math.div(30, 18);
				margin-bottom: 7px;
				@include g.tabletPmin {
					margin-top: -7px;
				}
			}
		}
		&__list {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
		}
		&__item {
			border-bottom: 1px solid #B8B8B8;
			border-right: 1px solid #B8B8B8;
			width: 50%;
			min-height: 72px;
			@include g.tabletPmin {
				&:only-child {
					width: 243px;
					margin: 0 auto;
				}
				@at-root #{$_root} #{$_}__group:not(:only-child) #{$_}__item {
					width: 100%;
					&:first-child {
						border-top: 1px solid #B8B8B8;
					}
				}
				@at-root #{$_root} #{$_}__group:not(:only-child):first-child #{$_}__item {
					border-left: 1px solid #B8B8B8;
				}
				@at-root #{$_root} #{$_}__group:only-child #{$_}__item {
					&:nth-child(-n + 2) {
						border-top: 1px solid #B8B8B8;
					}
					&:nth-child(odd) {
						border-left: 1px solid #B8B8B8;
					}
				}
			}
			@include g.tabletP {
				width: 100%;
				border-left: 1px solid #B8B8B8;
				&:first-child {
					border-top: 1px solid #B8B8B8;
				}
				&.-empty {
					min-height: unset;
					border-bottom: none;
				}
			}
		}
		&__link {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: center;
			position: relative;
			color: inherit;
			line-height: math.div(16, 15);
			text-decoration: none;
			height: 100%;
			padding: 15px 30px 14px 19px;
			&:is(a) {
				&::after {
					content: "";
					border-top: 1px solid currentColor;
					border-right: 1px solid currentColor;
					position: absolute;
					top: 0;
					bottom: 0;
					right: 20px;
					width: 8px;
					height: 8px;
					margin: auto;
					transform: rotate(45deg)
				}
			}
		}
		&__icon {
			background-color: #F19E5D;
			color: #fff;
			@include g.fz(13);
			margin-top: 10px;
			padding: 3px 8px;
		}
		&__detail {
			flex: 1;
			margin-right: auto;
		}
		&__type {
			@include g.fz(13);
			&:not(:last-child) {
				margin-bottom: 8px;
			}
		}
		&__title {
			@include g.fz(18);
			font-weight: 500;
			&:not(:last-child) {
				margin-bottom: 8px;
			}
		}
		&__feature {
			color: #6f83b5;
			@include g.fz(15);
		}
	}//.myListProductLineup
}