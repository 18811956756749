@charset "utf-8";

/*
#overview
other
*/

@use "global" as g;
@use 'sass:math';

/*
#styleguide
modPcOnly

```
<div class="modPcOnly">tabletP以上でのみ表示</div>
```
*/


.modPcOnly {
	@include g.tabletP {
		display:none;
	}
}

/*
#styleguide
modSpOnly

```
<div class="modSpOnly">tabletP未満のみで表示</div>
```
*/
.modSpOnly {
	@include g.tabletPmin {
		display:none;
	}
}

/*
#styleguide
modSection01

```
<div class="modSection01">共通セクション（paddingの統一）</div>
<div class="modSection01 -color02">背景色違い02</div>
<div class="modSection01 -colorNone">背景色なし</div>
<div class="modSection01 -pt0">共通セクション（padding-topなし）</div>
<div class="modSection01 -pb0">共通セクション（padding-bottomなし）</div>
```
*/
.modSection01 {
	background-color: #fff;
	padding: 62px 0 68px;
	@include g.tabletP {
		padding: 42px 0 46px;
	}
	&.-color02 {
		background-color: nth(g.$subColorProducts, 4);
	}
	&.-colorNone {
		background-color: transparent;
	}
	&.-pt0 {
		padding-top: 0;
	}
	&.-pb0 {
		padding-bottom: 0;
	}
	.modTitleProductsPage01 + & {
		@include g.tabletP {
			padding-top: 20px;
		}
	}
}


/*
#styleguide
modPager

```
<ul class="modPager">
<li class="modPager__item"><a href="#" class="modPager__prev"></a></li>
<li class="modPager__item"><a href="#" class="modPager__num -active">1</a></li>
<li class="modPager__item"><a href="#" class="modPager__num">2</a></li>
<li class="modPager__item"><a href="#" class="modPager__num">3</a></li>
<li class="modPager__item"><a href="#" class="modPager__num">4</a></li>
<li class="modPager__item"><a href="#" class="modPager__num">5</a></li>
<li class="modPager__item"><a href="#" class="modPager__next"></a></li>
</ul>

<ul class="modPager -products">
<li class="modPager__item"><a href="#" class="modPager__prev"></a></li>
<li class="modPager__item"><a href="#" class="modPager__num -active">1</a></li>
<li class="modPager__item"><a href="#" class="modPager__num">2</a></li>
<li class="modPager__item"><a href="#" class="modPager__num">3</a></li>
<li class="modPager__item"><a href="#" class="modPager__num">4</a></li>
<li class="modPager__item"><a href="#" class="modPager__num">5</a></li>
<li class="modPager__item"><a href="#" class="modPager__next"></a></li>
</ul>
```
*/
.modPager{
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap:8px;
	margin-top: 50px;
	@include g.tabletP{
		margin-top: 30px;
	}

	&__item{
		width: 38px;
	}

	&__prev,
	&__next,
	&__num
	{
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		position: relative;
		width: 38px;
		height: 38px;
		border:solid 1px #ccc;
		color:#ccc;
		text-decoration: none;
		text-align: center;
		font-family: g.$fontAlpha;
		line-height: 1.5;
		@include g.tabletP{
			@include g.fz(16);
		}
	}


	&__prev,
	&__next{
		background-color: #ccc;
		border-color: #ccc;
		color: #fff;
		&::before{
			content: '';
			width: 10px;
			height: 10px;
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			margin: auto;
			transform-origin: center center;
			transform: rotate(45deg);
		}
	}

	&__prev{
		&::before{
			border-left: solid 2px;
			border-bottom: solid 2px;
			left: 5px;
		}
	}

	&__next{
		&::before{
			border-right: solid 2px;
			border-top: solid 2px;
			right: 5px;
		}
	}

	&__num{
		transition:background-color 0.3s ease;
		&.-active{
			border-color: nth(g.$mainColor,1);
			background-color: nth(g.$mainColor,1);
			color:#fff;
		}

		@include g.tabletPmin{
			&:hover{
				opacity: 1;
				border-color: nth(g.$mainColor,1);
				background-color: nth(g.$mainColor,1);
				color:#fff;
			}
		}
	}

	&.-products & {
		&__num {
			&.-active{
				border-color: nth(g.$mainColorProducts,1);
				background-color: nth(g.$mainColorProducts,1);
			}

			@include g.tabletPmin{
				&:hover{
					opacity: 1;
					border-color: nth(g.$mainColorProducts,1);
					background-color: nth(g.$mainColorProducts,1);
				}
			}
		}
	}

}//.modPager

/*
#styleguide
modContainerReset

```
<div class="container">
	<div class="modContainerReset">
		<div class="modSection02 -color02">.containerの横幅をリセットする</div>
	</div>
</div>
```
*/
.modContainerReset {
	margin: 0 calc(-50vw + 50%);
}
