@charset "utf-8";

/*
coin_laundry
*/


@use "global" as g;
@use 'sass:math';

$_idParent:products;
$_id:coin_laundry;
$_imgPath:'../img/#{$_idParent}/#{$_id}/';

body#page_#{$_idParent}.-#{$_id} {
	.myBlockAppli {
		$_: ".myBlockAppli";
		&__list {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			gap: 30px 26px;
			justify-content: space-between;
			@include g.tabletP {
				grid-template-columns: repeat(1, 1fr);
				gap: 20px;
			}
		}
		&__item {
			&.-type02 {
				@include g.tabletPmin {
					grid-column: 1 / 3;
				}
			}
			img {
				width: 100%;
			}
		}
	}//.myBlockAppli

	.myBlockProduct {
		$_: ".myBlockProduct";
	}//.myBlockProduct

	.myBlockProductHead {
		$_: ".myBlockProductHead";
		@include g.tabletPmin {
			display: flex;
		}
		&__image,
		&__body {
			@include g.tabletPmin {
				display: flex;
				align-items: center;
				flex: 1;
			}
			&-inner {
				background-color: transparent;
				width: 100%;
				padding-top: 62px;
				padding-bottom: 68px;
				@include g.tabletP {
					padding-top: 42px;
					padding-bottom: 46px;
				}
			}
		}
		&__image {
			background-color: nth(g.$subColorProducts, 5);
			@include g.tabletPmin {
				justify-content: flex-end;
				text-align: left;
			}
			&-inner {
				text-align: center;
				padding-left: g.$containerPadding;
				padding-right: 72px;
				@include g.tabletPmin {
					max-width: 520px;
				}
				@include g.tabletP {
					padding-right: g.$containerPadding;
				}
			}
		}
		&__body {
			background-color: nth(g.$subColorProducts, 4);
			justify-content: flex-start;
			flex-shrink: 0;
			&-inner {
				padding-left: 72px;
				padding-right: g.$containerPadding;
				@include g.tabletP {
					padding-left: g.$containerPadding;
				}
			}
		}
	}//.myBlockProductHead

	.myBlockProductTitle {
		$_: ".myBlockProductTitle";
		display: flex;
		align-items: flex-end;
		line-height: 1.5;
		@include g.tabletP {
			text-align: center;
		}
		&:not(:last-child) {
			margin-bottom: 30px;
			@include g.tabletP {
				margin-bottom: 20px;
			}
		}
		&__txt {
			flex-shrink: 0;
		}
		&__image {
			margin-left: 27px;
			padding-bottom: 10px;
			@include g.tabletP {
				margin-left: 18px;
				padding-bottom: 8px;
			}
		}
		&__en {
			@include g.fz(20);
			font-family: g.$fontAlpha;
			font-weight: 300;
			@include g.tabletP {
				@include g.fz(17);
			}
		}
		&__jp {
			@include g.fz(45);
			@include g.tabletP {
				@include g.fz(30);
			}
		}
	}//.myBlockProductTitle

	.myListProductLineup {
		$_: '.myListProductLineup';
		@include g.tabletPmin {
			width: 428px;
		}
		@include g.tabletP {
			max-width: 428px;
			margin: 0 auto;
		}
		&__item {
			&:not(:last-child) {
				margin-bottom: 9px;
				@include g.tabletP {
					margin-bottom: 8px;
				}
			}
		}
		&__link {
			background-color: #fff;
			border: 1px solid #B8B8B8;
			border-radius: 5px;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			color: inherit;
			line-height: math.div(25, 15);
			text-decoration: none;
			min-height: 79px;
			padding: 10px 20px 10px 25px;
			@include g.tabletP {
				min-height: 53px;
				padding: 9px 17px 9px 21px;
			}
			&::after {
				content: "";
				border-top: 1px solid currentColor;
				border-right: 1px solid currentColor;
				display: block;
				flex-shrink: 0;
				width: 7px;
				height: 7px;
				margin-left: 10px;
				transform: rotate(45deg);
			}
		}
		&__head {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
		}
		&__title {
			flex-shrink: 0;
			@include g.fz(20);
			font-weight: 500;
			width: 5.75em;
			@include g.tabletP {
				@include g.fz(17);
			}
		}
		&__icon {
			background-color: #F19E5D;
			color: #fff;
			@include g.fz(12);
			line-height: 1;
			margin-top: 2px;
			padding: 4px 8px 5px;
			@include g.tabletP {
				@include g.fz(10);
				padding: 3px 6px 4px;
			}
		}
		&__txt {
			@include g.fz(15);
			margin-right: auto;
			@include g.tabletP {
				@include g.fz(13);
			}
		}
	}//.myListProductLineup

	.myListProductsFeature {
		$_: ".myListProductsFeature";
		&__item {
			&:not(:last-child) {
				margin-bottom: 54px;
				@include g.tabletP {
					margin-bottom: 36px;
				}
			}
		}
		&__inner {
			@include g.tabletPmin {
				display: flex;
				justify-content: space-between;
			}
			@include g.tabletP {
				@include g.tabletP {
					max-width: 450px;
					margin: 0 auto;
				}
			}
		}
		&__image {
			text-align: center;
			@include g.tabletPmin {
				max-width: 535px;
				width: calc(100% - 396px - 30px);
			}
			@include g.tabletP {
				margin-bottom: 25px;
			}
			img {
				@include g.tabletPmin {
					width: 100%;
				}
			}
		}
		&__body {
			@include g.tabletPmin {
				display: flex;
				flex-direction: column;
				justify-content: center;
				flex-shrink: 0;
				width: 396px;
				margin-top: -6px;
			}
		}
		&__title {
			@include g.fz(30);
			line-height: math.div(40, 30);
			margin-bottom: 16px;
			@include g.tabletP {
				@include g.fz(20);
				margin-bottom: 11px;
			}
		}
		&__txt {
			@include g.fz(16);
			@include g.tabletP {
				@include g.fz(13);
			}
		}
	}//.myListProductsFeature

	.myBlockCase {
		$_: '.myBlockCase';
		position: relative;
		&__inner {
			display: flex;
			align-items: center;
			justify-content: center;
		}
		&__image {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 1;
			img {
				object-fit: cover;
				width: 100%;
				height: 100%;
			}
		}
		&__body {
			background-color: #fff;
			border-radius: 7.5px;
			position: relative;
			max-width: 600px;
			width: 100%;
			z-index: 2;
			@include g.tabletP {
				border-radius: 6px
			}
		}
		&__catch {
			@include g.fz(24);
			line-height: math.div(40, 24);
			text-align: center;
			margin-bottom: 23px;
			@include g.tabletP {
				@include g.fz(20);
				margin-bottom: 20px;
			}
		}
		&__txt {
			@include g.fz(15);
			line-height: math.div(30, 15);
			text-align: center;
			margin-bottom: 32px;
			@include g.tabletP {
				@include g.fz(13);
				margin-bottom: 22px;
			}
		}
		&__btn {
			display: flex;
			justify-content: center;
		}
	}//.myBlockCase

	.myBlockBnr {
		$_: '.myBlockBnr';
		border-top: 1px solid #B8B8B8;
		text-align: center;
		padding-top: 68px;
		@include g.tabletP {
			padding-top: 46px;
		}
	}//.myBlockBnr
}
