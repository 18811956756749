@charset "utf-8";
/*
#overview
modForm
*/

@use "global" as g;
@use 'sass:math';



/*
#styleguide
.modFormInput

```
	<input type="text" class="modFormInput" placeholder="例）岡山市北区">
	<input type="text" class="modFormInput -small" placeholder="例）岡山市北区">
	<input type="text" class="modFormInput -middle" placeholder="例）岡山市北区">
```
*/
.modFormInput {
	width: 100%;
	&.-small {
		max-width: 180px;
	}
	&.-middle {
		max-width: 600px;
	}
}

/*
#styleguide
.modFormSelect

```
<select class="modFormSelect">
	<option>選択してください</option>
	<option>選択してください</option>
	<option>選択してください</option>
	<option>選択してください</option>
	<option>選択してください</option>
</select>
```
*/
.modFormSelect {
	background: url(#{g.$formPath}icon_form_select.svg) no-repeat right 10px center;
	padding-right: 20px+25px;
}

/*
#styleguide
.modFormTextarea

```
<textarea rows="8" class="modFormTextarea" placeholder="例）入力してください"></textarea>
```
*/
.modFormTextarea {
	width: 100%;
	max-height: 277px;
	@include g.tabletP {
		max-height: 130px;
	}
}


/*
#styleguide
.modFormRadio

```
<label class="modFormRadio"><input type="radio" class="modFormRadio__input"><span class="modFormRadio__label">ラジオボタン01</span></label>
<label class="modFormRadio"><input type="radio" class="modFormRadio__input"><span class="modFormRadio__label">ラジオボタン02</span></label>

<label class="modFormRadio -small"><input type="radio" class="modFormRadio__input"><span class="modFormRadio__label">小さいラジオボタン01</span></label>
<label class="modFormRadio -small"><input type="radio" class="modFormRadio__input"><span class="modFormRadio__label">小さいラジオボタン02</span></label>
```
*/
.modFormRadio {
	$_: &;
	&__label {
		cursor: pointer;
		display: inline-block;
		padding-left: 40px;
		min-height: 30px;
		position: relative;
		width:100%;
		&::before,
		&::after {
			border-radius: 100%;
			content: '';
			position: absolute;
			top: 0;
			transition: all .2s;
		}
		&::before {
			background:  map-get( map-get(g.$formColor, 'check'), bg );
			border: 1px solid map-get( map-get(g.$formColor, 'check'), border );
			height: 30px;
			left: 0;
			margin-top: 0;
			width: 30px;
		}
		&::after {
			background: map-get( map-get(g.$formColor, 'check'), color );
			height: 16px;
			left: 7px;
			margin-top: 7px;
			opacity: 0;
			transform: scale(0.5);
			width: 16px;
		}
	}
	&__input {
		@include g.accessibilityHidden;
		&:checked {
			& + #{$_}__label {
				&::before {
					// background:  map-get( map-get(g.$formColor, 'check'), bg02 );
					// border: 1px solid map-get( map-get(g.$formColor, 'check'), color );
				}
				&::after {
					opacity: 1;
					transform: scale(1);
				}
			}
		}
	}
	&.-small {
		#{$_} {
			&__label {
				@include g.fz(14);
				padding-left: 30px;
				min-height: 20px;
				&::before {
					width: 20px;
					height: 20px;
					margin-top: 3px;
				}
				&::after {
					left: 5px;
					width: 10px;
					height: 10px;
					margin-top: 8px;
				}
			}
		}
	}
}



/*
#styleguide
.modFormCheckbox

```
<label class="modFormCheckbox"><input type="checkbox" class="modFormCheckbox__input"><span class="modFormCheckbox__label">チェックボックス01</span></label>
<label class="modFormCheckbox"><input type="checkbox" class="modFormCheckbox__input"><span class="modFormCheckbox__label">チェックボックス02</span></label>

<label class="modFormCheckbox -small"><input type="checkbox" class="modFormCheckbox__input"><span class="modFormCheckbox__label">チェックボックス01</span></label>
<label class="modFormCheckbox -small"><input type="checkbox" class="modFormCheckbox__input"><span class="modFormCheckbox__label">チェックボックス02</span></label>
```
*/

.modFormCheckbox {
	$_: &;
	&__label {
		cursor: pointer;
		display: inline-block;
		padding-left: 40px;
		min-height: 30px;
		position: relative;
		transition: all .2s;
		&::before,
		&::after {
			content: '';
			position: absolute;
			top: 0;
		}
		&::before {
			background:  map-get( map-get(g.$formColor, 'check'), bg );
			border: 1px solid map-get( map-get(g.$formColor, 'check'), border );
			border-radius: 5px;
			height: 30px;
			left: 0;
			margin-top: 0;
			width: 30px;
		}
		&::after {
			border-bottom: 4px solid map-get( map-get(g.$formColor, 'check'), color );
			border-left: 4px solid map-get( map-get(g.$formColor, 'check'), color );
			height: 10px;
			left: 8px;
			margin-top: 8px;
			opacity: 0;
			transform: rotate(-45deg) scale(0.5);
			width: 13px;
		}
	}
	&__input {
		@include g.accessibilityHidden;
		&:checked {
			& + #{$_}__label {
				&::before {
					// background:  map-get( map-get(g.$formColor, 'check'), bg02 );
					// border: 1px solid map-get( map-get(g.$formColor, 'check'), color );
				}
				&::after {
					opacity: 1;
					transform: rotate(-45deg) scale(1);
				}
			}
		}
	}
	&.-large {
		#{$_} {
			&__label {
				@include g.fz(18);
				letter-spacing: 0.05em;
				line-height: math.div(28, 18);
				min-height: 45px;
				padding-top: 6px;
				padding-left: 60px;
				&::before {
					width: 45px;
					height: 45px;
					margin-top: 0;
				}
				&::after {
					border-bottom-width: 6px;
					border-left-width: 6px;
					height: 14px;
					left: 12px;
					margin-top: 12px;
					width: 21px;
				}
			}
		}
	}
	&.-small {
		#{$_} {
			&__label {
				@include g.fz(14);
				padding-left: 30px;
				min-height: 20px;
				&::before {
					width: 20px;
					height: 20px;
					margin-top: 3px;
				}
				&::after {
					border-bottom-width: 3px;
					border-left-width: 3px;
					left: 5px;
					width: 10px;
					height: 7px;
					margin-top: 8px;
				}
			}
		}
	}
}


/*
#styleguide
.modFormStep01

```
<ul class="modFormStep01">
    <li class="modFormStep01__item" aria-current="step"><span class="modFormStep01__item-txt"><span class="modPcOnly">内容の</span>入力</span></li>
    <li class="modFormStep01__item"><span class="modFormStep01__item-txt"><span class="modPcOnly">入力内容の</span>確認</span></li>
    <li class="modFormStep01__item"><span class="modFormStep01__item-txt"><span class="modPcOnly">送信</span>完了</span></li>
</ul><!-- /.modFormStep01 -->

<ul class="modFormStep01">
    <li class="modFormStep01__item"><span class="modFormStep01__item-txt"><span class="modPcOnly">内容の</span>入力</span></li>
    <li class="modFormStep01__item" aria-current="step"><span class="modFormStep01__item-txt"><span class="modPcOnly">入力内容の</span>確認</span></li>
    <li class="modFormStep01__item"><span class="modFormStep01__item-txt"><span class="modPcOnly">送信</span>完了</span></li>
</ul><!-- /.modFormStep01 -->
```
*/
.modFormStep01 {
    $_: &;
    display: flex;
    justify-content: space-between;
    max-width: 626px;
    margin: 0 auto 90px;
    padding-top: 31px;
    @include g.tabletP {
        width: calc(100% - 80px);
        margin-bottom: 40px;
        padding-top: 26px;
    }
    &__item {
        color: map-get(g.$step01Color, color01);
        position: relative;
        flex-basis: 100%;
        &::before,
        &::after {
            content: "";
            background-color: map-get(g.$step01Color, color01);
        }
        &::before {
            border-radius: 50%;
            display: block;
            position: relative;
            width: 25px;
            height: 25px;
            z-index: 2;
            @include g.tabletP {
                width: 20px;
                height: 20px;
            }
        }
        &::after {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 2px;
            margin: auto;
        }
        &:last-child {
            flex-basis: auto;
            &::after {
                content: none;
            }
        }
        &-txt {
            position: absolute;
            bottom: calc(100% + 12px);
            left: calc(-10em / 2 + #{math.div(25px, 2.5)});
            @include g.fz(14);
            font-weight: bold;
            letter-spacing: 0.05em;
            text-align: center;
            width: 10em;
            @include g.tabletP {
                bottom: calc(100% + 7px);
            }
        }
        &[aria-current] {
            &::after {
                background-color: map-get(g.$step01Color, color02);
            }
        }
        &[aria-current] ~ & {
            color: map-get(g.$step01Color, color02);
            &::before,
            &::after {
                background-color: map-get(g.$step01Color, color02);
            }
        }
    }
}//.modFormStep01

/*
#styleguide
.modFormStep02

```
<ol class="modFormStep02">
    <li class="modFormStep02__item" aria-current="step">
        <div class="modFormStep02__item-box">
            <span class="modFormStep02__item-step"><span class="modFormStep02__item-stepTxt">STEP</span><span class="modFormStep02__item-stepNum">01</span></span>
            <span class="modFormStep02__item-txt">〇〇の入力</span>
        </div>
    </li>
    <li class="modFormStep02__item">
        <div class="modFormStep02__item-box">
            <span class="modFormStep02__item-step"><span class="modFormStep02__item-stepTxt">STEP</span><span class="modFormStep02__item-stepNum">02</span></span>
            <span class="modFormStep02__item-txt">個人情報保護方針の同意とか長いよー</span>
        </div>
    </li>
    <li class="modFormStep02__item -pause"><div class="modFormStep02__item-box">・・・</div></li>
    <li class="modFormStep02__item">
        <div class="modFormStep02__item-box">
            <span class="modFormStep02__item-step"><span class="modFormStep02__item-stepTxt">STEP</span><span class="modFormStep02__item-stepNum">08</span></span>
            <span class="modFormStep02__item-txt">送信完了</span>
        </div>
    </li>
</ol><!-- /.modFormStep02 -->

<ol class="modFormStep02">
    <li class="modFormStep02__item">
        <div class="modFormStep02__item-box">
            <span class="modFormStep02__item-step"><span class="modFormStep02__item-stepTxt">STEP</span><span class="modFormStep02__item-stepNum">02</span></span>
            <span class="modFormStep02__item-txt">個人情報保護方針の同意とか長いよー</span>
        </div>
    </li>
    <li class="modFormStep02__item" aria-current="step">
        <div class="modFormStep02__item-box">
            <span class="modFormStep02__item-step"><span class="modFormStep02__item-stepTxt">STEP</span><span class="modFormStep02__item-stepNum">03</span></span>
            <span class="modFormStep02__item-txt">〇〇の入力</span>
        </div>
    </li>
    <li class="modFormStep02__item -pause"><div class="modFormStep02__item-box">・・・</div></li>
    <li class="modFormStep02__item">
        <div class="modFormStep02__item-box">
            <span class="modFormStep02__item-step"><span class="modFormStep02__item-stepTxt">STEP</span><span class="modFormStep02__item-stepNum">08</span></span>
            <span class="modFormStep02__item-txt">送信完了</span>
        </div>
    </li>
</ol><!-- /.modFormStep02 -->

<ol class="modFormStep02">
    <li class="modFormStep02__item" aria-current="step">
        <div class="modFormStep02__item-box">
            <span class="modFormStep02__item-step"><span class="modFormStep02__item-stepTxt">STEP</span><span class="modFormStep02__item-stepNum">06</span></span>
            <span class="modFormStep02__item-txt">〇〇の入力</span>
        </div>
    </li>
    <li class="modFormStep02__item">
        <div class="modFormStep02__item-box">
            <span class="modFormStep02__item-step"><span class="modFormStep02__item-stepTxt">STEP</span><span class="modFormStep02__item-stepNum">07</span></span>
            <span class="modFormStep02__item-txt">入力内容の確認</span>
        </div>
    </li>
    <li class="modFormStep02__item">
        <div class="modFormStep02__item-box">
            <span class="modFormStep02__item-step"><span class="modFormStep02__item-stepTxt">STEP</span><span class="modFormStep02__item-stepNum">08</span></span>
            <span class="modFormStep02__item-txt">送信完了</span>
        </div>
    </li>
</ol><!-- /.modFormStep02 -->
```
*/
.modFormStep02 {
    $_: &;
    display: flex;
    margin-bottom: 90px;
    @include g.tabletP {
        margin-bottom: 40px;
    }
    & + & {
        margin-top: -75px;
        @include g.tabletP {
            margin-top: -25px;
        }
    }
    &__item {
        flex-basis: 100%;
        position: relative;
        color: map-get(g.$step02Color, color);
        @include g.fz(14);
        font-weight: bold;
        letter-spacing: 0.05em;
        line-height: math.div(21, 14);
        min-height: 60px;
        margin-left: -55px;
        overflow: hidden;
        @include g.tabletP {
            margin-left: -35px;
        }
        @for $_i from 1 to 11 {
            &:nth-child(#{$_i}) {
                z-index: 11 - $_i;
            }
        }
        @include g.tabletP {
            min-height: 53px;
        }
        &:not(:last-child) {
            padding-left: 50px;
            padding-right: 55px;
            @include g.tabletP {
                padding-left: 30px;
            }
            &::before,
            &::after {
                content: "";
                border: 100px solid map-get(g.$step02Color, separate);
                position: absolute;
                top: 50%;
                height: 100%;
                transform-origin: right 0;
                transform: rotate(45deg) skew(-4deg, -4deg);
            }
            &::before {
                right: 0;
                z-index: 1;
            }
            &::after {
                content: "";
                border-color: map-get(g.$step02Color, bg);
                right: 10px;
                z-index: 2;
            }
        }
        &:first-child {
            margin-left: 0;
            padding-left: 0;
        }
        &:last-child {
            @include g.tabletP {
                flex-basis: 180%;
            }
        }
        &-box {
            background-color: map-get(g.$step02Color, bg);
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            height: 100%;
            margin-left: inherit;
            padding: 6px 15px 7px;
            z-index: 3;
            #{$_}__item:not(:first-child) & {
                padding-left: 15px+55px;
            }
            #{$_}__item:last-child & {
                padding-left: 15px+93px;
                @include g.tabletP {
                    padding-left: 15px+63px;
                }
            }
        }
        &-step {
            @include g.fz(14);
            letter-spacing: 0.05em;
            position: relative;
            @include g.tabletPmin {
                padding-right: 40px;
                &::after {
                    content: "";
                    background-color: map-get(g.$step02Color, color);
                    position: absolute;
                    top: 0;
                    right: 20px;
                    width: 1px;
                    height: 100%;
                }
            }
            &Txt,
            &Num {
                display: block;
                line-height: 1;
            }
            &Txt {
                @include g.fz(10);
                letter-spacing: 0.05em;
                margin-bottom: 2px;
                @include g.tabletP {
                    text-indent: -.2em;
                    transform-origin: center 0;
                    transform: scale(.8);
                }
            }
            &Num {
                @include g.fz(20);
                letter-spacing: 0.05em;
                @include g.tabletP {
                    @include g.fz(18);
                    letter-spacing: 0.05em;
                }
            }
            @include g.tabletP {
                #{$_}__item:first-child & {
                    margin-right: -22px;
                }
            }
        }
        &-txt {
            margin-right: -10px;
			@include g.narrowPC {
				@include g.fz(12);
				line-height: 1.3;
			}
            @include g.tabletP {
                display: none;
            }
        }
        &[aria-current = "step"] {
            color: map-get(map-get(g.$step02Color, current), color);
            &::after {
                border-color: map-get(map-get(g.$step02Color, current), bg);
            }
            #{$_}__item-box {
                background-color: map-get(map-get(g.$step02Color, current), bg);
            }
            #{$_}__item {
                &-step {
                    &:not(:last-child) {
                        @include g.tabletPmin {
                            &::after {
                                background-color: map-get(map-get(g.$step02Color, current), color);
                            }
                        }
                    }
                }
            }
        }
        &.-pause {
            flex-basis: 43%;
            #{$_}__item-box {
                background: none;
                @include g.fz(16);
                text-indent: 1.5em;
                @include g.tabletP {
                    @include g.fz(10);
                    text-indent: -1em;
                    transform-origin: center center;
                    transform: scale(.8);
                }
            }
        }
    }
}//.modFormStep02

/*
#styleguide
.modFormTitlePage

```
<h1 class="modFormTitlePage">フォームタイトルが入ります。</h1>
```
*/
.modFormTitlePage {
    $_: &;
    @include g.fz(24);
    font-weight: bold;
    letter-spacing: 0.05em;
    line-height: 1.5;
    text-align: center;
    margin-bottom: 30px;
    @include g.tabletP {
        @include g.fz(22);
        margin-bottom: 14px;
    }
}//.modFormTitlePage

/*
#styleguide
.modFormTxtLead

```
<p class="modFormTxtLead">リード文が入ります。リード文が入ります。リード文が入ります。</p>
```
*/
.modFormTxtLead {
    $_: &;
    margin-bottom: 60px;
    @include g.tabletP {
        margin-bottom: 30px;
    }
}//.modFormTxtLead

/*
#styleguide
.modFormTxtCaution

```
```
*/
.modFormTxtCaution {
    margin-bottom: 10px;
    &:not(:first-child) {
        margin-top: -5px;
    }
}//.modFormTxtCaution


/*
#styleguide
.modFormTxtError

```
```
*/
.modFormTxtError {
    display: inline-flex;
    color: map-get(map-get(g.$formColor, error), color);
    margin-bottom: 15px;
    &::before {
        content: "";
        background: url(#{g.$formPath}icon_form_error.svg) no-repeat center;
        background-size: contain;
        display: inline-block;
        flex-shrink: 0;
        width: 20px;
        height: 20px;
        margin: 5px 8px 0 0;
		@include g.tabletP {
			margin-top: 2px;
		}
    }
    &:not(:first-child) {
        margin-top: -15px;
    }
    &:empty {
        display: none;
    }
}//.modFormTxtError

/*
#styleguide
.modFormListError

```
```
*/
.modFormListError {
    $_: &;
	border: 2px solid map-get(map-get(g.$formColor, error), border);
	color: map-get(map-get(g.$formColor, error), color);
	margin: 30px 0 40px;
	padding: 30px;
	& > *,
	& li {
		&:not(:last-child) {
			margin-bottom: 7px;
		}
	}
	li {
		text-indent: -1em;
		padding-left: 1em;
		&::before {
			content: "\0030fb";
		}
	}
}//.modFormListError

/*
#styleguide
.modFormBlockSection

```
<section class="modFormBlockSection">
    <h2 class="modFormTitle01">見出し</h2>
</section>
```
*/
.modFormBlockSection {
    $_: &;
    margin-bottom: 60px;
}//.modFormBlockSection

/*
#styleguide
.modFormTitle01

```
<h2 class="modFormTitle01">見出し</h2>
```
*/
.modFormTitle01 {
    $_: &;
    background-color: nth(g.$mainColor, 1);
    color: #fff;
    @include g.fz(22);
    font-weight: bold;
    letter-spacing: 0.05em;
    line-height: 1.5;
    margin-bottom: 45px;
    padding: 15px 30px 14px;
    @include g.tabletP {
        @include g.fz(18);
        padding: 10px 15px 9px;
        margin-bottom: 30px;
    }
}//.modFormTitle01

/*
#styleguide
.modFormTitle01

```
<h2 class="modFormTitle02">テキストテキストテキストテキスト</h2>
```
*/
.modFormTitle02 {
    $_: &;
    border-bottom: 2px solid nth(g.$mainColor, 1);
    @include g.fz(18);
    font-weight: bold;
    letter-spacing: 0.05em;
    line-height: math.div(28, 18);
    margin-bottom: 30px;
    padding-bottom: 14px;
    @include g.tabletP {
        margin-bottom: 15px;
    }
}//.modFormTitle02

/*
#styleguide
.modFormBlockArea

```
```
*/
.modFormBlockArea {
    $_: &;
    &__head {
        display: flex;
        flex-wrap: wrap;
        align-items: baseline;
        margin-bottom: 14px;
        &-title {
            @include g.fz(18);
            font-weight: bold;
            letter-spacing: 0.05em;
            line-height: math.div(28, 18);
            text-align: left;
            @include g.tabletP {

            }
        }
        &-required {
            background-color: map-get(g.$formColor, required);
            border-radius: 2px;
            flex-shrink: 0;
            color: #fff;
            @include g.fz(12);
            letter-spacing: 0.05em;
            line-height: 1.5;
            margin-left: 10px;
            padding: 0 12px 1px;
            transform: translateY(-3px);
        }
        &-txt {
            @include g.tabletPmin {
                margin-left: 2em;
            }
            @include g.tabletP {
                width: 100%;
            }
        }
    }
    &__contents {
        margin-bottom: 45px;
        @include g.tabletP {
            margin-bottom: 30px;
        }
        &.-confirm {
            border-bottom: 1px solid map-get(g.$formColor, 'border');
            margin-bottom: 30px;
            padding-bottom: 30px;
        }
    }
}//.modFormBlockArea

/*
#styleguide
.modFormBlockItem

```
```
*/
.modFormBlockItem {
    $_: &;
    margin-bottom: 15px;
}//.modFormBlockItem

/*
#styleguide
.modFormBlockName

```
```
*/
.modFormBlockName {
    $_: &;
    margin-bottom: 15px;
    display: flex;
    &__item {
        flex-basis: 100%;
        max-width: 180px;
        &:not(:last-child) {
            margin-right: 15px;
        }
    }
    &__title {
        margin-bottom: 10px;
    }
}//.modFormBlockName

/*
#styleguide
.modFormBlockZip

```
```
*/
.modFormBlockZip,
.modFormBlockTel {
    $_: &;
    display: flex;
    margin-bottom: 15px;
    &__item {
        &:not(:last-child) {
            margin-right: 15px;
        }
        &.-input {
            max-width: 180px;
            @include g.tabletP {
                &:only-child {
                    min-width: 175px;
                }
            }
        }
        &.-pause {
            align-self: center;
            @include g.sphoneP {
                margin-left: -10px;
                margin-right: 5px;
            }
        }
        &.-btn {
            background-color: nth(g.$mainColor, 1);
            border: 2px solid nth(g.$mainColor, 1);
            border-radius: 5px;
            display: flex;
            align-items: center;
            flex-shrink: 0;
            justify-content: center;
            color: #fff;
            font-weight: bold;
            padding: 5px 30px 5px;
            @include g.tabletP {
                padding-left: 20px;
                padding-right: 20px;
                #{$_}__item.-input:first-child + & {
                    display: none;
                }
            }
        }
    }
}//.modFormBlockZip

/*
#styleguide
.modFormListCheck

```
```
*/
.modFormListCheck {
    $_: &;
    &.-horizontal {
        @include g.tabletPmin {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            margin-bottom: -15px;
            margin-right: -30px;
            overflow: hidden;
            #{$_}__item {
                margin-right: 30px;
            }
        }
    }
    &__item {
        margin-bottom: 15px;
		.modFormRadio,
		.modFormCheckbox {
			& + .modFormBlockItem {
				margin-top: 15px;
			}
		}
    }
}//.modFormListCheck

/*
#styleguide
.modFormListCheck02

```
```
*/
.modFormListCheck02 {
    $_: &;
    display: grid;
    gap: 15px percentage(math.div(35, 1000));
    margin-bottom: 15px;
    @include g.tabletPmin {
        grid-auto-flow: column;
        grid-auto-columns: 1fr;
    }
    @include g.tabletP {
        grid-template-columns: repeat(1, 1fr);
    }
    &__item {
        display: flex;
        &-inner {
			border: 1px solid map-get( map-get(g.$formColor, 'check'), border );
            border-radius: 5px;
            display: flex;
            flex-direction: column;
            gap: 12px;
            padding: 15px 20px 15px;
            @include g.tabletP {
                gap: 6px;
                padding: 15px 15px 15px;
            }
        }
    }
    &__label {
        line-height: math.div(22.4, 16);
        &::before,
        &::after {
            top: calc(50% - 17px);
            @include g.tabletP {
                top: calc(50% - 20px);
            }
        }
    }
    &__head {
        display: flex;
        align-items: center;
    }
    &__txt01 {
        @include g.fz(14);
        letter-spacing: 0;
        line-height: math.div(23.8, 14);
        @include g.tabletP {
            @include g.fz(11);
        }
    }
    &__txt02 {
        @include g.fz(12);
        letter-spacing: 0;
        line-height: math.div(20.4, 12);
        @include g.tabletP {
            @include g.fz(10);
        }
    }
}//.modFormListCheck02

/*
#styleguide
.modFormBlockGrid

```
```
*/
.modFormBlockGrid {
    $_: &;
    display: flex;
    align-items: center;
    &__item {
        &:not(:last-child) {
            margin-right: 15px;
        }
    }
}//.modFormBlockGrid

/*
#styleguide
.modFormBlockPrivacy

```
```
*/
.modFormBlockPrivacy {
    $_: &;
    background-color: nth(map-get(g.$formColor, bg), 1);
    margin-bottom: 60px;
    padding: 60px 60px 45px;
    @include g.tabletP {
        margin-bottom: 30px;
        padding: 30px 30px 30px;
    }
    &__lead {
        margin-bottom: 30px;
        @include g.tabletP {
            margin-bottom: 15px;
        }
    }
    &__body {
        background-color: #fff;
        max-height: 341px;
        margin-bottom: 30px;
        padding: 30px 30px 30px;
        overflow: auto;
        @include g.tabletP {
            max-height: 325px;
            margin-bottom: 15px;
            padding: 15px 15px 15px;
        }
        & > * {
            &:not(:last-child) {
                margin-bottom: 14px;
            }
        }
        &-title {
            @include g.fz(18);
            font-weight: bold;
            letter-spacing: 0.05em;
            line-height: math.div(28, 18);
            &:not(:first-child) {
                margin-top: 30px;
            }
        }
    }
}//.modFormBlockPrivacy

/*
#styleguide
.modFormBtnBox

```
```
*/
.modFormBtnBox {
    margin-top: 60px;
    @include g.tabletP {
        margin-top: 30px;
    }
    &:not(:last-child) {
        margin-bottom: 30px;
    }
}//.modFormBtnBox

/*
#styleguide
.modFormBlockComp

```
```
*/
.modFormBlockComp {
    $_: &;
    &__title {
        @include g.fz(24);
        font-weight: bold;
        letter-spacing: 0.05em;
        line-height: 1.5;
        text-align: center;
        margin-bottom: 30px;
        @include g.tabletP {
            @include g.fz(22);
            margin-bottom: 14px;
        }
    }
    &__txt {
        text-align: center;
		&:not(:last-child) {
			margin-bottom: 80px;
			@include g.tabletP {
				margin-bottom: 54px;
			}
		}
    }
}//.modFormBlockComp


/*
#styleguide
.modFormBlockContact

```
```
*/
.modFormBlockContact {
    $_: &;
	line-height: 1.4;
	text-align: center;
	&__title {
		@include g.fz(28);
		line-height: math.div(30, 14);
		margin-bottom: 29px;
		@include g.tabletP {
			@include g.fz(19);
			margin-bottom: 20px;
		}
	}
	&__txt01 {
		@include g.fz(16);
		font-weight: 500;
		margin-bottom: 13px;
		@include g.tabletP {
			@include g.fz(13);
			margin-bottom: 11px;
		}
	}
	&__txt02 {
		margin-bottom: 12px;
		@include g.tabletP {
			margin-bottom: 10px;
		}
	}
	&__txt03 {
		@include g.fz(15);
		font-weight: 500;
		margin-bottom: 12px;
		@include g.tabletP {
			@include g.fz(12);
			margin-bottom: 10px;
		}
	}
	&__txt04 {
		@include g.fz(14);
		font-weight: 500;
		@include g.tabletP {
			@include g.fz(11);
		}
	}
	&__link {
		display: inline-flex;
		@include g.fz(42);
		font-family: g.$fontAlpha;
		font-weight: 300;
		align-items: center;
		text-decoration: none;
		@include g.tabletP {
			@include g.fz(28);
		}
		&::before {
			content: "";
			background-color: currentColor;
			mask: url(#{g.$cmnPath}icon_tel.svg) no-repeat center;
			mask-size: cover;
			display: block;
			flex-shrink: 0;
			width: 33px;
			height: 33px;
			margin-right: 10px;
			@include g.tabletP {
				width: 22px;
				height: 22px;
				margin-right: 9px;
			}
		}
		@include g.tabletPmin {
			@media(hover: hover) {
				&:hover {
					border-color: currentColor;
					opacity: 1;
					color: nth(g.$mainColor, 1);
					&.-products {
						background-color: nth(g.$subColorProducts, 1);
						border-color: nth(g.$subColorProducts, 1);
						color: #fff;
					}
				}
			}
		}
		@include g.tabletP {
			@include g.fz(26.35);
		}
	}
}//.modFormBlockContact
