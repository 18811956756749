@charset "utf-8";
/*
#overview
modTable
*/

@use "global" as g;
@use 'sass:math';

/*
#styleguide
.modTable01

```
<section class="modTable01">
	<table>
		<tbody>
			<tr>
				<th colspan="2">型 式</th>
				<td><strong>WN403H</strong></td>
			</tr>
			<tr>
				<th colspan="2">容 量</th>
				<td colspan="2">40kg</td>
			</tr>
			<tr>
				<th colspan="2">ドラム寸法</th>
				<td>965φ×592mm</td>
			</tr>
			<tr>
				<th colspan="2">洗浄回転数</th>
				<td>20/38rpm</td>
			</tr>
			<tr>
				<th colspan="2">脱水回転数</th>
				<td>375/750rpm</td>
			</tr>
			<tr>
				<th colspan="2">電 源</th>
				<td>3相　AC200V 40A</td>
			</tr>
			<tr>
				<th colspan="2">電動機</th>
				<td>5.5kW　6P</td>
			</tr>
			<tr>
				<th rowspan="4">配管口径</th>
				<th>給 水</th>
				<td>32A</td>
			</tr>
			<tr>
				<th>給 湯</th>
				<td>32A</td>
			</tr>
			<tr>
				<th>排 水</th>
				<td>80A</td>
			</tr>
			<tr>
				<th>カップ洗浄</th>
				<td>15A</td>
			</tr>
			<tr>
				<th colspan="2">所要水量</th>
				<td>低76ℓ/中136ℓ/高204ℓ</td>
			</tr>
			<tr>
				<th colspan="2">機械最大寸法</th>
				<td>1243（幅）×1472（奥行）×1810（高さ）mm</td>
			</tr>
			<tr>
				<th colspan="2">重 量</th>
				<td>1340kg</td>
			</tr>
		</tbody>
	</table>
</section><!-- /.modTable01 -->
<div class="modTable01 -maintenance -small">
	<table>
		<thead>
			<tr>
				<th class="-center -normal -narrow -contents">内容</th>
				<th class="-center -normal -narrow -products">対応機種</th>
				<th class="-center -normal -narrow -about">このような症状の場合に</th>
				<th class="-center -normal -narrow -movie">動画</th>
			</tr>
		</thead>
		<tbody>
			<tr>
				<td class="-wAuto">排水弁の清掃</td>
				<td class="-wAuto -narrow">
					<dl class="myList01">
						<dt class="myList01__title">洗濯機</dt>
						<dd class="myList01__inner">
							<ul class="myList01__list">
								<li class="myList01__list-item">WNシリーズ</li>
								<li class="myList01__list-item">WN-Dシリーズ</li>
								<li class="myList01__list-item">WN-CSシリーズ</li>
							</ul>
						</dd>
					</dl>
					<dl class="myList01">
						<dt class="myList01__title">洗濯乾燥機</dt>
						<dd class="myList01__inner">
							<ul class="myList01__list">
								<li class="myList01__list-item">WD-Gシリーズ</li>
								<li class="myList01__list-item">WD-Sシリーズ</li>
								<li class="myList01__list-item">WD-CSシリーズ</li>
							</ul>
						</dd>
					</dl>
				</td>
				<td class="-wAuto -narrow -vAtop">
					<ul class="modListNormal">
						<li>エラー番号　25　26　が表示</li>
						<li>水がたまらない</li>
						<li>水が抜けない</li>
					</ul>
				</td>
				<td class="-wAuto -narrow -center">
					<a href="#" class="modTxtLinkMovie" target="_blank">動画を見る</a>
				</td>
			</tr>
		</tbody>
	</table>
</div><!-- /.modTable01 -->
```
*/
.modTable01,
.modWysiwygBody {

	&.-maintenance{
		th{
			&.-contents{
				width: 23.2%;
			}
			&.-movie{
				width: 14.3%;
			}
			&.-products{
			}
			&.-about{
				width: 30%;
			}
		}
	}

	&.-corporate{
		th {
			background-color: #f0f0f0;
		}
	}

	&.-errorcode{
		* + &{
			margin-top: 15px;
		}

		th{
			&.-contents{
			}
			&.-products{
				width: 23%;
			}
			&.-code{
				width: 16%;
			}
		}
	}


	&.-small{

		th,td{
			@include g.fz(14);
			@include g.tabletP{
				@include g.fz(12);
			}
			@include g.sphoneP{
				@include g.fz(10);
			}
		}
	}


	table {
		&.-sizeAuto {
			width: auto;
		}

		th,
		td {
			background-color: #fff;
			border: 1px solid #B8B8B8;
			@include g.fz(16);
			line-height: math.div(30, 28);
			padding: 14px 42px 16px;
			@include g.tabletP {
				@include g.fz(14);
				padding: 12px 30px 13px;
			}
			@include g.sphoneP {
				@include g.fz(12);
				padding: 10px 16px 11px;
			}
			&.-left{
				text-align: left;
			}
			&.-center{
				text-align: center;
			}

			.modListNormal{
				line-height: math.div(30,16);
			}

			&.-narrow{
				padding-right: 24px;
				padding-left: 24px;
				@include g.sphoneP{
					padding-right: 6px;
					padding-left: 6px;
				}
			}

			&.-vAtop{
				vertical-align: top;
			}

		}
		th {
			background-color: #F5F5F5;
			@at-root .modTable01 table th:not([rowspan]) {
				white-space: nowrap;
			}
			&.-normal{
				white-space: normal;
			}
		}
		td {
			@at-root .modTable01 table td {
				width: 100%;
				&.-wAuto{
					width: auto;
				}
			}
			@at-root .modWysiwygBody table td {
				text-align: center;
			}
		}
	}
}//.modTable01