@charset "utf-8";

/*
recruit tasuki_relay
*/


@use "global" as g;
@use 'sass:math';

$_idParent:recruit;
$_id:tasuki_relay;
$_imgPath:'../img/#{$_idParent}/#{$_id}/';

body#page_#{$_idParent}.-#{$_id} {

	.myBlockLead{
		background-color: #fff;
		margin-bottom: 90px;
		@include g.sphonePmin{
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			padding: 22px 70px;
			gap:60px;
		}
		@include g.sphoneP{
			margin-bottom: 40px;
		}

		&__image{
			text-align: center;
			@include g.sphonePmin{
				width: 33.5%;
			}

			@include g.sphoneP{
				width: 60%;
				padding-top: 5vw;
				margin: 0 auto;

			}

			img{
				@include g.sphonePmin{
					object-fit: cover;
					height: 100%;
				}
				@include g.sphoneP{
					width: 100%;
				}
			}
		}

		&__detail{
			@include g.sphonePmin{
				width: calc(100% - 33.5% - 60px);
				display: flex;
				flex-wrap: wrap;
				align-items: center;
			}
			@include g.sphoneP{
				padding: 20px;
			}
		}

		&__inner{
			width: 100%;
		}

		&__catch01{
			margin-bottom: 25px;
			@include g.fz(40);
			line-height: math.div(50,40);
			font-weight: 500;
			@include g.tabletP{
				margin-bottom: 10px;
				@include g.fz(22);
			}
		}

		&__catch02{
			margin-bottom: 25px;
			color: nth(g.$mainColor,1);
			@include g.fz(26);
			line-height: math.div(40,26);
			font-weight: 500;
			@include g.tabletP{
				margin-bottom: 10px;
				@include g.fz(18);
			}

		}
		&__txt{
		}
	}//.myBlockLead

	.myListStep{
		position: relative;
		$_stepw:125px;
		$_stepwSp:65px;

		&::before{
			content:"";
			position: absolute;
			top: 0;
			bottom: 0;
			left: calc(#{$_stepw} / 2 - 1px);
			width: 3px;
			background-color: #ecd5d9;
			@include g.tabletP{
				left: calc(#{$_stepwSp} / 2 - 1px);
			}
		}

		&__item{
			position: relative;
			&:not(:last-child){
				margin-bottom: 40px;
			}
		}

		&Head{
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			margin-bottom: 20px;
			@include g.tabletP{
				gap:5px 10px;
			}

			&__step{
				background-color: nth(g.$mainColor,1);
				border:solid 5px nth(g.$subColor,1);
				width: #{$_stepw};
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				align-items: center;
				aspect-ratio: 1;
				border-radius: 50%;
				color:#fff;
				text-align: center;
				font-weight: 600;
				line-height: 1.2;
				@include g.fz(15);
				font-family: g.$fontAlpha;
				@include g.tabletP{
					width: #{$_stepwSp};
					@include g.fz(11);
					line-height: 1;
				}

				&-inner{
					display: block;
				}

				&-num{
					display: block;
					font-size: math.percentage(math.div(24,15));
					@include g.tabletP{
					}
				}
			}

			&__title{
				@include g.fz(36);
				font-weight: 500;
				@include g.tabletPmin{
					margin-left: 25px;
				}
				@include g.tabletP{
					width: calc(100% - #{$_stepwSp} - 10px);
					@include g.fz(20);
				}

				&-sub{
					font-size: math.percentage(math.div(30,36));
				}
			}

			&__more{
				display: block;
				position: relative;
				min-width: 243px;
				background-color: #fff;
				border:solid 2px currentColor;
				margin-left: 32px;
				padding: 5px 50px 5px 20px;
				border-radius: 99999px;
				text-decoration: none;
				color: nth(g.$mainColor,1);
				text-align: left;
				line-height: math.div(30,16);
				@include g.tabletP{
					min-width: 150px;
					margin-left: calc(#{$_stepwSp} + 10px);
				}

				&::after{
					content:"";
					background-image: url(#{g.$cmnPath}icon_zoom.svg);
					background-position: 0 0;
					background-repeat: no-repeat;
					background-size: 100% auto;
					width: 21px;
					height: 21px;
					position: absolute;
					top: 0;
					right: 21px;
					bottom: 0;
					margin: auto 0;
				}
			}

		}

		&__body{
			padding-left: 260px;
			@include g.tabletP{
				padding-left: calc(#{$_stepwSp} + 10px);
			}
		}

		&__mainvisual{
			margin-bottom: 34px;
			text-align: center;
		}

	}//.myListStep

	.myListInterview{
		background-color: #fff;

		&__item{
			padding: 32px;
			position: relative;
			@include g.tabletP{
				padding: 15px;
			}
			&:not(:last-child){
				&::after{
					content:"";
					position: absolute;
					bottom: 0;
					left: 32px;
					right: 32px;
					background-color: #ebebeb;
					height: 2px;
					@include g.tabletP{
						left: 15px;
						right: 15px;
					}
				}
			}
		}

		&Head{
			display: grid;
			gap:0 40px;
			font-weight: 500;
			@include g.fz(15);
			line-height: math.div(25,15);
			@include g.sphonePmin{
				grid-template:
				"image catch"
				"image info"
				"image more" 1fr /
				28.2% calc(100% - 28.2% - 40px);
				align-items: center;
			}
			@include g.sphoneP{
				grid-template:
				"catch catch"
				"image info"
				"more more" 1fr /
				28.2% calc(100% - 28.2% - 10px);
				gap:0 10px;
				@include g.fz(11);
			}


			&__image{
				grid-area: image;
				text-align: center;
			}

			&__catch{
				grid-area: catch;
				margin-bottom: 20px;
				@include g.fz(22);
				font-weight: 500;
				line-height: math.div(35,22);
				@include g.tabletP{
					margin-bottom: 10px;
					@include g.fz(15);
				}
			}

			&__info{
				grid-area: info;
			}

			&__job{
			}

			&__name{
			}

			&__more{
				grid-area: more;
				display: flex;
				flex-wrap: wrap;
				justify-content: flex-end;
				$_:'.myListInterviewHead__more';

				&-btn{
					display: flex;
					flex-wrap: wrap;
					align-items: center;
					justify-content: flex-end;
					position: relative;
					border:none;
					padding: 0 50px 0 20px;
					min-width: 135px;
					min-height: 35px;
					background-color:transparent;
					text-decoration: none;
					@include g.fz(13);
					line-height: 1.5;
					@include g.tabletP{
						padding-right: 40px;
						min-width: 115px;
						min-height: 25px;
						@include g.fz(11);
					}

					&::before{
						content:"";
						border-radius: 50%;
						width: 35px;
						aspect-ratio: 1;
						background-color: #93959f;
						position: absolute;
						top: 0;
						right: 0;
						bottom: 0;
						margin: auto;
						@include g.tabletP{
							width: 25px;
						}
					}

					&::after{
						content:"";
						border-color:#fff;
						border-width: 1px;
						border-bottom-style:solid;
						border-right-style:solid;
						position: absolute;
						top: 0;
						bottom: 0;
						right: 17px;
						width: 8px;
						height: 8px;
						margin: auto;
						transform: translate(3px,-1px) rotate(45deg);
						@include g.tabletP{
							right: 13px;
							width: 6px;
							height: 6px;
						}
					}

					&[aria-expanded="false"]{
						#{$_}-close{
							display: none;
						}
					}

					&[aria-expanded="true"]{
						#{$_}-open{
							display: none;
						}

						&::after{
							transform: scale(1,-1) translate(3px,-1px) rotate(45deg);
						}
					}
				}

				&-open{

				}

				&-close{

				}
			}

		}

		&__body{
			padding: 30px 28px 0;
			display: none;
			@include g.tabletP{
				padding: 20px 0 0;
			}
		}

		&__image{
			margin-top: 30px;
			text-align: center;
			@include g.tabletP{
				margin-top: 20px;
			}
		}

	}//.myListInterview

	.myBlockModal{
		background-color: #fff;
		border-radius: 10px;
		padding: 60px;
		margin: 0 auto;
		position: relative;
		width:min(100%,1000px);
		@include g.tabletP{
			width: min(100%,calc(100% - 40px));
			padding: 20px;
		}

		&__inner{
			@include g.tabletPmin{
				display: grid;
				gap: 0 46px;
				grid-template:
				"side catch"
				"side body" 1fr /
				30% calc(100% - 30% - 46px);
			}

			@include g.tabletP{
				@include g.clearfix;
				max-height: calc(100vh - 20px - 20px - 1em - 20vh);
				max-height: calc(100dvh - 20px - 20px - 1em - 10vh);
				overflow: auto;
			}

		}

		&__side{
			text-align: center;
			@include g.tabletPmin{
				grid-area: side;
			}
			@include g.tabletP{
				float: left;
				width: 36%;
				margin-right: 10px;
				margin-bottom: 5px;
			}
		}

		&__image{
			@include g.tabletP{
			}
		}

		&__info{
			text-align: left;
			@include g.fz(15);
			font-weight: 500;
			line-height: math.div(25,15);
			@include g.tabletPmin{
				margin-top: 20px;
			}
			@include g.tabletP{
				margin-top: 5px;
				@include g.fz(12);
				line-height: 1.4;
			}
		}

		&__catch{
			margin-bottom: 25px;
			@include g.fz(22);
			font-weight: 500;
			line-height: math.div(35,22);
			@include g.tabletPmin{
				grid-area: catch;
			}
			@include g.tabletP{
				margin-bottom: 15px;
				@include g.fz(18);
			}
		}

		&__body{
			font-weight: 500;
			@include g.tabletPmin{
				grid-area: body;
			}
		}

		&__close{
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			margin-top: 40px;
			text-align: center;
			@include g.tabletP{
				margin-top: 20px;
			}

			&-btn{
				border:none;
				display: block;
				background-color: transparent;
				border-bottom: 1px solid nth(g.$baseFontColor,1);
				padding-bottom: 3px;
				text-decoration: none;
				text-align: center;
				@include g.fz(15);
				line-height: math.div(25,15);
			}
		}

	}//.myBlockModal




}
