@charset "utf-8";

/*
coin_laundry case
*/


@use "global" as g;
@use 'sass:math';

$_idParentTop:products;
$_idParent:coin_laundry;
$_id:case;
$_imgPath:'../img/#{$_idParentTop}/#{$_idParent}/#{$_id}/index/';

body#page_#{$_idParentTop}.-#{$_idParent}.-#{$_id} {

	$_root: &;

	.myListCaseIndex {
		$_: ".myListCaseIndex";
		display: grid;
		grid-template: auto / repeat(2, 1fr);
		gap: 30px 26px;
		justify-content: space-between;
		margin: 0 auto;
		@include g.sphoneP {
			grid-template-columns: repeat(1, 1fr);
			justify-content: center;
			max-width: 450px;
		}
		&__item {
			&-inner {
				display: flex;
				flex-direction: column;
				position: relative;
				color: inherit;
				text-decoration: none;
				height: 100%;
				@include g.tabletPmin {
					@media(hover: hover) {
						&:hover {
							color: nth(g.$mainColorProducts, 1)
						}
					}
				}
			}
		}
		&__image {
			margin-bottom: 18px;
			@include g.tabletP {
				margin-bottom: 15px;
			}
			img {
				width: 100%;
			}
		}
		&__title {
			@include g.fz(16);
			line-height: math.div(28, 16);
			margin-bottom: auto;
			@include g.tabletP {
				@include g.fz(13);
			}
		}
		&__address {
			position: relative;
			@include g.fz(14);
			line-height: math.div(24.5, 14);
			padding-right: 32px + 10px;
			margin-top: 20px;
			@include g.tabletP {
				@include g.fz(12);
				margin-top: 9px;
				padding-right: 26px + 10px;
			}
			@include g.sphonePmin {
				@at-root #{$_root} #{$_}__item:not(:only-child) #{$_}__address {
					min-height: math.div(24.5, 14) * 3em;
					margin-top: 11px;
				}
			}
			@at-root #{$_}__item-inner[href] #{$_}__address {
				&::after {
					content: "";
					background-color: currentColor;
					mask: url(#{g.$cmnPath}icon_arrow01.svg) no-repeat 0 0;
					mask-size: 17px;
					position: absolute;
					top: .625em;
					right: 0;
					width: 32px;
					height: 10px;
					@include g.tabletP {
						top: 0.75em;
						mask-size: 14px;
						width: 26px;
					}
				}
			}
		}
	}//.myListCaseIndex

	.myBlockStore{
		border:solid 1px #b8b8b8;
		padding: 16px 26px 36px;
		margin-top: 46px;

		&__name{
			margin-bottom: 10px;
		}

		&__inner{
			p + p{
				margin-top: 8px;
			}
		}

		&__url{
			@include g.fz(14);
		}


	}//.myBlockStore
}
