@charset "utf-8";

/*
recruit message
*/


@use "global" as g;
@use 'sass:math';

$_idParent:recruit;
$_id:message;
$_imgPath:'../img/#{$_idParent}/#{$_id}/';

body#page_#{$_idParent}.-#{$_id} {

	.myBlockSection{
		& + .myBlockSection{
			margin-top: 70px;
			@include g.tabletP{
				margin-top: 40px;
			}
		}
		.myBlockSection{
			& + .myBlockSection{
				@include g.tabletP{
				}
			}

		}
	}//.myBlockSection

	.myBlockLead{
		margin-bottom: 60px;
		@include g.tabletP{
		}

		&Head{
			position: relative;
			margin-bottom: 80px;
			@include g.tabletP{
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				gap:25px 0;
				margin-bottom: 40px;
			}

			&__detail{

				@include g.tabletPmin{
					position: absolute;
					bottom: 13.5%;
					left: 100px;
					color:#fff;
				}
				@include g.tabletP{
					width: 100%;
					order:2;
					text-align: right;
				}

				&-job{
					font-weight: 500;
				}

				&-name{
					font-weight: 500;
					@include g.fz(30);
					line-height: math.div(50,30);
					@include g.tabletP{
						@include g.fz(24);
					}
				}
				&-en{
					margin-top: 10px;
					@include g.tabletP{
						img{
							width: 60%;
							filter: brightness(0) invert(0);//黒
						}
					}
				}
			}

			&__image{
				text-align: center;
				@include g.tabletP{
					order:1;
				}
			}
		}
	}//.myBlockLead

	.myTxtBlock{
		p + p{
			margin-top: 30px;
		}
	}//.myTxtBlock

	.myBlockImage{
		margin-top: 70px;
		text-align: center;
		@include g.tabletP{
			margin-top: 20px;
		}
	}//.myBlockImage

}
