@charset "utf-8";

/*
maintenance maintenance
*/


@use "global" as g;
@use 'sass:math';

$_idParent:maintenance;
$_id:maintenance;
$_imgPath:'../img/#{$_idParent}/#{$_id}/';

body#page_#{$_idParent}.-#{$_id} {


	.myList01{
		$_:'.myList01';
		@include g.tabletPmin{
			display: flex;
			flex-wrap: wrap;
			gap: 0 15px;
		}

		& + #{$_}{
			margin-top: 25px;
			@include g.tabletP{
				margin-top: 15px;
			}
		}

		&__title{
			@include g.tabletP{
				margin-bottom: 5px;
			}
		}

		&__inner{
		}

		&__list{

			&-item{
				&:not(:last-child){
					margin-bottom: 5px;
				}
			}
		}
	}//.myList01









}
