@charset "utf-8";

@use "global" as g;
@use 'sass:math';

#footer {}
.gFooter {
	$_: &;
    @include g.print{
        display: none;
    }
	position: relative;
	padding: 63px 0 63px;
	z-index: 3;
	@include g.tabletP {
		padding: 28px 11px;
	}
	&::before {
		content: "";
		background-color: #EBEBEB;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		max-width: 1205px;
		height: 2px;
		margin: 0 auto;
	}
	&.-products {
		background-color: nth(g.$subColorProducts, 3);
		color: g.$baseFontColorProducts;
		&::before {
			content: none;
		}
	}
	&__pageTop {
		display: block;
		position: fixed;
		bottom: 25px;
		left: calc(100% - 22px);
		@include g.fz(12);
		font-family: g.$fontAlpha;
		line-height: 1;
		text-decoration: none;
		white-space: nowrap;
		transition: .2s;
		transform-origin: left bottom;
		transform: rotate(-90deg);
		z-index: 1;
		#{$_}.-products & {
			color: #fff;
			text-shadow: 0 0 2px nth(g.$subColorProducts, 3), 0 0 2px nth(g.$subColorProducts, 3), 0 0 2px nth(g.$subColorProducts, 3), 0 0 2px nth(g.$subColorProducts, 3), 0 0 2px nth(g.$subColorProducts, 3);
		}
		@include g.narrowPC {
			display: none;
			// bottom: 13px;
			// left: calc(100% - 15px);
		}
		&[aria-hidden = "true"] {
			opacity: 0;
			visibility: hidden;
		}
	}
	&__inner {
		@include g.tabletPmin {
			display: grid;
			grid-template:
				"group02 group01" auto
				"copyright group01" 1fr / auto minmax(auto, 606px);
			;
			gap: 51px 60px;
			justify-content: space-between;
		}
	}
	&__group01 {
		grid-area: group01;
		@include g.tabletP {
			margin-bottom: 29px;
		}
	}
	&__group02 {
		grid-area: group02;
		@include g.tabletPmin {
			padding-top: 5px;
		}
	}
	&__listbox {
		@include g.tabletPmin {
			display: grid;
			justify-content: space-between;
			grid-template: auto / repeat(3, auto);
			gap: 37px 0;
		}
		@include g.tabletP {
			display: flex;
			justify-content: space-between;
			max-width: 282px;
			margin: 0 auto;
		}
	}
	&__list {
		&-item {
			$__: &;
			position: relative;
			@include g.fz(14);
			line-height: math.div(30, 14);
			#{$_}.-products & {
				color: #fff;
			}
			@include g.tabletPmin {
				display: flex;
			}
			@include g.tabletP {
				@include g.fz(13);
				line-height: math.div(26, 13);
				&:not(:last-child) {
					margin-bottom: 6px;
				}
			}
			&.-title {
				@include g.fz(24);
				font-family: g.$fontAlpha;
				font-weight: 300;
				line-height: 1;
				margin-bottom: 13px;
			}
			&.-type02 {
				#{$__}:not(.-type02) + & {
					margin-top: 6px;
				}
				&::before {
					content: "\2212";
					flex-shrink: 0;
					margin-right: 12px;
				}
			}
			&.-type02 + &:not(.-type02) {
				margin-top: 4px;
			}
		}
		&-link {
			display: flex;
			align-items: center;
			justify-content: space-between;
			position: relative;
			color: inherit;
			text-decoration: none;
			height: 100%;
			@include g.tabletPmin {
				&.-type02 {
					line-height: 1.4;
					padding: 6px 0 5px;
				}
			}
			@include g.tabletP {
				&::after {
					content: "";
					border-top: 1px solid currentColor;
					border-right: 1px solid currentColor;
					display: block;
					width: 7px;
					height: 7px;
					transform: rotate(45deg);
					margin-left: 14px;
				}
			}
		}
	}
	&__logo {
		grid-column: span 2;
		text-align: center;
		width: 197px;
		margin: 0 auto 26px;
		@include g.tabletP {
			width: 164px;
			margin-bottom: 11px;
		}
		img {
			width: 100%;
		}
	}
	&__sns {
		display: grid;
		grid-auto-flow: column;
		gap: 0 17px;
		justify-content: center;
		@include g.tabletPmin {
			margin-bottom: 34px;
		}
		@include g.tabletP {
			gap: 0 13px;
			justify-self: flex-end;
		}
	}
	&__lang {
		display: grid;
		grid-auto-flow: column;
		justify-content: center;
		&-item {
			color: #A7ABB3;
			@include g.fz(14);
			line-height: 1.4;
			@include g.tabletP {
				@include g.fz(13);
			}
			#{$_}.-products & {
				// color: #8B8C91;
			}
			&:not(:last-child) {
				&::after {
					content: "\ff5c";
					margin: 0 0.25em;
				}
			}
		}
		&-link {
			color: g.$baseFontLinkColor;
			text-decoration: none;
			#{$_}.-products & {
				color: #fff;
			}
		}
	}
	&__copyright {
		grid-area: copyright;
		color: #8F9195;
		@include g.fz(12);
		line-height: 1.4;
		text-align: center;
		#{$_}.-products & {
			color: #929BB8;
		}
		@include g.tabletP {
			@include g.fz(11);
			margin-top: 17px;
		}
	}
}
