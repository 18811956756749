@charset "utf-8";

/*
about_us
*/


@use "global" as g;
@use 'sass:math';

$_id:about_us;
$_imgPath:'../img/#{$_id}/';

body#page_#{$_id} {
	.myBlock01 {
		&__lead {
			margin-bottom: 57px;
			@include g.fz(16);
		}

		&__list {
			display: flex;
			justify-content: center;
			gap: 63px;
			margin-bottom: 132px;

			@include g.tabletP{
				gap: 20px;
				margin-bottom: 50px;
			}
		}

		&__def {
			@include g.tabletPmin{
				padding-top: 12px;
			}
		}
	}//.myBlock01

	.myBlock02 {
		display: flex;
		justify-content: space-between;
		gap: 149px;
		margin-bottom: 210px;
		@include g.tabletP {
			gap: 25px;
			margin-bottom: 50px;
			flex-direction: column;
		}

		&__txtArea {
			flex: 1;
		}

		&__imgArea {
			width: math.percentage(math.div(420,1000));
			display: flex;
			flex-direction: column;
			gap: 5px;

			@include g.tabletP{
				width: 100%;
			}

			&.-type02 {
				position: relative;
				padding-top: 5px;
				&::after {
					content: "";
					background: url(#{$_imgPath}img10.png) no-repeat center;
					background-size: 178px 181px;
					width: 178px;
					height: 181px;
					position: absolute;
					bottom: -13px;
					left: -42px;
					margin: auto;

					@include g.tabletP {
						background-size: 89px 90.5px;
						width: 89px;
						height: 90.5px;
						bottom: -16px;
						left: -5px;
					}
				}
			}

			&-item {
				img {
					@include g.tabletP{
						width: 100%;
					}
				}
			}
		}

		&__txt {
			font-weight: 500;
			@include g.fz(16);
			line-height: math.div(30,16);
			letter-spacing: normal;
		}

		&__btn {
			margin-top: 50px;
			@include g.tabletP{
				margin-top: 25px;
			}
		}

		&.-type01 {
			@include g.tabletPmin{
				margin-bottom: 129px;
			}
		}

		&.-type02 {
			display: block;
			@include g.tabletPmin{
				margin-bottom: 166px;
			}
		}

		&.-type03 {
			@include g.tabletPmin{
				margin-bottom: 145px;
			}
		}
	}//.myBlock02

	.myBlock03 {
		display: flex;
		justify-content: start;
		flex-direction: column;
		gap: 104px;
		padding-top: 20px;
		@include g.tabletP {
			gap: 50px;
		}

		&__item {
			display: flex;
			justify-content: space-between;
			gap: 158px;
			@include g.tabletP {
				gap: 5px;
				flex-direction: column;
			}
		}

		&__txtArea {
			flex: 1;
		}

		&__imgArea {
			position: relative;
			width: math.percentage(math.div(420,1000));
			padding-top: 10px;

			@include g.tabletP{
				width: 100%;
			}
			&.-type01 {
				&::after {
					content: "";
					background: url(#{$_imgPath}img06.png) no-repeat center;
					background-size: 185px 195px;
					width: 185px;
					height: 195px;
					position: absolute;
					bottom: -44px;
					left: -43px;
					margin: auto;
					@include g.tabletP {
						background-size: 92.5px 97.5px;
						width: 92.5px;
						height: 97.5px;
						bottom: -16px;
						left: -5px;
					}
				}
			}
			&.-type02 {
				&::after {
					content: "";
					background: url(#{$_imgPath}img08.png) no-repeat center;
					background-size: 304px 163px;
					width: 304px;
					height: 163px;
					position: absolute;
					bottom: -44px;
					left: -41px;
					margin: auto;
					@include g.tabletP {
						background-size: 152px 81.5px;
						width: 152px;
						height: 81.5px;
						bottom: -16px;
						left: -5px;
					}
				}
			}

			img {
				@include g.tabletP{
					width: 100%;
				}
			}
		}

		&__ttl {
			font-weight: 500;
			@include g.fz(30);
			line-height: math.div(50,30);
			letter-spacing: normal;
			margin-bottom: 20px;

			@include g.tabletP{
				@include g.fz(20);
				margin-bottom: 10px;
			}
		}

		&__btn {
			margin-top: 50px;
			@include g.tabletP{
				margin-top: 25px;
			}
		}
	}//.myBlock03

	.myBLockHistory {
		$_: '.myBLockHistory';

		&__table {
			&-inner {
				border-left: 3px solid #ECD5D9;
			}
		}
		&__item {
			display: flex;
			justify-content: start;
			gap: 57px;

			@include g.tabletP{
				gap: 10px;
			}

			&:first-child {
				#{$_}__detail {
					border-top: 2px solid #ebebeb;
				}
			}
		}
		&__date {
			position: relative;
			min-width: 150px;
			padding: 18px 0 18px 24px;

			@include g.tabletP{
				min-width: 75px;
				padding: 15px 0 15px 10px;
			}

			&-txt {
				position: relative;
				display: inline-block;
				font-family: g.$fontAlpha;
				font-weight: 600;
				color: nth(g.$mainColor, 1);
				@include g.fz(30);

				@include g.tabletP{
					@include g.fz(20);
				}
				&::before {
					content: "";
					width: 6.5px;
					height: 6.5px;
					background: nth(g.$mainColor, 1);
					border-radius: 50%;
					position: absolute;
					top: 0;
					bottom: 0;
					left: -34px;
					margin: auto;
					border: 5px solid #F6F6F4;
					box-sizing:content-box;
					@include g.tabletP{
						left: -20px;
					}
				}
			}
		}

		&__detail {
			flex: 1;
			padding: 28px 0 30px;
			border-bottom: 2px solid #EBEBEB;
			@include g.fz(16);
			font-weight: 500;
			line-height: math.div(30,16);

			@include g.tabletP{
				padding: 18px 0 17px;
			}

			&-img {
				margin-top: 20px;
				@include g.tabletP{
					margin-top: 20px;
				}

				&:not(:last-child){
					margin-bottom: 20px;
				}

				& + #{$_}__detail-head{
					margin-top: 20px;
				}
			}

			&-head {
				display: block;
				@include g.fz(20);
				font-weight: 500;
				line-height: math.div(30,20);
				color: nth(g.$mainColor, 1);
				margin-bottom: 10px;
			}
		}
	}//.myBLockHistory
}

