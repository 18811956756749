@charset "utf-8";

/*
products_cleaning_machine
*/


@use "global" as g;
@use 'sass:math';

$_idParent:products;
$_id:cleaning_machine;
$_imgPath:'../img/#{$_idParent}/#{$_id}/';

body#page_#{$_idParent}.-#{$_id} {
	.myBlockCase {
		$_: '.myBlockCase';
		max-width: 1900px;
		margin: 0 auto;
		@include g.tabletPmin {
			display: flex;
			flex-direction: row-reverse;
		}
		& > * {
			@include g.tabletPmin {
				flex-basis: 100%;
			}
		}
		&__image {
			img {
				object-fit: cover;
				width: 100%;
				height: 100%;
			}
		}
		&__body {
			display: flex;
			align-items: center;
		}
		&__catch {
			@include g.fz(24);
			line-height: math.div(40, 24);
			text-align: center;
			margin-bottom: 23px;
			@include g.tabletP {
				@include g.fz(19);
				margin-bottom: 20px;
			}
		}
		&__txt {
			@include g.fz(15);
			line-height: math.div(30, 15);
			text-align: center;
			margin-bottom: 32px;
			@include g.tabletP {
				@include g.fz(13);
				margin-bottom: 22px;
			}
		}
		&__btn {
			display: flex;
			justify-content: center;
		}
	}//.myBlockCase

	.myBlockProducts {
		$_: '.myBlockProducts';
		&__txt {
			@include g.fz(15);
			line-height: math.div(30, 15);
			text-align: center;
			margin: -6px 0 22px;
			@include g.tabletP {
				@include g.fz(13);
				margin: -5px 0 18px;
			}
		}
	}//.myBlockProducts

	.myListProducts {
		$_: ".myListProducts";
		display: grid;
		gap: 33px 25px;
		grid-template: auto / repeat(auto-fit, 284px);
		justify-content: center;
		position: relative;
		margin: 0 auto;
		@include g.tabletP {
			grid-template-columns: repeat(2, 1fr);
			max-width: 284px*2;
		}
		&:not(:last-child) {
			margin-bottom: 65px;
			@include g.tabletP {
				margin-bottom: 44px;
			}
		}
		&__link {
			display: block;
			position: relative;
			color: g.$baseFontLinkColorProducts;
			line-height: math.div(30, 28);
			text-decoration: none;
			height: 100%;
			padding-bottom: 24px;
			@include g.tabletPmin {
				@media(hover: hover) {
					&:hover {
						opacity: 1;
					}
				}
			}
		}
		&__head {
			display: block;
			position: relative;
			text-decoration: none;
		}
		&__image {
			position: relative;
			z-index: 1;
			img {
				width: 100%;
			}
		}
		&__title {
			display: flex;
			justify-content: center;
			position: relative;
			@include g.fz(18);
			line-height: math.div(30, 28);
			margin-top: 10px;
			z-index: 1;
			@include g.tabletP {
				@include g.fz(15);
			}
			&::before {
				content: "";
				border-top: 2px solid currentColor;
				border-right: 2px solid currentColor;
				flex-shrink: 0;
				display: block;
				width: 8px;
				height: 8px;
				margin-right: 10px;
				transform: translateY(6px) rotate(45deg);
				@include g.tabletP {
					border-top-width: 1px;
					border-right-width: 1px;
				}
			}
		}
		&__over {
			background: rgba(nth(g.$mainColorProducts, 1), .65);
			display: none;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			position: absolute;
			top: 0;
			left: 0;
			color: #fff;
			@include g.fz(16);
			line-height: 1;
			width: 100%;
			height: 100%;
			pointer-events: none;
			transition: opacity .2s;
			z-index: 2;
			&-icon {
				background-color: #fff;
				border: 1px solid #fff;
				border-radius: 50%;
				display: block;
				position: relative;
				width: 52px;
				height: 52px;
				margin-top: 12px;
				@include g.tabletP {
					width: 35px;
					height: 35px;
					margin-top: 10px;
				}
				&::before {
					content: "";
					background-color: nth(g.$mainColorProducts, 1);
					mask: url(#{g.$cmnPath}icon_arrow02.svg) no-repeat center;
					mask-size: 22px;
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					@include g.tabletP {
						mask-size: 18px;
					}
				}
			}
			@include g.tabletPmin {
				@media(hover: hover) {
					display: flex;
					@at-root #{$_}__link:not(:hover) #{$_}__over {
						opacity: 0;
					}
					@at-root #{$_}__link:hover #{$_}__over {
						opacity: 1;
					}
				}
			}
			@include g.tabletP {
				display: none;
			}
		}
	}
}//.myListProducts