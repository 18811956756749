@charset "utf-8";

/*
maintenance errorcode
*/


@use "global" as g;
@use 'sass:math';

$_idParent:maintenance;
$_id:errorcode;
$_imgPath:'../img/#{$_idParent}/#{$_id}/';

body#page_#{$_idParent}.-#{$_id} {

	.myBlockErrorCode{
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		gap:18px;
		background-color: #F6F8FD;
		padding:36px 10px 40px;
		margin-bottom: 46px;
		border-width: 1px;
		border-style: solid;
		border-radius: 7px;
		color: nth(g.$mainColorProducts,1);
		text-align: center;
		font-weight: 500;
		line-height: 1;
		@include g.tabletP{
			padding:26px 10px 30px;
		}


		&__title{
			font-size: math.percentage(math.div(22,16));
			@include g.tabletPmin{
				padding-top: 0.3em;
			}
			@include g.tabletP{
			}
		}

		&__detail{
			font-size: math.percentage(math.div(50,16));
			@include g.tabletPmin{
			}
		}

	}//.myBlockErrorCode

	.myBlockSection{
		& + .myBlockSection{
			margin-top: 40px;
		}
	}//.myBlockSection

	.myTxtProducts{
		@include g.fz(20);
		@include g.tabletP{
			@include g.fz(16);
		}
	}//.myTxtProducts

	.myBlockResult{
		@include g.fz(15);
		line-height: math.div(25,15);
		@include g.tabletP{
			@include g.fz(12);
		}

		.modListNormal{
			line-height: math.div(25,15);
		}

		&__title{
			margin-bottom: 10px;
			font-weight: 500;
			@include g.fz(16);
			@include g.tabletP{
				margin-bottom: 5px;
				@include g.fz(14);
			}
		}

		&__list{
			& + p{
				margin-top: 8px;
			}

			&-title{
				&::before{
					content:"\25CF";
				}
			}
			&-detail{
			}
		}

	}//.myBlockResult




}
