@charset "utf-8";

/*
download catalog
*/


@use "global" as g;
@use 'sass:math';

$_idParent:download;
$_id:catalog;
$_imgPath:'../img/#{$_idParent}/#{$_id}/';

body#page_#{$_idParent}.-#{$_id} {

	.myList{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		gap:35px;
		@include g.tabletP{
			gap:35px 20px;
		}

		@include g.sphoneP{
			gap:20px;
		}

		&__item{
			width: calc((100% - 35px*3)/4);
			@include g.tabletP{
				width: calc((100% - 20px*2)/3);
			}
			@include g.sphoneP{
				width: calc((100% - 20px*1)/2);
			}
		}

		&__image{
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			align-items: flex-end;
			margin-bottom: 20px;
		}

		&__txt{
			margin-bottom: 20px;
		}

	}//.myList






}
