@charset "utf-8";

@use "_variables" as g;
@use 'sass:math';

@mixin narrowPC {
	@media only screen and (max-width: map-get(g.$bp, 'narrowPC')) {
		@content;
	}
}

@mixin narrowPCmin {
	@media only screen and (min-width: #{map-get(g.$bp, 'narrowPC') + 1}) {
		@content;
	}
}

@mixin tabletP {
	@media only screen and (max-width: map-get(g.$bp, 'tabletP')) {
		@content;
	}
}

@mixin tabletPmin {
	@media only screen and (min-width: #{map-get(g.$bp, 'tabletP') + 1}) {
		@content;
	}
}

@mixin sphoneP {
	@media only screen and (max-width: map-get(g.$bp, 'sphoneP')) {
		@content;
	}
}

@mixin sphonePmin {
	@media only screen and (min-width: #{map-get(g.$bp, 'sphoneP') + 1}) {
		@content;
	}
}

@mixin sphoneSE {
	@media only screen and (max-width: map-get(g.$bp, 'sphoneSE')) {
		@content;
	}
}

@mixin max($max) {
	@media only screen and (max-width: #{$max}px) {
		@content;
	}
}

@mixin min($min) {
	@media only screen and (min-width: #{$min}px) {
		@content;
	}
}

@mixin print {
  @media print{
    @content;
  }
}

@mixin retina {
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
        @content;
    }
}


// clearfix
@mixin clearfix() {
	&:after {
		clear:both;
		content:".";
		display:block;
		height:0;
		visibility:hidden;
	}
}

// font-size
@mixin fz($size: g.$baseFont, $important:false) {
	$result:math.div($size,16);
	font-size: if($important,($result + rem!important),($result + rem));
}

@function get_vw($size, $viewport:375){
	$rate: math.div(100,$viewport);
	@return $rate * $size * 1vw;
}

@mixin animation($animation-name,$s,$easing,$count,$end:none,$delay:0) {
	animation: $animation-name;
	animation-delay: $delay;
	animation-duration:$s;
	animation-fill-mode: $end;
	animation-iteration-count:$count;
	animation-timing-function: $easing;
}

@mixin accessibilityHidden {
	border: 0;
	clip: rect(0 0 0 0);
	clip-path: inset(50%);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: fixed;
	white-space: nowrap;
	width: 1px;
}
