@charset "utf-8";

/*
top
*/


@use "global" as g;
@use 'sass:math';

$_id:top;
$_imgPath:'../img/#{$_id}/';

body#page_#{$_id} {


	.myMainvisual{
		position: relative;
		height: 100vh;
		height: 100svh;
		display: flex;
		flex-wrap: wrap;
		align-items: center;

		&__movie{
			background-color: rgba(#333,0.5);
			background-image: url(#{$_imgPath}bg_movie.webp);
			background-size: 6px auto;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			width: 100%;
			height: 100vh;
			height: 100svh;
			overflow: hidden;

			@include g.retina{
				background-image: url(#{$_imgPath}bg_movie@2x.webp);
			}

			img{
			}

			video{
				mix-blend-mode: multiply;
				position: absolute;
				top: 50%;
				left: 50%;
				min-width: 100%;
				min-height: 100%;
				transform: translate(-50%, -50%);
			}
		}


		&__inner{
			position: relative;
			width: 100%;
		}

		&__en{
			color:#fff;
			font-family: g.$fontAlpha;
			font-weight: 600;
			@include g.fz(85);
			line-height: 0.9;
			@include g.tabletP{
				font-size: g.get_vw(60);
			}
			&-txt01{
				color: nth(g.$mainColor,1);
			}
		}

		&__catch{
			margin-top: 40px;
			color:#fff;
			font-weight: 500;
			@include g.fz(22);
			line-height: math.div(40,22);
			@include g.tabletP{
				margin-top: g.get_vw(20);
				font-size: g.get_vw(16);
			}
		}

		&__scroll{
			position: absolute;
			bottom: 54px;
			left: 0;
			right: 0;
			text-align: center;
			@include g.tabletP{
				width: 20vw;
				margin: 0 auto;
				bottom: 8vw;
			}
		}
	}//myMainvisual

	.mfp-iframe-holder .mfp-content{
		@include g.tabletPmin{
			max-width: 70%;
		}
	}

	.myBlockUnder{
		padding-top: 120px;
		margin-top: 2px;
		@include g.tabletP{
			padding-top: 60px;
		}
	}

	.myBlock01{

		& + .myBlock01{
			margin-top: 120px;
			@include g.tabletP{
				margin-top: 60px;
			}
		}

		&__inner{
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			gap:0 74px;
			@include g.tabletP{
				gap: 50px 0;
			}
		}

		&__detail{
			width: calc(100% - 46% - 74px);
			@include g.tabletP{
				width: 100%;
			}

		}

		&__image{
			width: 46%;
			@include g.tabletP{
				width: 100%;
			}
		}

		&__catch{
			margin-bottom: 20px;
			font-weight: 500;
			@include g.fz(30);
			line-height:math.div(40,30);
			@include g.tabletP{
				@include g.fz(20);
			}
		}

		&__txt{
			font-weight: 500;
			& + *{
				margin-top: 40px;
			}

		}

		&__btn{
			margin-top: 50px;
			@include g.tabletP{
				margin-top: 30px;
			}
		}
	}//.myBlock01

	.myListMenu{

		$_:'.myListMenu';

		&__item{
			border-bottom: 2px solid #ebebeb;
			&:first-child{
				border-top: 2px solid #ebebeb;
			}
		}

		&__link{
			display: block;
			position: relative;
			padding: 20px 70px 20px 0;
			text-decoration: none;
			@include g.fz(15);
			font-weight: 500;
			@include g.tabletP{
				padding: 16px 70px 16px 0;
				@include g.fz(13);
			}
			@media(hover: hover) {
				&:hover{
					opacity: 1;
					color: nth(g.$mainColor,1);
					#{$_}__icon {
						background-color: currentColor;
						&::before {
							background-color: #fff;
						}
					}
				}
			}

		}

		&__icon {
			background-color: #fff;
			border: 1px solid #fff;
			border-radius: 50%;
			position: absolute;
			top: 0;
			bottom: 0;
			right: 16px;
			width: 35px;
			height: 35px;
			margin: auto;
			@include g.tabletP {
				width: 24px;
				height: 24px;
			}
			&::before {
				content: "";
				background-color: currentColor;
				mask: url(#{g.$cmnPath}icon_arrow01.svg) no-repeat center;
				mask-size: 17px;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				@include g.tabletP {
					mask-size: 12px;
				}
			}
		}

	}//.myListMenu

	.myBlockNews{
		margin-top: 140px;
		margin-bottom: 120px;
		@include g.tabletP{
			margin-top: 70px;
		}

		&__tab{
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			gap:22px;
			margin-bottom: 60px;
			@include g.tabletP{
				gap:10px;
				margin-bottom: 40px;
			}

			&-item{
			}

			&-btn{
				display: block;
				transition:background-color 0.3s ease;
				min-width: 115px;
				padding: 9px 10px;
				background-color: #fff;
				border:none;
				border-radius: 99999px;
				@include g.fz(15);
				line-height: 1.5;
				font-weight: 500;
				text-align: center;

				&[aria-expanded="true"]{
					background-color: #93959F;
					color:#fff;
				}

				@media(hover: hover) {
					&:hover{
						opacity: 1;
						background-color: #93959F;
						color:#fff;
					}
				}

				@include g.tabletP{
					min-width: 80px;
					padding: 5px;
					@include g.fz(13);
				}
			}
		}

		&__btn{
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			margin-top: 60px;
		}


	}//.myBlockNews


}