@charset "utf-8";
/*
#overview
modBlock
*/

@use "global" as g;
@use 'sass:math';

/*
#styleguide
.modBlockXXX

```
```
*/
.modBlockXXX {

}//.modBlockXXX

/*
#styleguide
.modBlockProductsMv01

```
<div class="modBlockProductsMv01">
	<div class="modBlockProductsMv01__item"><img src="/assets/img/products/cleaning_machine/index/img_mv01.webp" srcset="/assets/img/products/cleaning_machine/index/img_mv01@2x.webp 2x" alt=""></div>
</div><!-- /.modBlockProductsMv01 -->
```
*/
.modBlockProductsMv01	 {
	img {
		width: 100%;
	}
}//.modBlockProductsMv01

/*
#styleguide
.modBlockProductsIntro

```
<div class="modBlockProductsIntro">
	<p class="modBlockProductsIntro__catch">安定性・耐久性を大幅にアップ</p>
	<p class="modBlockProductsIntro__txt">WNシリーズ洗濯機はあらゆる作業工程について、考え抜かれた機能デザインを持ち合わせています。<br>洗濯時のスムーズな工程、安全性、耐久性など高いレベルでお客様を満足いただける設計をおこなっています。<br>オプション装着（ボイラー設備接続）で、熱水洗濯が可能になります。</p>
	<div class="modBlockProductsIntro__child">
		<p class="modTitle07 -products">施設</p>
		<p>食品工場、製薬工場、自動車関連工場の民間企業から、病院・介護の施設、ホテル・旅館、理容院、学生寮・社員寮、温浴施設、ゴルフ場など、多くの自社設備に対応した業務用乾燥機です。</p>
	</div>
	<div class="modBlockProductsIntro__child">
		<p class="modTitle07 -products">その他の業務用乾燥機</p>
		<ul>
			<li><a href="/products/cleaning_machine/electric_dryer/">電気乾燥機</a></li>
			<li><a href="/products/cleaning_machine/steam_dryer/">蒸気乾燥機</a></li>
		</ul>
	</div>
	<dl class="modListProductsPickup01">
		<div class="modListProductsPickup01__item">
			<p class="modListProductsPickup01__image"><img src="/assets/img/products/cleaning_machine/laundry_machines/index/img_pickup01.svg" alt=""></p>
			<div class="modListProductsPickup01__body">
				<div class="modListProductsPickup01__head">
					<p class="modListProductsPickup01__catch">オプション装備により、熱水洗濯の<br><span class="modListProductsPickup01__catchStrong01"><span class="modListProductsPickup01__catchStrong02">80℃、10分</span>を可能にします。</span></p>
					<p class="modListProductsPickup01__caution"><span class="modListProductsPickup01__caution-icon">※</span>オプション装着に際し、ボイラー設備への接続が必要になります。</p>
				</div>
				<p class="modListProductsPickup01__txt">安定した高速回転に高い防音・防振性を兼ね備え、従来の全自動洗濯機から安定性・耐久性を大幅にUP。</p>
			</div>
		</div>
	</dl>
	<ul class="modBlockProductsIntro__info">
		<li class="modBlockProductsIntro__info-item">※ガス乾燥機とは、電気ではなくガスの力で乾燥させる衣類乾燥機です。</li>
		<li class="modBlockProductsIntro__info-item">※業務用ガス乾燥機の仕様や値段につきましては、上記の製品リストからご希望の製品をクリックしてご確認ください。</li>
		<li class="modBlockProductsIntro__info-item">※各製品の設置方法やお取り扱いについて詳しくは、下記のお問い合わせ先までお気軽にご連絡ください。</li>
	</ul>
	<p class="modBlockProductsIntro__image">
		<picture>
			<source srcset="/assets/img/products/cleaning_machine/barrier/index/img_intro01_sp.webp 1x,/assets/img/products/cleaning_machine/barrier/index/img_intro01_sp@2x.webp 2x" media="(max-width: 768px)">
			<img src="/assets/img/products/cleaning_machine/barrier/index/img_intro01.webp" srcset="/assets/img/products/cleaning_machine/barrier/index/img_intro01@2x.webp 2x" alt="バリア式システムのイメージ">
		</picture>
	</p>
</div><!-- /.modBlockProductsIntro -->
```
*/
.modBlockProductsIntro {
	&:not(:first-child) {
		border-top: 1px solid #B8B8B8;
		margin-top: 76px;
		padding-top: 68px;
		@include g.tabletP {
			margin-top: 51px;
			padding-top: 46px;
		}
	}
	&__catch {
		@include g.fz(40);
		font-weight: 500;
		line-height: math.div(30, 28);
		margin-bottom: 28px;
		@include g.tabletP {
			@include g.fz(27);
			margin-bottom: 19px;
		}
	}
	&__txt {
		@include g.fz(16);
		@include g.tabletP {
			@include g.fz(13);
		}
	}
	&__image {
		&:not(:first-child) {
			text-align: center;
			margin-top: 51px;
			@include g.tabletP {
				margin-top: 34px;
			}
		}
	}
	&__child {
		margin-top: 50px;
		@include g.tabletP {
			margin-top: 34px;
		}
		ul {
			padding-left: 20px;
			li {
				list-style-type: revert;
			}
		}
	}
	&__info {
		@include g.fz(16);
		margin-top: 40px;
		@include g.tabletP {
			@include g.fz(13);
			margin-top: 24px;
		}
		&.-type02 {
			@include g.fz(14);
			margin-top: 15px;
			@include g.tabletP {
				@include g.fz(12);
				margin-top: 10px;
			}
		}
	}
}//.modBlockProductsIntro

/*
#styleguide
.modBlockProductsDetail

```
<div class="modBlockProductsDetail">
	<p class="modBlockProductsDetail__image">
		<img src="/assets/img/products/cleaning_machine/laundry_machines/wn403h/img01.webp" srcset="/assets/img/products/cleaning_machine/laundry_machines/wn403h/img01@2x.webp 2x" alt="">
		<img src="/assets/img/products/cleaning_machine/laundry_machines/wn403h/img01.webp" srcset="/assets/img/products/cleaning_machine/laundry_machines/wn403h/img01@2x.webp 2x" alt="">
	</p>
	<div class="modBlockProductsDetail__body">
		<div class="modBlockProductsDetail__group">
			<div class="modBlockProductsDetail__head">
				<h1 class="modBlockProductsDetail__title">WN403H</h1>
				<p class="modBlockProductsDetail__type">全自動洗濯機【揺れ抑制】</p>
				<p class="modBlockProductsDetail__option">オプション：熱水洗濯｜80℃・10分</p>
			</div>
			<ul class="modBlockProductsDetail__list01">
				<li class="modBlockProductsDetail__list01-item"><a href="#" target="_blank" class="modBlockProductsDetail__list01-link -pdf">取扱説明書</a></li>
				<li class="modBlockProductsDetail__list01-item"><a href="#" target="_blank" class="modBlockProductsDetail__list01-link -pdf">CADデータ</a></li>
				<li class="modBlockProductsDetail__list01-item"><a href="#" target="_blank" class="modBlockProductsDetail__list01-link -pdf">図面</a></li>
			</ul>
			<ul class="modBlockProductsDetail__feature">
				<li class="modBlockProductsDetail__feature-item">洗濯容量<span class="modBlockProductsDetail__feature-txt -large02">&nbsp;40</span><span class="modBlockProductsDetail__feature-txt -large01 -end">kg</span></li>
			</ul>
		</div>

		<div class="modBlockProductsDetail__group">
			<dl class="modBlockProductsDetail__list02">
				<div class="modBlockProductsDetail__list02-item">
					<dt class="modBlockProductsDetail__list02-title">種類別洗濯枚数</dt>
					<dd class="modBlockProductsDetail__list02-txt">バスタオル：148枚</dd>
					<dd class="modBlockProductsDetail__list02-txt">パジャマ：89枚</dd>
					<dd class="modBlockProductsDetail__list02-txt">肌着：286枚</dd>
					<dd class="modBlockProductsDetail__list02-txt">シーツ（シングル）：30～35枚</dd>
				</div>
				<div class="modBlockProductsDetail__list02-item">
					<dt class="modBlockProductsDetail__list02-title">その他の仕様</dt>
					<dd class="modBlockProductsDetail__list02-txt">機械最大寸法：1247(幅)×1472(奥行)×1810(高さ)mm</dd>
					<dd class="modBlockProductsDetail__list02-txt">重量：1340kg</dd>
					<dd class="modBlockProductsDetail__list02-txt">電源：3相　AC200V　40A</dd>
					<dd class="modBlockProductsDetail__list02-txt">高防振動機能を標準装備</dd>
				</div>
			</dl>
		</div>
	</div>
</div><!-- /.modBlockProductsDetail -->
```
*/
.modBlockProductsDetail {
	@include g.tabletPmin {
		display: flex;
		justify-content: space-between;
	}
	&:not(:last-child) {
		margin-bottom: 100px;
		@include g.tabletP {
			margin-bottom: 60px;
		}
	}
	&__image {
		text-align: center;
		@include g.tabletPmin {
			width: percentage(math.div(450px, g.$containerWidth));
		}
		img {
			&:not(:last-child) {
				margin-bottom: 40px;
				@include g.tabletP {
					margin-bottom: 20px;
				}
			}
		}
	}
	&__body {
		@include g.tabletPmin {
			width: percentage(math.div(498px, g.$containerWidth));
		}
	}
	&__group {
		border-bottom: 1px solid #B8B8B8;
		padding-bottom: 22px;
		@include g.tabletP {
			padding-bottom: 18px;
		}
		&:not(:last-child) {
			margin-bottom: 20px;
			@include g.tabletP {
				margin-bottom: 14px;
			}
		}
	}
	&__head {
		&:not(:last-child) {
			margin-bottom: 32px;
			@include g.tabletP {
				margin-bottom: 22px;
			}
		}
	}
	&__title {
		@include g.fz(40);
		font-weight: 500;
		line-height: math.div(30, 28);
		margin-bottom: 13px;
		@include g.tabletP {
			@include g.fz(27);
			margin-bottom: 9px;
		}
	}
	&__type {
		@include g.fz(26);
		font-weight: 500;
		line-height: math.div(30, 28);
		&:not(:last-child) {
			margin-bottom: 25px;
			@include g.tabletP {
				margin-bottom: 17px;
			}
		}
	}
	&__option {
		color: nth(g.$mainColorProducts, 1);
		@include g.fz(18);
		line-height: math.div(30, 28);
	}
	&__list01 {
		display: grid;
		grid-template: auto / repeat(2, calc(50% - 7px));
		gap: 11px 0;
		justify-content: space-between;
		@include g.sphoneP {
			grid-template-columns: repeat(1, minmax(auto, 100%));
			justify-content: center;
		}
		&:not(:last-child) {
			margin-bottom: 27px;
			@include g.tabletP {
				margin-bottom: 18px;
			}
		}
		&-item {
			position: relative;
		}
		&-link {
			background-color: nth(g.$mainColorProducts, 1);
			display: flex;
			align-items: center;
			position: relative;
			color: #fff;
			@include g.fz(14);
			line-height: math.div(30, 28);
			text-decoration: none;
			min-height: 53px;
			height: 100%;
			padding: 14px 30px 14px 22px;
			&::after {
				content: "";
				border-top: 1px solid currentColor;
				border-right: 1px solid currentColor;
				position: absolute;
				top: 0;
				bottom: 0;
				right: 16px;
				width: 8px;
				height: 8px;
				margin: auto;
				transform: rotate(45deg);
			}
			&.-pdf {
				&::before {
					content: "";
					background: url(#{g.$cmnPath}icon_pdf.svg) no-repeat center;
					background-size: cover;
					display: block;
					flex-shrink: 0;
					width: 18px;
					height: 24px;
					margin-right: 10px;
				}
			}
		}
	}
	&__feature {
		display: inline-grid;
		grid-template: auto / auto;
		grid-auto-flow: column;
		gap: 11px 14px;
		&-item {
			background-color: #f19e5c;
			// display: flex;
			// align-items: center;
			color: #fff;
			@include g.fz(16);
			line-height: 1;
			padding: 13px 29px 16px 23px;
		}
		&-txt {
			&.-large01 {
				font-size: math.div(24, 16) * 1em;
			}
			&.-large02 {
				font-size: math.div(50, 16) * 1em;
			}
			&.-end {
				align-self: flex-end
			}
		}
	}
	&__list02 {
		&-item {
			&:not(:last-child) {
				margin-bottom: 11px;
			}
		}
		&-title {
			display: flex;
			@include g.fz(17);
			font-weight: 500;
			line-height: math.div(30, 17);
			margin-bottom: 2px;
			padding-left: 5px;
			&::before {
				content: "\25cf";
				font-size: math.div(12, 17) * 1em;
				margin: 4px 7px 0 0;
			}
		}
		&-txt {
			display: flex;
			@include g.fz(15);
			line-height: math.div(25, 15);
			&::before {
				content: "\30fb";
			}
		}
	}
}//.modBlockProductsDetail

/*
#styleguide
.modBlockProductsSupport01

```
<section class="modBlockProductsSupport01">
	<div class="container">
		<div class="modBlockProductsSupport01__inner">
			<div class="container">
				<h2 class="modBlockProductsSupport01__title">サポート＆サービス</h2>
				<p class="modBlockProductsSupport01__lead">製品のカタログ・取扱説明書ダウンロード、<br class="sp">メンテナンス情報、エラーコードでの検索は<br class="sp">こちら。</p>
				<dl class="modBlockProductsSupport01__list">
					<div class="modBlockProductsSupport01__item">
						<dt class="modBlockProductsSupport01__item-title">データダウンロード</dt>
						<dd class="modBlockProductsSupport01__item-btn"><a href="#" class="modBtn01 -products">詳しくはこちら<span class="modBtn01__icon"></span></a></dd>
					</div>
					<div class="modBlockProductsSupport01__item">
						<dt class="modBlockProductsSupport01__item-title">メンテナンス</dt>
						<dd class="modBlockProductsSupport01__item-btn"><a href="#" class="modBtn01 -products">詳しくはこちら<span class="modBtn01__icon"></span></a></dd>
					</div>
				</dl>
			</div>
		</div>
	</div>
</section><!-- /.modBlockProductsSupport01 -->
```
*/
.modBlockProductsSupport01 {
	background: url(#{g.$productsCmnPath}bg_support.webp) no-repeat center;
	background-size: cover;
	padding: 90px 0;
	@include g.retina {
		background-image: url(#{g.$productsCmnPath}bg_support@2x.webp);
		padding: 60px 0;
	}
	&__inner {
		background-color: #fff;
		border-radius: 7px;
		max-width: 892px;
		margin: 0 auto;
		padding: 49px 0 51px;
		@include g.tabletP {
			border-radius: 6px;
			padding: 33px 0 34px;
		}
	}
	&__title {
		@include g.fz(28);
		line-height: math.div(30, 28);
		text-align: center;
		margin-bottom: 21px;
		@include g.tabletP {
			@include g.fz(18);
			line-height: math.div(25, 18);
			margin-bottom: 17px;
		}
		&::before {
			content: "";
			background: url(#{g.$cmnPath}icon_support.svg) no-repeat center;
			background-size: cover;
			display: block;
			width: 43px;
			height: 41px;
			margin: 0 auto 10px;
			@include g.tabletP {
				width: 29px;
				height: 28px;
				margin-bottom: 9px;
			}
		}
	}
	&__lead {
		@include g.fz(15);
		line-height: math.div(30, 15);
		text-align: center;
		margin-bottom: 27px;
		@include g.tabletP {
			@include g.fz(13);
			margin-bottom: 20px;
		}
	}
	&__list {
		display: grid;
		grid-template: auto / repeat(auto-fit, minmax(auto, g.$btnWidth));
		gap: 10px 17px;
		justify-content: center;
		@include g.tabletP {
			padding: 0 27px;
		}
	}
	&__item {
		&-title {
			@include g.fz(16);
			line-height: math.div(30, 28);
			text-align: center;
			margin-bottom: 20px;
			@include g.tabletP {
				@include g.fz(13);
				line-height: math.div(22.34, 13);
				margin-bottom: 10px;
			}
		}
	}
}//.modBlockProductsSupport01

/*
#styleguide
.modBlockContact01

```
<div class="modBlockContact01">
	<p class="modBlockContact01__txt">お探しの事例が見つからない場合は、ご提案いたしますので、お気軽にお問い合わせください。</p>
	<div class="modBtnBox01"><a href="/contact/" class="modBtn01 -products -mail">お問い合わせフォーム</a></div>
</div><!-- /.modBlockContact01 -->
```
*/
.modBlockContact01 {
	$_: &;
	margin-top: 62px;
	@include g.tabletP {
		margin-top: 42px;
	}
	&__txt {
		@include g.fz(18);
		line-height: 1.5;
		text-align: center;
		margin-bottom: 25px;
		@include g.tabletP {
			@include g.fz(15);
			margin-bottom: 21px;
		}
	}
}//.modBlockContact01

/*
#styleguide
.modBlockContact02

```
<div class="modBlockContact02">
	<h2 class="modBlockContact02__title">OPL（自社設備用ランドリー）に関するお問い合わせ</h2>
	<dl class="modBlockContact02__list">
		<div class="modBlockContact02__item">
			<dt class="modBlockContact02__item-title">メールでのお問い合わせはこちらから</dt>
			<dd class="modBlockContact02__item-btn"><a href="/contact/" class="modBtn01 -mail">お問い合わせフォーム</a></dd>
		</div>
		<div class="modBlockContact02__item">
			<dt class="modBlockContact02__item-title">お電話でのお問い合わせはこちらから</dt>
			<dd class="modBlockContact02__item-btn"><a href="tel:0848485300" class="modBtn01 -tel">0848-48-5300</a></dd>
		</div>
	</dl>
</div><!-- /.modBlockContact02 -->
```
*/
.modBlockContact02 {
	&__title {
		@include g.fz(28);
		line-height: math.div(30, 28);
		text-align: center;
		margin-bottom: 40px;
		@include g.tabletP {
			@include g.fz(18);
			line-height: math.div(25, 18);
			margin-bottom: 11px;
		}
	}
	&__list {
		display: grid;
		grid-template: auto / repeat(auto-fit, minmax(auto, g.$btnWidth));
		gap: 10px 17px;
		justify-content: center;
		@include g.tabletP {
			padding: 0 27px;
		}
	}
	&__item {
		&-title {
			@include g.fz(16);
			line-height: math.div(30, 28);
			text-align: center;
			margin-bottom: 20px;
			@include g.tabletP {
				@include g.fz(13);
				line-height: math.div(22.34, 13);
				margin-bottom: 10px;
			}
		}
	}
}//.modBlockContact02


/*
#styleguide
.modBlockInner

```
<div class="modBlockInner">下にpaddingがつきます</div>
```
*/
.modBlockInner {
	padding-bottom: 100px;
	@include g.tabletP{
		padding-bottom: 50px;
	}
}//.modBlockInner


/*
#styleguide
.modBlockProductsSearch

```
<form>
	<div class="modBlockProductsSearch">
		<dl class="modBlockProductsSearch__box -model">
			<dt class="modBlockProductsSearch__title">型式</dt>
			<dd class="modBlockProductsSearch__detail">
				<input type="text" placeholder="型式を入力してください" class="modBlockProductsSearch__input">
				<p class="modBlockProductsSearch__caution">※半角英数字（例：WN403H）</p>
			</dd>
		</dl>
		<dl class="modBlockProductsSearch__box -pk">
			<dt class="modBlockProductsSearch__title">製造番号</dt>
			<dd class="modBlockProductsSearch__detail">
				<input type="text" placeholder="製造番号を入力してください" class="modBlockProductsSearch__input">
				<p class="modBlockProductsSearch__caution">※半角英字3文字（例：BXG）</p>
			</dd>
		</dl>
		<dl class="modBlockProductsSearch__box -category">
			<dt class="modBlockProductsSearch__title">カテゴリー</dt>
			<dd class="modBlockProductsSearch__detail">
				<select class="modBlockProductsSearch__select" size="4">
					<option>コインランドリー</option>
					<option>├　洗濯乾燥機</option>
					<option>├　乾燥機</option>
					<option>├　洗濯機</option>
					<option>自社設備用ランドリー</option>
				</select>
			</dd>
		</dl>
		<div class="modBlockProductsSearch__btn">
			<button class="modBtn01 -products">検索<span class="modBtn01__icon"></span></button>
		</div>
	</div>
</form>
<form action="result.html">
	<div class="modBlockProductsSearch">
		<dl class="modBlockProductsSearch__box -code">
			<dt class="modBlockProductsSearch__title">エラーコード</dt>
			<dd class="modBlockProductsSearch__detail">
				<input type="text" placeholder="コードを入力してください" class="modBlockProductsSearch__input">
			</dd>
		</dl>
		<dl class="modBlockProductsSearch__box -model">
			<dt class="modBlockProductsSearch__title">型式</dt>
			<dd class="modBlockProductsSearch__detail">
				<input type="text" placeholder="型式を入力してください" class="modBlockProductsSearch__input">
				<p class="modBlockProductsSearch__caution">※半角英数字（例：WN403H）</p>
			</dd>
		</dl>
		<dl class="modBlockProductsSearch__box -pk">
			<dt class="modBlockProductsSearch__title">製造番号</dt>
			<dd class="modBlockProductsSearch__detail">
				<input type="text" placeholder="製造番号を入力してください" class="modBlockProductsSearch__input">
				<p class="modBlockProductsSearch__caution">※半角英字3文字（例：BXG）<br>
					<span class="modBlockProductsSearch__caution-accent">※未入力の場合、最新データを参照</span></p>
			</dd>
		</dl>
		<dl class="modBlockProductsSearch__box -category">
			<dt class="modBlockProductsSearch__title">カテゴリー</dt>
			<dd class="modBlockProductsSearch__detail">
				<select class="modBlockProductsSearch__select" size="4">
					<option selected="">選択してください</option>
					<option>コインランドリー</option>
					<option>├　洗濯乾燥機</option>
					<option>├　乾燥機</option>
					<option>├　洗濯機</option>
					<option>自社設備用ランドリー</option>
				</select>
			</dd>
		</dl>
		<div class="modBlockProductsSearch__btn">
			<div class="modBlockProductsSearch__btn-inner">
				<button class="modBtn01 -products">エラーコードを確認<span class="modBtn01__icon"></span></button>
				<button type="button" class="modBlockProductsSearch__btn-reset">リセットする</button>
			</div>
		</div>
	</div>
</form>
<form action="result.html">
<section class="modBlockProductsSearch">
						<h2 class="modBlockProductsSearch__title02">型式がわかる方はこちらから</h2>
						<div class="modBlockProductsSearch__box -error">
							<dl class="modBlockProductsSearch__child -code">
								<dt class="modBlockProductsSearch__title -small -error">
									エラーコード
									<span class="modBlockProductsSearch__title-error">Er</span>
								</dt>
								<dd class="modBlockProductsSearch__detail -small">
									<input type="text" class="modBlockProductsSearch__input">
								</dd>
								<dd class="modBlockProductsSearch__info">
									<ul class="modBlockProductsSearch__caution">
										<li class="modBlockProductsSearch__caution-item">※数字もしくはアルファベットでご入力ください</li>
										<li class="modBlockProductsSearch__caution-item">※カタカナの入力は不要です
										</li>
										<li class="modBlockProductsSearch__caution-item">※必須</li>
									</ul>
								</dd>
							</dl>
							<dl class="modBlockProductsSearch__child -model">
								<dt class="modBlockProductsSearch__title -small">型式</dt>
								<dd class="modBlockProductsSearch__detail -small">
									<input type="text" class="modBlockProductsSearch__input">
								</dd>
								<dd class="modBlockProductsSearch__info">
									<ul class="modBlockProductsSearch__caution">
										<li class="modBlockProductsSearch__caution-item">※必須</li>
									</ul>
								</dd>
							</dl>
						</div>
						<div class="modBlockProductsSearch__btn -error">
							<div class="modBlockProductsSearch__btn-inner">
								<button class="modBtn01 -products">検索<span class="modBtn01__icon"></span></button>
								<ul class="modBlockProductsSearch__error">
									<li class="modBlockProductsSearch__error-item">※エラーコードは必須項目です</li>
									<li class="modBlockProductsSearch__error-item">※型式は必須項目です</li>
								</ul>
							</div>
						</div>
					</section>
	<section class="modBlockProductsSearch">
		<h2 class="modBlockProductsSearch__title02">型式がわからない方はこちらから</h2>
		<dl class="modBlockProductsSearch__box -categoryError">
			<dt class="modBlockProductsSearch__title -small">カテゴリー</dt>
			<dd class="modBlockProductsSearch__detail">
				<select class="modBlockProductsSearch__select" size="4">
					<option selected>選択してください</option>
					<option>コインランドリー</option>
					<option>├　洗濯乾燥機</option>
					<option>├　乾燥機</option>
					<option>├　洗濯機</option>
					<option>自社設備用ランドリー</option>
				</select>
			</dd>
		</dl>
		<dl class="modBlockProductsSearch__box -products">
			<dt class="modBlockProductsSearch__title -small">機器を選択してください</dt>
			<dd class="modBlockProductsSearch__detail">
				<div class="modBlockProductsSearch__products">
					<ul class="modBlockProductsSearch__products-list">
						<li class="modBlockProductsSearch__products-item">
							<label class="modBlockProductsSearch__products-label">
								<input type="radio" name="device" class="modBlockProductsSearch__products-input">
								<span class="modBlockProductsSearch__products-txt">VR221</span></label>
						</li>
						<li class="modBlockProductsSearch__products-item">
							<label class="modBlockProductsSearch__products-label">
								<input type="radio" name="device" class="modBlockProductsSearch__products-input">
								<span class="modBlockProductsSearch__products-txt">VR223D</span></label>
						</li>
						<li class="modBlockProductsSearch__products-item">
							<label class="modBlockProductsSearch__products-label">
								<input type="radio" name="device" class="modBlockProductsSearch__products-input">
								<span class="modBlockProductsSearch__products-txt">VR223D</span></label>
						</li><% for (var i = 0; i < 4; i++) { %>
						<li class="modBlockProductsSearch__products-item">
							<label class="modBlockProductsSearch__products-label">
								<input type="radio" name="device" class="modBlockProductsSearch__products-input">
								<span class="modBlockProductsSearch__products-txt">VR221</span></label>
						</li><% } %>
					</ul>
				</div>
			</dd>
		</dl>
	</section>
</form>
```
*/
.modBlockProductsSearch{
	background-color:nth(g.$subColorProducts,4);
	padding: 30px 46px;
	margin-bottom: 54px;

	@include g.tabletPmin{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		gap:20px ;
	}

	@include g.tabletP{
		padding: 20px;
	}

	& + .modBlockProductsSearch{
		margin-top: -#{54 - 16}px;
	}

	$_:&;

	&__box{
		@include g.tabletPmin{
			display: flex;
			flex-wrap: wrap;
		}
		@include g.tabletP{
			margin-bottom: 15px;
		}

		&.-model,
		&.-category{
			@include g.tabletPmin{
				width: 57.8%;
			}
		}

		&:not(.category){
			#{$_}__title{
				@include g.tabletPmin{
					padding-top: 9px;
				}
			}
		}

		&.-error{
			display: block;
			@include g.tabletPmin{
				width: calc(100% - 25% - 20px);
			}
		}


		&.-pk{
			@include g.tabletPmin{
				width: calc(100% - 57.8% - 20px);
			}

			#{$_}__title{
				width: 5em;
			}

		}

		&.-categoryError,
		&.-category{
			align-items: center;
		}

		&.-categoryError,
		&.-products{
			@include g.tabletPmin{
				width: calc((100% - 20px)/2);
			}
		}

		&.-products{
			display: block;
			#{$_}__title{
				padding-top: 0;
				width: auto;
			}
		}
	}

	&__child{
		display: flex;
		flex-wrap: wrap;
		align-items: center;

		&:not(:last-child){
			margin-bottom: 12px;
		}

		#{$_}__title{
			@include g.tabletPmin{
			}
		}

		&.-code,
		&.-model{
			#{$_}__title{
				@include g.tabletPmin{
					padding-top: 0;
				}
				&.-small{
					width: 9.7em;
				}
			}
			#{$_}__detail{
				@include g.tabletP{
					width: calc(100% - 11.7em);
				}
			}
			#{$_}__info{
				@include g.tabletP{
					width: 100%;
					margin-top: 5px;
				}
			}

		}

	}

	&__title{
		font-weight: 500;
		@include g.tabletPmin{
			width: 7em;
			padding-right: 15px;
		}
		@include g.tabletP{
			margin-bottom: 5px;
			@include g.fz(15);
		}

		&:not(.-small){
			@include g.fz(18);
			@include g.tabletP{
				@include g.fz(15);
			}
		}

		&.-error{
			position: relative;
			padding-right: 2.3em;
		}

		&-error{
			position: absolute;
			top: 0;
			right: 12px;
			@include g.fz(20);
			line-height: 1.5;
			@include g.tabletPmin{
			}
		}

	}

	&__title02{
		@include g.fz(18);
		font-weight: 500;
		@include g.tabletPmin{
			width: 100%;
		}
		&::before{
			content:"\25CF";
		}
	}

	&__detail{
		@include g.tabletPmin{
			&:not(.-small){
				flex:1;
			}

			&.-small{
				width: 24%;
			}
		}

	}

	&__info{
		@include g.tabletPmin{
			flex:1;
			padding-left: 17px;
		}
	}

	&__caution{
		@include g.fz(14);
		@include g.tabletP{
			@include g.fz(12);
		}

		&-item{
			line-height: 1.5;
		}

		&-accent {
			color: nth(g.$mainColor, 1);
		}
	}

	&__input{
		width: 100%;

		&[type]{
			padding: 9px 24px;
			border-radius: 7px;
		}

	}

	&__select{
		width: 100%;
		background-color: #fff;
		padding: 16px 24px;
		border-radius: 0;
		@include g.tabletP{
			padding-top: 9px;
			padding-bottom: 9px;
		}

	}

	&__btn{
		text-align: center;
		@include g.tabletPmin{
			width: calc(100% - 57.8% - 30px);
			padding: 0 25px;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
		}

		&.-error{
			@include g.tabletPmin{
				width: 25%;
				padding: 0;
			}
		}

		&-inner{
			width: 100%;
		}

		&-reset{
			margin-top: 6px;
			border:none;
			border-bottom: 1px solid nth(g.$baseFontColor,1);
			background-color: transparent;
			text-decoration: none;
			@include g.fz(15);

		}

		.modBtn01{
			margin-right: auto;
			margin-left: auto;
		}

	}

	&__error{
		margin-top: 10px;
		&-item{
			color: map-get(map-get(g.$formColor, error), color);
			@include g.fz(12);
			line-height: 1.4;
			text-align: left;
			@include g.tabletP{
				text-align: center;
			}
		}
	}

	&__products{
		border: solid 1px #c7c7c7;
		background-color: #fff;
		padding: 8px;
		margin-top: 12px;

		$_:&;

		&-list{
			display: flex;
			flex-wrap: wrap;
			gap:6px;
		}

		&-item{

		}

		&-label{
		}

		&-input{
			@include g.accessibilityHidden;
			&:checked {
				& + #{$_}-txt{
					background-color: #fff;
					border-color: nth(g.$mainColorProducts,1);
					color: nth(g.$mainColorProducts,1);
				}
			}
		}

		&-txt{
			cursor: pointer;
			display: block;
			border:solid 1px #ddd;
			background-color: #ddd;
			border-radius: 2.5px;
			width:100%;
			padding: 4px 10px;
			line-height: 1.2;
			@include g.fz(15);
		}


	}


}//.modBlockProductsSearch

/*
#styleguide
.modBlockProductsRelation

```
<dl class="modBlockProductsRelation">
	<dt class="modBlockProductsRelation__title">関連リンク</dt>
	<dd class="modBlockProductsRelation__inner">
		<ul class="modBlockProductsRelation__list -column03">
			<li class="modBlockProductsRelation__list-item"><a href="/download/catalog/" class="modBlockProductsRelation__list-link">
				<span class="modBlockProductsRelation__list-icon"><img src="/assets/img/common/icon_book.svg" alt="" width="56"></span>
				<span class="modBlockProductsRelation__list-txt">カタログ・会社案内<br class="pc">ダウンロード</span>
				<span class="modBlockProductsRelation__list-arrow"></span>
			</a></li>
			<li class="modBlockProductsRelation__list-item"><a href="/maintenance/" class="modBlockProductsRelation__list-link">
			<span class="modBlockProductsRelation__list-inner">
				<span class="modBlockProductsRelation__list-icon"><img src="/assets/img/common/icon_maintenance.svg" alt="" width="49"></span>
				<span class="modBlockProductsRelation__list-txt">機器のメンテナンス</span>
				<span class="modBlockProductsRelation__list-arrow"></span>
				</span>
			</a></li>
			<li class="modBlockProductsRelation__list-item"><a href="/maintenance/support/" class="modBlockProductsRelation__list-link -lock">
				<span class="modBlockProductsRelation__list-inner">
					<span class="modBlockProductsRelation__list-icon"><img src="/assets/img/common/icon_support02.svg" alt="" width="49"></span>
					<span class="modBlockProductsRelation__list-txt">修理・部品交換動画サポート</span>
					<span class="modBlockProductsRelation__list-arrow"></span>
				</span>
			</a></li>
			<li class="modBlockProductsRelation__list-item"><a href="/maintenance/partslist/" class="modBlockProductsRelation__list-link -lock">
				<span class="modBlockProductsRelation__list-inner">
					<span class="modBlockProductsRelation__list-icon"><img src="/assets/img/common/icon_parts.svg" alt="" width="51"></span>
					<span class="modBlockProductsRelation__list-txt">パーツリスト</span>
					<span class="modBlockProductsRelation__list-arrow"></span>
				</span>
			</a></li>
		</ul>
	</dd>
</dl>
<ul class="modBlockProductsRelation__list -column02">
	<li class="modBlockProductsRelation__list-item"><a href="/maintenance/errorcode/" class="modBlockProductsRelation__list-link">
		<span class="modBlockProductsRelation__list-inner">
			<span class="modBlockProductsRelation__list-icon"><img src="/assets/img/common/icon_error.svg" alt="" width="69"></span>
			<span class="modBlockProductsRelation__list-txt">エラーコード検索</span>
			<span class="modBlockProductsRelation__list-arrow"></span>
		</span>
	</a></li>
	<li class="modBlockProductsRelation__list-item"><a href="/maintenance/maintenance/" class="modBlockProductsRelation__list-link">
		<span class="modBlockProductsRelation__list-inner">
			<span class="modBlockProductsRelation__list-icon"><img src="/assets/img/common/icon_maintenance.svg" alt="" width="68"></span>
			<span class="modBlockProductsRelation__list-txt">機器のメンテナンス</span>
			<span class="modBlockProductsRelation__list-arrow"></span>
		</span>
	</a></li>
	<li class="modBlockProductsRelation__list-item"><a href="/maintenance/support/" class="modBlockProductsRelation__list-link -lock">
		<span class="modBlockProductsRelation__list-inner">
			<span class="modBlockProductsRelation__list-icon"><img src="/assets/img/common/icon_support02.svg" alt="" width="78"></span>
			<span class="modBlockProductsRelation__list-txt">修理・部品交換動画サポート</span>
			<span class="modBlockProductsRelation__list-arrow"></span>
		</span>
	</a></li>
	<li class="modBlockProductsRelation__list-item"><a href="/maintenance/partslist/" class="modBlockProductsRelation__list-link -lock">
		<span class="modBlockProductsRelation__list-inner">
			<span class="modBlockProductsRelation__list-icon"><img src="/assets/img/common/icon_parts.svg" alt="" width="75"></span>
			<span class="modBlockProductsRelation__list-txt">パーツリスト</span>
			<span class="modBlockProductsRelation__list-arrow"></span>
		</span>
	</a></li>
</ul>
```
*/
.modBlockProductsRelation{
	&:not(.-borderNo){
		margin-top: 60px;
		padding-top: 60px;
		border-top: 1px solid #b8b8b8;
	}

	&__title{
		margin-bottom: 20px;
		@include g.fz(28);
		line-height: math.div(30, 28);
		text-align: center;
		margin-bottom: 34px;
		color: nth(g.$baseFontColorProducts,1);
		@include g.tabletP {
			@include g.fz(19);
			margin-bottom: 23px;
		}
	}

	&__list{
		$_:'.modBlockProductsRelation__list';
		@include g.tabletPmin{
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			gap:28px;
		}

		&.-column02{
			@include g.tabletPmin{
				gap:35px;
			}
			#{$_}-item{
				@include g.tabletPmin{
					width: calc((100% - 35px*1)/2);
				}
			}

			#{$_}-link{
				@include g.fz(18);
				@include g.tabletPmin{
					padding-top: 40px;
					padding-bottom: 40px;
				}
				@include g.tabletP{
					@include g.fz(15);
				}
			}

			#{$_}-icon{
				@include g.tabletPmin{
					height: 80px;
				}
				img{
					@include g.tabletP{
						width: 23%;
					}
				}
			}
		}

		&-item{
			@include g.tabletPmin{
				width: calc((100% - 28px*2)/3);
			}
			@include g.tabletP{
				&:not(:last-child){
					margin-bottom: 15px;
				}
			}
		}

		&-link{
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			align-items: center;
			position: relative;
			transition:border-color 0.3s ease;
			padding: 20px 50px;
			border-radius: 7px;
			border-width: 1px;
			border-style: solid;
			color: nth(g.$baseFontColorProducts,1);
			text-decoration: none;
			text-align: center;
			line-height: math.div(24,16);
			@include g.tabletPmin{
				min-height: 148px;
			}
			@media(hover: hover) {
				&:hover{
					opacity: 1;
					color: nth(g.$mainColorProducts, 1);
					#{$_}-arrow{
						background-color: nth(g.$mainColorProducts, 1);
						&::before{
							color:#fff;
						}
					}
				}
			}

			&.-lock{

				&::after{
					content:"";
					background-image: url(#{g.$cmnPath}icon_lock.svg);
					background-repeat: no-repeat;
					background-position: 0 0;
					background-size: 100% auto;
					width: 13px;
					height: 18px;
					position: absolute;
					bottom: 12px;
					right: 15px;
				}
			}
		}

		&-inner{
			width: 100%;
		}

		&-icon{
			height: 53px;
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			align-items: center;
			margin-bottom: 10px;
		}

		&-txt{
		}
		&-arrow{
			background-color: #fff;
			border-width: 1px;
			border-style: solid;
			border-radius: 50%;
			position: absolute;
			top: 0;
			bottom: 0;
			right: 20px;
			width: 35px;
			height: 35px;
			margin: auto;
			@include g.tabletP {
				width: 24px;
				height: 24px;
			}
			&::before {
				content: "";
				background-color: currentColor;
				mask: url(#{g.$cmnPath}icon_arrow01.svg) no-repeat center;
				mask-size: 17px;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				@include g.tabletP {
					mask-size: 12px;
				}
			}
		}
	}

}//.modBlockProductsRelation


/*
#styleguide
.modBlockProductsDownload

```
<dl class="modBlockProductsDownload">
	<dt class="modBlockProductsDownload__title">PDFダウンロード</dt>
	<dd class="modBlockProductsDownload__inner">
		<ul class="modBlockProductsDownload__list">
			<li class="modBlockProductsDownload__list-item"><a href="#" class="modBlockProductsDownload__list-link -pdf" target="_blank">取扱説明書</a></li>
			<li class="modBlockProductsDownload__list-item"><a href="#" class="modBlockProductsDownload__list-link -pdf" target="_blank">外観図</a></li>
			<li class="modBlockProductsDownload__list-item"><a href="#" class="modBlockProductsDownload__list-link -pdf" target="_blank">CADデータ</a></li>
		</ul>
	</dd>
</dl>
```
*/
.modBlockProductsDownload{
	background-color:nth(g.$subColorProducts,4);
	padding: 32px 20px 40px;
	margin-top: 40px;
	margin-bottom: 50px;

	@include g.tabletPmin{
	}

	@include g.tabletP{
		padding: 20px;
	}

	&__title{
		margin-bottom: 20px;
		@include g.fz(22);
		text-align: center;
		color: nth(g.$baseFontColorProducts,1);
		@include g.tabletP {
			@include g.fz(16);
		}
	}



	&__list{
		$_:'.modBlockProductsDownload__list';
		max-width: 744px;
		margin: 0 auto;

		@include g.tabletPmin{
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			gap:14px;
		}

		&-item{
			@include g.tabletPmin{
				width: calc((100% - 14px*2)/3);
			}
			@include g.tabletP{
				&:not(:last-child){
					margin-bottom: 15px;
				}
			}
		}

		&-link{
			background-color: nth(g.$mainColorProducts, 1);
			display: flex;
			align-items: center;
			position: relative;
			color: #fff;
			@include g.fz(14);
			line-height: math.div(30, 28);
			text-decoration: none;
			min-height: 53px;
			height: 100%;
			padding: 14px 30px 14px 22px;
			&::after {
				content: "";
				border-top: 1px solid currentColor;
				border-right: 1px solid currentColor;
				position: absolute;
				top: 0;
				bottom: 0;
				right: 16px;
				width: 8px;
				height: 8px;
				margin: auto;
				transform: rotate(45deg);
			}
			&.-pdf {
				&::before {
					content: "";
					background: url(#{g.$cmnPath}icon_pdf.svg) no-repeat center;
					background-size: cover;
					display: block;
					flex-shrink: 0;
					width: 18px;
					height: 24px;
					margin-right: 10px;
				}
			}
		}
	}

}//.modBlockProductsDownload

/*
#styleguide
.modBlockCaseIntro01

```
<div class="modBlockCaseIntro01">
	<p class="modBlockCaseIntro01__txt">「自社施設用ランドリー」は様々な分野での企業、施設、店舗で導入され活躍しています。<br>お客様の業態・施設に最適なランドリー設備をご提案、導入させていただきます。</p>
	<p class="modBlockCaseIntro01__image"><img src="/assets/img/products/cleaning_machine/case/index/img_case01.webp" srcset="/assets/img/products/cleaning_machine/case/index/img_case01@2x.webp 2x" alt=""></p>
</div><!-- /.modBlockCaseIntro01 -->
```
*/
.modBlockCaseIntro01 {
	margin-bottom: 57px;
	@include g.tabletP {
		margin-bottom: 38px;
	}
	&__txt {
		@include g.fz(16);
		line-height: math.div(28, 16);
		margin-bottom: 50px;
		@include g.tabletP {
			@include g.fz(13);
			margin-bottom: 34px;
		}
	}
	&__image {
		text-align: center;
	}
}//.modBlockCaseIntro01


/*
#styleguide
.modBlockDef

```
<dl class="modBlockDef">
	<div class="modBlockDef__inner">
		<dt class="modBlockDef__ttl">会社名</dt>
		<dd class="modBlockDef__txt">株式会社 山本製作所</dd>
	</div>
	<div class="modBlockDef__inner">
		<dt class="modBlockDef__ttl">所在地</dt>
		<dd class="modBlockDef__txt">
			<ul class="modBlockDef__list">
				<li class="modBlockDef__item">
					<span class="modBlockDef__item-ttl">本社工場</span>
					<span class="modBlockDef__item-txt">広島県尾道市長者原1丁目220-19<br>TEL（0848）48-5300　FAX（0848）48-5310</span>
				</li>
				<li class="modBlockDef__item">
					<span class="modBlockDef__item-ttl">山波工場</span>
					<span class="modBlockDef__item-txt">広島県尾道市山波町3037-1</span>
				</li>
			</ul>
		</dd>
	</div>
</dl>
```
*/
.modBlockDef {
	margin-bottom: 55px;
	&__inner {
		border-bottom: 2px solid #EBEBEB;
		display: flex;
		justify-content: start;
		padding: 17.8px 0;
		&:first-child {
			border-top: 2px solid #EBEBEB;
		}
		@include g.tabletP{
			padding: 15px 0;
		}

	}
	&__ttl {
		min-width: 188px;
		color: nth(g.$mainColor, 1);
		@include g.fz(16);
		line-height: math.div(30,16);
		font-weight: 500;
		@include g.tabletP{
			min-width: 100px;
			@include g.fz(15);
		}
	}
	&__txt {
		flex: 1;
		@include g.fz(16);
		line-height: math.div(30,16);
		font-weight: 500;
		@include g.tabletP{
			@include g.fz(15);
		}
	}

	&__list {
		display: flex;
		justify-content: start;
		flex-direction: column;
		gap: 5px;
	}

	&__item {
		display: flex;
		justify-content: start;
		gap: 41px;
		@include g.tabletP{
			gap: 25px;
		}

		&-ttl {
			min-width: 80px;
		}
	}

	&__word {
		&.-small {
			font-size: 0.8em;
		}
	}
}//.modBlockDef

/*
#styleguide
.modBlockBnrBottom01

```
```
*/
.modBlockBnrBottom01 {
	$_: &;
	&__open {
		background-color: #00ABEB;
		border: none;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #fff;
		@include g.fz(14);
		font-weight: 500;
		line-height: math.div(19.44, 14);
		text-decoration: none;
		width: 100%;
		padding: 8px g.$containerPadding;
		@include g.tabletPmin {
			display: none;
		}
		&::after {
			content: "";
			background: url(#{g.$cmnPath}icon_arrow03.svg) no-repeat center;
			background-size: cover;
			width: 16px;
			height: 16px;
			margin-left: 9px;
		}
	}
	&__block {
		@include g.tabletPmin {
			position: fixed;
			bottom: 100px;
			right: 18px;
			transition: opacity .3s, visibility .3s;
			z-index: 4;
			&[aria-hidden = "true"] {
				opacity: 0;
				visibility: hidden;
			}
		}
		@include g.tabletP {
			display: none;
		}
		&-outer {
			position: relative;
			z-index: 1;
			@include g.tabletPmin {
				display: block !important;
			}
			@include g.tabletP {
				max-width: 226px;
				margin: 0 auto;
			}

			.mfp-close {
				background-image: url(#{g.$cmnPath}icon_close02.svg) !important;
				background-size: 9px 9px;
				width: 28px;
				height: 28px;
				top: -14px;
				right: -8px;
			}
		}
		&-inner {
			background-color: #fff;
			border-radius: 9px;
			box-shadow: 4.59px 5.28px 15px rgba(0, 0, 0, 0.2);
			padding-bottom: 9px;
			overflow: hidden;
			@include g.tabletP {
				border-radius: 10px;
				padding-bottom: 12px;
			}
		}
		&-close {
			border: none;
			display: block;
			position: absolute;
			width: 24px;
			height: 24px;
			z-index: 2;

			// applied by /lib/_magnific-popup_custom.scss
			background-color: #93959f;
			background-image: url(#{g.$cmnPath}icon_close.svg);
			background-position: center;
			background-size: 9px 9px;
			background-repeat: no-repeat;
			border-radius: 50%;
			right: -6px;
			top: -12px;
			opacity: 1;
			text-indent: -999999px;
			&:active {
				top: -12px;
			}
			&:hover{
				opacity: 0.7;
			}
			@include g.tabletP {
				display: none;
			}
		}
		&-title {
			background-color: #00ABEB;
			color: #fff;
			@include g.fz(12);
			font-weight: 500;
			line-height: math.div(22.16, 13.68);
			text-align: center;
			margin-bottom: 7px;
			padding: 4px 10px 5px;
			@include g.tabletP {
				@include g.fz(13.68);
				margin-bottom: 9px;
				padding: 4px 10px 5px;
			}
		}
	}
	&__list {
		padding: 0 12px;
	}
	&__item {
		&:not(:last-child) {
			border-bottom: 2px solid #EBEBEB;
			margin-bottom: 9px;
			padding-bottom: 9px;
			@include g.tabletP {
				margin-bottom: 12px;
				padding-bottom: 12px;
			}
		}
	}
	&__link{
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		text-decoration: none;
		text-align: center;

		&.-mynavi{
			#{$_}__logo{
				@include g.tabletPmin {
					width: 162px;
				}
				@include g.tabletP {
					width: 184px;
				}
			}
			#{$_}__btn{
				background-color: #ff870c;
			}
		}

		&.-blank{
			#{$_}__btn{
				&::after{
					content:"";
					background-image: url(#{g.$cmnPath}icon_blank.svg);
					background-position: 0 0;
					background-repeat: no-repeat;
					background-size: 100% auto;
					width: 13px;
					height: 10px;
					display: inline-block;
					margin-left: 6px;
					@include g.tabletP {
						width: 15px;
						height: 11px;
					}
				}
			}
		}
	}

	&__inner{
		display: block;
		width: 100%;
	}

	&__logo{
		display: block;
		width: 154px;
		margin: 0 auto 2px;
		@include g.tabletP {
			width: 174px;
			margin-bottom: 11px;
		}
	}

	&__btn{
		display: block;
		background-color: #f8bb00;
		border-radius: 5px;
		padding: 2px 5px;
		color:#fff;
		@include g.fz(11);
		font-weight: 500;
		line-height: 1.4;
		@include g.tabletP {
			@include g.fz(13.67);
		}
	}
}//.modBlockBnrBottom01

/*
#styleguide
.modBlockItem

```
<section class="modBlockItem">
	<div class="modBlockItem__detail">
		<header class="modTitle03">
			<h2 class="modTitle03__txt">
				<span class="modTitle03__txt-en">03</span>
				<span class="modTitle03__txt-ja">健康管理</span>
			</h2>
		</header><!-- /.modTitle03 -->
		<dl class="myList01">
			<dt class="myList01__title">各種社会保険（労災保険含む）<br>
				健康診断（会社負担）</dt>
			<dd class="myList01__detail">年1回の定期健康診断の実施に加えて、希望者はオプション検査（自費）を受診できます。</dd>
			<dt class="myList01__title">ストレスチェックの実施（会社負担）</dt>
			<dd class="myList01__detail">年1回ストレスチェックを実施し、心の健康づくりに取り組んでいます。</dd>
			<dt class="myList01__title">トレーニングルーム完備</dt>
			<dd class="myList01__detail">会社施設内にある空調完備したトレーニングルームです。 365日24時間いつでも無料で利用できます。</dd>
		</dl>
	</div>
	<ul class="modBlockItem__image">
		<li class="modBlockItem__image-item"><img src="/assets/img/recruit/development/img03_01.webp" srcset="/assets/img/recruit/development/img03_01@2x.webp 2x" alt=""></li>
		<li class="modBlockItem__image-item"><img src="/assets/img/recruit/development/img03_02.webp" srcset="/assets/img/recruit/development/img03_02@2x.webp 2x" alt=""></li>
	</ul>
</section>
```
*/
.modBlockItem{
	@include g.tabletPmin{
		display: flex;
		flex-wrap: wrap;
		gap: 0 80px;
	}

	& + .modBlockItem{
		margin-top: 100px;
		@include g.tabletP{
			margin-top: 50px;
		}
	}

	&__detail{
		@include g.tabletPmin{
			width: 50%;
		}
		@include g.tabletP{
			margin-bottom: 15px;
		}

		&:only-child{
			width: 100%;
			margin-bottom: 0;
		}

	}

	&__image{
		text-align: center;
		@include g.tabletPmin{
			width: calc(50% - 80px);
		}
		@include g.tabletP{
			display: flex;
			flex-wrap: wrap;
			gap:10px;
		}

		&-item{
			@include g.tabletPmin{
				&:not(:last-child){
					margin-bottom: 5px;
				}
			}
			@include g.tabletP{
				width: calc((100% - 10px*1)/2);
				&:only-child{
					width: 100%;
				}
			}
		}
	}
}//.modBlockItem


/*
#styleguide
.modBlockComingsoon

```
<div class="modBlockComingsoon">
	制作中
</div>
```
*/
.modBlockComingsoon{
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	height: 50vh;
	font-size: clamp(1.8rem , 2.5vw ,2.5rem);
	line-height: 1;
	color:#ccc;
	font-weight: 500;
}//.modBlockComingsoon


/*
#styleguide
.modBlockSupprt

```
<div class="modBlockSupprt">
	<p class="modBlockSupprt__lead">部品の交換方法から、保守点検などの<br>山本製作所の製品メンテナンスに関する動画で説明します。</p>

	<section class="modBlockSupprt__item">
		<h2 class="modTitle06 -products">洗濯機WNシリーズ ベアリングケース交換編</h2>
		<div class="modWysiwygBlockMovie">
			<iframe width="560" height="315" src="https://www.youtube.com/embed/k8ahFXOcWBQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
		</div>
	</section>

	<section class="modBlockSupprt__item">
		<h2 class="modTitle06 -products">洗濯機WNシリーズ　ドア編</h2>
		<div class="modWysiwygBlockMovie">
			<iframe width="560" height="315" src="https://www.youtube.com/embed/2bkml04SkYE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
		</div>
	</section>

	<section class="modBlockSupprt__item">
		<h2 class="modTitle06 -products">洗濯機WNシリーズ　【排水弁掃除】</h2>
		<div class="modWysiwygBlockMovie">
			<iframe width="560" height="315" src="https://www.youtube.com/embed/hJPTbg8uGaQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
			</div>
	</section>
</div><!-- /.modBlockSupprt -->

```
*/
.modBlockSupport {
	&__lead {
		text-align: center;
		margin-bottom: 50px;
	}
	&__item {
		&:not(:last-child) {
			margin-bottom: 50px;
		}
	}
}//.modBlockSupport

/*
#styleguide
.modBlockBnrFixed01

```
<div class="modBlockBnrFixed01">
	<button class="modBlockBnrFixed01__close" aria-label="バナーを非表示にする"></button>
	<a href="/products/monodukuri/" class="modBlockBnrFixed01__image"><img src="<%- cmnPath %>bnr_fixed01.webp" srcset="<%- cmnPath %>bnr_fixed01@2x.webp 2x" alt="FOR THE FUTURE 子供たちの未来のために MANUFACTURING CONCEPT ものづくりコンセプト"></a>
</div><!-- /.modBlockBnrFixed01 -->
```
*/
.modBlockBnrFixed01 {
    $_: &;
	position: fixed;
	bottom: 11px;
	right: 87px;
	z-index: 4;
	@include g.narrowPC {
		right: 63px;
	}
	&__inner {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		gap: 2px;
		position: relative;
		transition: transform .3s;
		#{$_}[aria-hidden = "true"] & {
			transform: translateY(calc(100% + 11px));
		}
	}
	&__close {
		appearance: none;
		background:
			linear-gradient(#404040, #404040) no-repeat center / 100% 3px,
			linear-gradient(#404040, #404040) no-repeat center / 3px 100%,
		;
		border: none;
		display: block;
		width: 19px;
		height: 19px;
		transform: rotate(45deg);
		@include g.narrowPC {
			display: none;
		}
	}
}//.modBlockBnrFixed01


.modBlockCardList{

	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-bottom: -90px;
	@include g.tabletP{
		margin-bottom: -35px;
	}

	@include g.sphoneP{
		margin-bottom: -20px;
	}

	&::before,
	&::after {
		content: "";
		display: block;
		order: 1;
	}

	&__item,
	&::before,
	&::after {
		width: calc((100% - 35px*3)/4);
		margin-bottom: 90px;
		@include g.tabletP{
			width: calc((100% - 20px*2)/3);
			margin-bottom: 35px;
		}
		@include g.sphoneP{
			width: calc((100% - 20px*1)/2);
			margin-bottom: 20px;
		}
	}

	&__image{
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: flex-end;
		margin-bottom: 20px;
	}

	&__txt{
		margin-bottom: 20px;
	}

}//.modBlockCardList

.modBlcokBanner {
	margin-top: 120px;
	@include g.tabletP{
		margin-top: 85px;
	}
	&__link {
		img{
			width: 100%;
		}
	}
}//.modBlcokBanner