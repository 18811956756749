@charset "utf-8";

/*
products
*/


@use "global" as g;
@use 'sass:math';

$_id:products;
$_imgPath:'../img/#{$_id}/';

body#page_#{$_id} {
	.myBlock {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		gap: 44px;

		@include g.tabletP{
			gap: 25px;
		}
		&:not(:last-child){
			margin-bottom: 73px;
			@include g.tabletP{
				margin-bottom: 60px;
			}
		}
		&:first-child {
			@include g.tabletP{
				margin-top: 40px;
			}
		}
		&:last-child {
			margin-bottom: 116px;
			@include g.tabletP{
				margin-bottom: 40px;
			}
		}
		&__body {
			display: flex;
			justify-content: space-between;
			gap: 116px;
			@include g.tabletP{
				flex-direction: column;
				gap: 10px;
			}
		}
		&__btnArea {
			width: math.percentage(math.div(377,1000));
			padding-top: 11px;
			@include g.tabletP{
				width: 100%;
			}
		}
		&__txtArea {
			flex: 1;
		}
		&__ttl {
			@include g.fz(30);
			line-height: math.div(40,30);
			letter-spacing: normal;
			margin-bottom: 15px;
			@include g.tabletP{
				@include g.fz(23);
				margin-bottom: 10px;
			}
		}
		&__txt {
			@include g.fz(16);
			line-height: math.div(30,16);
			letter-spacing: normal;
			@include g.tabletP{
				@include g.fz(14);
			}
		}
		&__movie {
			width: 100%;
			aspect-ratio: 16 / 9;

			iframe {
				width: 100%;
				height: 100%;
			}
		}
	}
}
