@charset "utf-8";

/*
products_monodukuri
*/


@use "global" as g;
@use 'sass:math';

$_idParent:products;
$_id:monodukuri;
$_imgPath:'../img/#{$_idParent}/#{$_id}/';

body#page_#{$_idParent}.-#{$_id} {
	$_root: &;

	.gBreadcrumb {
		display: none;
	}

	.gFooter {
		padding: 0;
		&__inner {
			display: none;
		}
	}

	.myWrapper {
		$_: ".myWrapper";
		background-color: nth(g.$subColorProducts, 4);
	}//.myWrapper

	.myTitlePage {
		$_: ".myTitlePage";
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		padding-top: 39px;
		z-index: 2;
		@include g.tabletP {
			padding-top: 19px;
		}
		&__inner {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			line-height: 1;
			max-width: 1920px + g.$containerPadding * 2 - 77px * 2;
			margin: 0 auto;
			padding: 0 g.$containerPadding;
			pointer-events: none;
			@include g.tabletP {
				padding: 0 15px;
			}
			> * {
				pointer-events: auto;
			}
		}
		&__en {
			color: nth(g.$mainColor, 1);
			@include g.fz(30 * .67);
			font-family: g.$fontAlpha;
			font-weight: 800;
			letter-spacing: 0;
			margin-bottom: 21px * .67;
			@include g.tabletP {
				@include g.fz(21);
				letter-spacing: 0;
				margin-bottom: 15px;
			}
		}
		&__jp {
			color: #fff;
			@include g.fz(28 * .67);
			font-weight: 900;
			letter-spacing: 0;
			text-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
			@include g.tabletP {
				@include g.fz(20);
				letter-spacing: 0;
				font-weight: 900;
				text-shadow: 0px -1px 6px rgba(0, 0, 0, 0.16);
			}
		}
	}//.myTitlePage

	.myBlockSlide {
		$_: ".myBlockSlide";
		position: relative;
		z-index: 1;
		&__item {
			display: flex;
			flex-direction: column;
			justify-content: center;
			@include g.tabletPmin {
				height: calc(100vh - var(--top));
				height: calc(100dvh - var(--top));
				overflow: hidden;
			}
		}
		// slick
		&.slick-vertical {
			.slick-slide {
				border: none;
				height: calc(100vh - var(--top));
				height: calc(100dvh - var(--top));
			}
		}
	}//.myBlockSlide

	.myBlock01 {
		$_: ".myBlock01";
		$_2: "#{$_}Section";
		position: relative;
		&,
		&__inner,
		&__body {
			height: inherit;
		}
		&__movie {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 1;
			video {
				object-fit: cover;
				width: 100%;
				height: 100%;
			}
		}
		&__inner {
			position: relative;
			z-index: 2;
			@include g.tabletPmin {
				display: table;
				min-width: 768px - 30px;
				width: 50%;
				@at-root #{$_root} #{$_}.-type02 #{$_}__inner {
					margin-left: auto;
				}
			}
			@include g.tabletP {
				display: table-cell;
				vertical-align: bottom;
				width: 100vw;
				height: inherit;
				padding-top: 100px;
			}
		}
		&__body {
			background-color: rgba(#333,0.5);
			background-image: url(#{$_imgPath}bg_movie.webp);
			background-size: 6px auto;
			color: #fff;
			overflow: auto;
			@include g.retina{
				background-image: url(#{$_imgPath}bg_movie@2x.webp);
			}
			@include g.tabletPmin {
				display: table-cell;
				vertical-align: middle;
				height: calc(100vh - var(--top));
				height: calc(100svh - var(--top));
				text-align: right;
				padding-left: 35px;
				padding-right: unquote('max(3.6vw, #{g.$containerPadding})');
				@at-root #{$_root} #{$_}.-type02 #{$_}__body {
					text-align: left;
					padding-left: unquote('max(3.6vw, #{g.$containerPadding})');
					padding-right: 35px;
				}
			}
			@include g.tabletP {
				padding: 39px 20px 39px;
			}
			&-inner {
				@include g.tabletPmin {
					display: inline-block;
					text-align: left;
				}
			}
		}
		&__title {
			@include g.tabletPmin {
				border-image: linear-gradient(0deg, #fff 1px, transparent 1px) fill 0 / / 0 0 0 100vw;
				margin-bottom: 39px * .67;
				padding-top: 60px * .67;
				padding-bottom: 18px * .67;
				@at-root #{$_root} #{$_}.-type02 #{$_}__title {
					border-image: linear-gradient(0deg, #fff 1px, transparent 1px) fill 0 / / 0 100vw 0 0;
				}
			}
			@include g.tabletP {
				border-image: linear-gradient(0deg, #fff 1px, transparent 1px) fill 0 / / 0 100vw 0 0;
				margin-bottom: 18px;
				padding-bottom: 21px;
			}
			&-en {
				opacity: 0.4;
				display: block;
				@include g.fz(60 * .67);
				font-family: g.$fontAlpha;
				font-weight: bold;
				letter-spacing: 0;
				line-height: 1;
				margin-bottom: 9px * .67;
				@include g.narrowPC {
					@include g.fz(56 * .67);
					letter-spacing: 0;
				}
				@include g.tabletP {
					@include g.fz(35);
					letter-spacing: 0;
					margin-bottom: 4px;
				}
			}
			&-jp {
				@include g.fz(52 * .67);
				font-weight: 600;
				letter-spacing: 0.06em;
				line-height: 1.6;
				@include g.narrowPC {
					@include g.fz(49 * .67);
					letter-spacing: 0.06em;
				}
				@include g.tabletP {
					@include g.fz(25);
					letter-spacing: 0.06em;
				}
			}
		}
		&__txt {
			font-weight: 500;
			@include g.tabletPmin {
				@include g.fz(17);
				letter-spacing: 0;
				line-height: math.div(50, 21);
				padding-bottom: 60px * .67;
			}
			@include g.tabletP {
				@include g.fz(14);
				line-height: math.div(30, 14);
			}
		}
	}//.myBlock01

	.myBlock02 {
		$_: ".myBlock02";
		background-color: nth(g.$subColorProducts, 4);
		position: relative;
		padding: 30px 0 30px;
		z-index: 1;
		@include g.tabletP {
			z-index: 3;
		}
		&__head {
			border-bottom: 1px solid #b8b8b8;
			margin-bottom: 64px * .67;
			padding-bottom: 73px * .67;
			@include g.tabletP {
				margin-bottom: 68px;
				padding-bottom: 44px;
			}
			&-title {
				@include g.fz(40 * .67);
				font-weight: bold;
				letter-spacing: 0.01em;
				line-height: math.div(59, 40);
				text-align: center;
				margin-bottom: 34px * .67;
				@include g.narrowPC {
					@include g.fz(37 * .67);
					letter-spacing: 0.01em;
				}
				@include g.tabletP {
					@include g.fz(27);
					letter-spacing: 0.01em;
					line-height: math.div(40, 27);
					margin-bottom: 30px;
				}
			}
			&-lead {
				@include g.fz(20 * .75);
				letter-spacing: 0;
				line-height: math.div(45, 20);
				text-align: center;
				@include g.narrowPC {
					@include g.fz(18 * .75);
					letter-spacing: 0;
				}
				@include g.tabletP {
					@include g.fz(14);
					letter-spacing: 0;
					line-height: math.div(28, 14);
					text-align: left;
				}
			}
		}
		&Section {
			&__title {
				@include g.fz(40 * .67);
				font-weight: bold;
				letter-spacing: 0.01em;
				line-height: math.div(59, 40);
				text-align: center;
				margin-bottom: 60px * .67;
				@include g.narrowPC {
					@include g.fz(37 * .67);
					letter-spacing: 0.01em;
				}
				@include g.tabletP {
					@include g.fz(27);
					letter-spacing: 0.01em;
					line-height: math.div(40, 27);
					margin-bottom: 41px;
				}
			}
		}//.myBlock02Section
		&Concept {
			$_2: "#{$_}Concept";
			background-color: #fff;
			border-radius: 10px;
			display: flex;
			flex-direction: column;
			align-items: center;
			text-align: center;
			padding: 47px 20px 34px;
			@include g.tabletP {
				padding-top: 37px;
				padding-bottom: 26px;
			}
			&__title {
				position: relative;
				@include g.fz(42 * .67);
				font-weight: bold;
				letter-spacing: 0.01em;
				line-height: math.div(62, 42);
				margin-bottom: 32px * .67;
				@include g.tabletP {
					@include g.fz(23);
					letter-spacing: 0.01em;
					margin-bottom: 23px;
				}
				&-en {
					font-family: g.$fontAlpha;
					font-weight: bold;
					letter-spacing: 0;
					@include g.tabletP {
						letter-spacing: 0;
					}
				}
			}
			&__image {
				margin-bottom: 43px;
				@include g.tabletP {
					margin-bottom: 17px;
				}
			}
			&__txt {
				@include g.fz(16);
				letter-spacing: 0;
				line-height: math.div(28, 14);
				margin-bottom: 20px * .67;
				@include g.tabletP {
					@include g.fz(14);
					letter-spacing: 0;
					text-align: center;
					color: #0b163e;
					margin-bottom: 20px;
				}
			}
			&__close {
				appearance: none;
				background: none;
				border: none;
				@include g.fz(16 * .67);
				text-decoration: underline;
			}
			&Modal {
				.mfp-container {
					padding: 0 107px;
					@include g.tabletP {
						padding: 0 51px;
					}
				}
				.mfp-content {
					max-width: 1000px;
					margin: 0 auto;
					@include g.tabletP {
						max-width: 450px;
					}
				}
				.mfp-arrow {
					opacity: 1;
					width: 42px;
					height: 42px;
					margin-top: -48px;
					transform: none;
					@include g.tabletP {
						width: 20px;
						height: 20px;
						margin-top: -5px;
					}
					&::before {
						content: none;
					}
					&::after {
						border-color: #fff;
						border-style: solid;
						border-width: 0 0 6px 6px;
						display: block;
						position: static;
						width: inherit;
						height: inherit;
						margin: 0;
					}
					&-left {
						left: unquote('calc(50% - min(calc(50% - 228px), #{500px - 107px}) - 188px)');
						@include g.tabletP {
							left: unquote('calc(50% - min(calc(50% - 102px), #{225px - 51px}) - 85px)');
						}
						&::after {
							transform: rotate(45deg);
						}
					}
					&-right {
						right: unquote('calc(50% - min(calc(50% - 228px), #{500px - 107px}) - 188px)');
						@include g.tabletP {
							right: unquote('calc(50% - min(calc(50% - 102px), #{225px - 51px}) - 85px)');
						}
						&::after {
							transform: rotate(-135deg);
						}
					}
				}
			}
		}//.myBlock02Concept
	}//.myBlock02

	.myBlock03 {
		$_: ".myBlock03";
		padding: 30px 0 30px;
		&__txt {
			@include g.fz(22 * .67);
			font-weight: bold;
			letter-spacing: 0;
			line-height: math.div(45, 22);
			text-align: center;
			margin-bottom: 26px;
			@include g.narrowPC {
				@include g.fz(20 * .67);
				letter-spacing: 0;
			}
			@include g.tabletP {
				@include g.fz(18);
				letter-spacing: 0;
				line-height: math.div(33, 18);
				margin-bottom: 20px;
			}
		}
		&__image {
			text-align: center;
			margin-bottom: 40px;
			@include g.tabletP {
				margin-bottom: 30px;
			}
			img {
				display: inline;
			}
		}
		&__link {
			margin-bottom: 76px * .67;
			@include g.tabletP {
				margin-bottom: 31px;
			}
			&-inner {
				background-color: #fff;
				border-radius: 10px;
				box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
				display: flex;
				flex-direction: column;
				align-items: center;
				position: relative;
				text-decoration: none;
				padding: 62px * .67 156px * .67 49px * .67;
				@include g.narrowPC {
					padding: 52px * .67 80px * .67 39px * .67;
				}
				@include g.tabletP {
					align-items: flex-start;
					max-width: 450px;
					margin: 0 auto;
					padding: 34px 70px 25px 20px;
				}
				&::after {
					content: "";
					background: nth(g.$mainColor, 1) url(#{$_imgPath}icon_arrow.svg) no-repeat center;
					border-radius: 50%;
					position: absolute;
					top: 0;
					bottom: 0;
					// right: 61px;
					// width: 75px;
					// height: 75px;
					right: 52px;
					width: 64px;
					height: 64px;
					margin: auto;
					@include g.narrowPC {
						background-image: url(#{$_imgPath}icon_arrow_sp.svg);
						// right: 19px;
						// width: 50px;
						// height: 50px;
						right: 16px;
						width: 43px;
						height: 43px;
					}
				}
			}
			&-txt01 {
				color: nth(g.$mainColor, 1);
				@include g.fz(24 * .67);
				font-weight: bold;
				letter-spacing: 0;
				line-height: 1;
				margin-bottom: 12px * .67;
				@include g.narrowPC {
					@include g.fz(20 * .67);
					letter-spacing: 0;
				}
				@include g.tabletP {
					@include g.fz(20);
					letter-spacing: 0;
					margin-bottom: 8px;
				}
			}
			&-txt02 {
				@include g.fz(40 * .67);
				font-weight: bold;
				letter-spacing: 0;
				line-height: math.div(45, 26);
				@include g.narrowPC {
					@include g.fz(32 * .67);
					letter-spacing: 0;
				}
				@include g.tabletP {
					@include g.fz(26);
					letter-spacing: 0;
				}
			}
		}
		&__reference {
			@include g.fz(16 * .67);
			font-weight: 500;
			letter-spacing: 0.01em;
			line-height: math.div(24, 16);
			@include g.narrowPC {
				@include g.fz(14 * .67);
				font-weight: 400;
				letter-spacing: 0;
				line-height: math.div(28, 14);
			}
			@include g.tabletP {
				margin: 0 -5px;
			}
		}
	}//.myBlock03

	.myListConcept {
		$_: ".myListConcept";
		display: flex;
		flex-wrap: wrap;
		gap: 68px * .67 0;
		justify-content: space-between;
		max-width: 600px;
		margin: 0 auto;
		@include g.tabletP {
			gap: 23px 0;
			max-width: 450px;
			width: 100%;
			margin: 0 auto;
		}
		&__item {
			@include g.tabletPmin {
				width: percentage(math.div(575, 1214));
			}
			@include g.tabletP {
				width: 100%;
			}
			&-inner {
				background-color: #fff;
				border-radius: 10px;
				box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
				display: block;
				text-decoration: none;
				overflow: hidden;
			}
		}
		&__image {
			img {
				width: 100%;
			}
		}
		&__title {
			position: relative;
			@include g.fz(33 * .67);
			font-weight: 500;
			letter-spacing: 0.01em;
			line-height: math.div(49, 33);
			text-align: center;
			padding: 21px * .67 0 17px * .67;
			@include g.tabletP {
				@include g.fz(18);
				letter-spacing: 0.01em;
				line-height: math.div(27, 18);
				padding: 12px 0 11px;
			}
			&-en {
				font-family: g.$fontAlpha;
				font-weight: bold;
				letter-spacing: 0;
				@include g.tabletP {
					letter-spacing: 0;
				}
			}
			&::after {
				content: "";
				background: url(#{g.$cmnPath}icon_zoom.svg) no-repeat center / 100%;
				position: absolute;
				top: 0;
				bottom: 0;
				// right: 27px;
				// width: 29px;
				// height: 29px;
				right: 23px;
				width: 25px;
				height: 25px;
				margin: auto;
				@include g.tabletP {
					right: 15px;
					width: 17px;
					height: 17px;
				}
			}
		}
	}//.myListConcept
}