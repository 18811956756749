@charset "utf-8";

@use "global" as g;
@use 'sass:math';

#header {}
.gHeader {
	$_: &;
    @include g.print{
        display: none;
    }
	position: relative;
	z-index: 4;
	@include g.narrowPC {
		height: g.$headerHeightSp !important;
	}
	&.-products {
		color: g.$baseFontColorProducts;
	}
	&__wrapper {
		position: relative;
		height: 100%;
	}
	&__outer {
		background-color: nth(g.$subColor, 1);
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		@include g.narrowPC {
			background-color: #fff;
		}
		#{$_}.-products & {
			background-color: #fff;
		}
	}
	&__inner {
		display: flex;
		@include g.narrowPCmin {
			align-items: center;
			min-height: g.$headerHeight;
			padding: 10px 0;
		}
		@include g.narrowPC {
			position: relative;
			height: g.$headerHeightSp;
			padding: 10px 0;
		}
	}
	&__head {
		display: flex;
		position: relative;
		@include g.narrowPCmin {
			align-items: center;
		}
		@include g.narrowPC {
			flex-direction: column;
			justify-content: center;
		}
	}
	&__title {
		@include g.narrowPCmin {
			margin: 0 0 4px 4px;
		}
		@include g.narrowPC {
			width: 168px;
			margin-top: -4px;
		}

		&-color01{
		}

		&-color02{
			display: none;
		}
	}
	&__subtitle {
		@include g.fz(12);
		line-height: 1.2;
		@include g.narrowPCmin {
			margin-left: 17px;
		}
		@include g.narrowPC {
			@include g.fz(15);
			margin-bottom: 6px;
		}
	}
	&__nav {
		@include g.narrowPCmin {
			display: block !important;
			margin-left: auto;
		}
		@include g.narrowPC {
			background-color: #fff;
			display: none;
			position: absolute;
			top: 100%;
			left: -#{g.$containerPadding};
			right: -#{g.$containerPadding};
			max-height: calc(100vh - g.$headerHeightSp);
			max-height: calc(100svh - g.$headerHeightSp);
			overflow: auto;
			z-index: 2;
		}
	}
	&__toggle {
		$__: &;
		background: none;
		border: none;
		position: absolute;
		top: 0;
		right: -#{g.$containerPadding};
		width: 66px;
		height: 100%;
		@include g.narrowPCmin {
			display: none;
		}
		&-bar {
			background-color: currentColor;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			width: 22px;
			height: 2px;
			margin: auto;
			transition: .2s;
			&:nth-child(1) {
				transform: translateY(-8px);
				#{$__}[aria-expanded = "true"] & {
					transform: rotate(45deg);
				}
			}
			&:nth-child(2) {
				#{$__}[aria-expanded = "true"] & {
					opacity: 0;
				}
			}
			&:nth-child(3) {
				transform: translateY(8px);
				#{$__}[aria-expanded = "true"] & {
					transform: rotate(-45deg);
				}
			}
		}
	}
	&__toggleSub {
		background-color: #F1F5F8;
		border: none;
		display: block;
		position: relative;
		@include g.fz(15);
		line-height: 1.4;
		text-align: left;
		width: 100%;
		padding: 18px (16px + 16px) 19px 20px;
		z-index: 1;
		@include g.narrowPCmin {
			display: none;
		}
		&::after {
			content: "";
			border-bottom: 1px solid nth(g.$subColorProducts, 2);
			border-right: 1px solid nth(g.$subColorProducts, 2);
			position: absolute;
			top: 2px;
			bottom: 0;
			right: 26px;
			width: 10px;
			height: 10px;
			margin: auto;
			transform: translateY(-#{math.div(10px, 2)}) rotate(45deg);
		}
		&[aria-expanded = "true"] {
			&::after {
				transform: rotate(-135deg);
			}
		}
	}

	&__contact {
		background-color: g.$baseFontColor;
		border: 1px solid g.$baseFontColor;
		position: fixed;
		display: flex;
		flex-direction: row;
		align-items: center;
		color: #fff;
		@include g.fz(14);
		line-height: 1;
		text-decoration: none;
		writing-mode: vertical-lr;
		white-space: nowrap;
		padding: 18px 21px 18px;
		z-index: 2;
		@include g.narrowPCmin {
			top: 365px;
			right: 0;
			margin-bottom: 0 !important;
			@media(hover: hover) {
				&:hover {
					background-color: nth(g.$mainColor, 1);
					border-color: nth(g.$mainColor, 1);
					opacity: 1;
				}
			}
		}
		@include g.narrowPC {
			// border-radius: 50%;
			// bottom: 12px;
			// right: 11px;
			// width: 43px;
			// height: 43px;
			display: none;
		}
		#{$_}.-products & {
			background-color: nth(g.$mainColorProducts, 1);
			border: 1px solid nth(g.$mainColorProducts, 1);
			@include g.tabletPmin {
				@media(hover: hover) {
					&:hover {
						background-color: nth(g.$subColorProducts, 1);
						border-color: nth(g.$subColorProducts, 1);
						opacity: 1;
					}
				}
			}
		}
		&::before {
			content: "";
			background: url(#{g.$cmnPath}icon_mail.svg) no-repeat center;
			background-size: cover;
			display: block;
			width: 18px;
			height: 12px;
			margin-bottom: 5px;
			@include g.narrowPC {
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				margin: auto;
			}
		}
		&.-tel {
			&::before {
				background: url(#{g.$cmnPath}tel.svg) no-repeat center;
				background-size: cover;
			}
		}
		&-txt {
			@include g.narrowPC {
				display: none;
			}
		}
	}

	&__contact02 {
		$_2: &;
		display: flex;
		align-items: center;
		gap: 10px;
		margin-left: auto;
		margin-right: 45px;
		@include g.narrowPCmin {
			display: none;
		}
		&-item {
			display: flex;
			flex-shrink: 0;
			&.-item01 {
				img {
					width: 17px;
					@include g.narrowPC {
						width: 15px;
					}
				}
			}
			&.-item02 {
				img {
					width: 16px;
					@include g.narrowPC {
						width: 14px;
					}
				}
			}
		}
		&-link {
			background-color: currentColor;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 10px;
			line-height: 1;
			@include g.narrowPCmin {
				width: 34px;
				height: 34px;
			}
			@include g.narrowPC {
				width: 30px;
				height: 30px;
			}
			.gHeader.-products & {
				color: nth(g.$mainColorProducts, 1);
			}
		}
	}

	@keyframes topHeaderMoved {
		0% {
			transform: translateY(-100%);
			opacity: 0
		}

		to {
			transform: translateY(0);
			opacity: 1
		}
	}

	body#page_top &{
		$_:'.gHeader';

		position: fixed;
		top: 0;
		left: 0;
		width: 100%;

		#{$_}__outer,
		#{$_}__toggleSub{
			transition:background-color 0.3s ease;
		}

		&:not(.-scroll){
			#{$_}__title{

				&-color01{
					display: none;
				}

				&-color02{
					display: inline;
				}
			}
			#{$_}__toggle{
				color:#fff;
			}
			#{$_}__outer{
				background-color: transparent;
			}

			#{$_}__toggleSub{
				background-color: transparent;
				border-top: 1px solid #fff;
				border-bottom: 1px solid #fff;
				color:#fff;
				&::after{
					border-color:#fff;
				}
			}
		}

		&.-scroll {
			#{$_}__wrapper {
				animation: topHeaderMoved .3s cubic-bezier(.23,1,.32,1) 0s;
			}
		}

	}//#page_top
}
