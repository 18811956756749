@charset "utf-8";

/*
sustainability
*/


@use "global" as g;
@use 'sass:math';

$_id:sustainability;
$_imgPath:'../img/#{$_id}/';

body#page_#{$_id} {

	.myWrapper{
		overflow: hidden;
	}

	.myBlockSection{
		& + .myBlockSection{
			margin-top: 160px;
			@include g.tabletP{
				margin-top: 80px;
			}
		}
	}//.myBlockSection

	.myTxtLead{
		margin-bottom: 80px;
		@include g.tabletP{
			margin-bottom: 40px;
		}
	}//.myTxtLead

	.myBlock01{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		gap:0 70px;
		@include g.tabletP{
			gap: 30px 0;
		}

		$_:'.myBlock01';

		& + .myBlock01{
			margin-top: 140px;
			@include g.tabletP{
				margin-top: 70px;
			}
		}

		&.-left{
			#{$_}__image{
				@include g.tabletPmin{
					order:2;
					margin-right: -450px;
				}
			}
		}

		&.-right{
			#{$_}__detail{
				@include g.tabletPmin{
					order:2;
				}
			}
			#{$_}__image{
				@include g.tabletPmin{
					order:1;
					margin-left: -450px;
				}
			}
		}

		&__detail{
			width: 43%;
			@include g.tabletP{
				width: 100%;
			}

		}

		&__image{
			// width: calc(100% - 70px - 43% - 450px);
			text-align: center;
			@include g.tabletPmin{
				flex:1;
			}
			@include g.tabletP{
				width: 100%;
			}
		}

		&__txt {
			$__: ".myBlock01__txt";
			& + #{$__} {
				margin-top: 1em;
			}
			&.-small {
				font-size: 0.8em;
			}
		}
	}//.myBlock01






}