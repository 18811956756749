@charset "utf-8";

/*
professional_cleaning cell_linen_system
*/


@use "global" as g;
@use 'sass:math';

$_idParentTop:products;
$_idParent:professional_cleaning;
$_id:cell_linen_system;
$_imgPath:'../img/#{$_idParentTop}/#{$_idParent}/#{$_id}/';

body#page_#{$_idParentTop}.-#{$_idParent}.-#{$_id} {
	$_root :&;

	.mySection {
		& + .mySection{
			margin-top: 70px;
		}

		.mySection + .mySection{
			margin-top: 40px;
		}
	}//.mySection

	.myTitle01{
		margin-bottom: 30px;

		&__txt{
		}

		&__title{
			@include g.fz(40);
			font-weight: 500;
			line-height: 1.5;
			@include g.tabletP{
				@include g.fz(18);
			}
		}
	}//.myTitle01

	.myBlock01{
		margin-bottom: 64px;
		display: flex;
		flex-wrap: wrap;
		@include g.tabletPmin{
			gap:80px;
		}
		@include g.tabletP{
			margin-bottom: 40px;
		}

		&__detail{
			@include g.tabletPmin{
				width: calc(100% - 48.6% - 80px);
			}

			@include g.tabletP{
				width: 100%;
				order:1;
				margin-bottom: 20px;
			}

			p + p{
				margin-top: 26px;
			}
		}

		&__image{
			text-align: center;
			@include g.tabletPmin{
				width: 48.6%;
			}
			@include g.tabletP{
				width: 100%;
				order:2;
			}

		}
	}//.myBlock01


	.myListProducts {
		$_: ".myListProducts";
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		gap: 33px 25px;
		position: relative;
		margin: 0 auto;
		@include g.tabletP {
			grid-template-columns: repeat(2, 1fr);
			max-width: 284px*2;
		}
		&__item{
			width: 284px;
			text-align: center;
			@include g.tabletP{
				width: calc(50% - 25px);
			}

			@include g.tabletPmin{
				&.-num04{
					margin-left: calc(284px / 2);
				}
				&.-num05{
					margin-right: calc(284px / 2);
				}
			}
		}
		&__link {
			display: block;
			position: relative;
			color: g.$baseFontLinkColorProducts;
			line-height: math.div(30, 28);
			text-decoration: none;
			height: 100%;
			padding-bottom: 24px;
			@include g.tabletPmin {
				@media(hover: hover) {
					&:hover {
						opacity: 1;
					}
				}
			}
		}
		&__head {
			display: block;
			position: relative;
			text-decoration: none;
		}
		&__image {
			position: relative;
			padding: 15px 0;
			z-index: 1;
			img {
				width: 100%;
			}
		}
		&__title {
			position: relative;
			display: inline-block;
			padding-left: 20px;
			margin-top: 10px;
			z-index: 1;
			text-align: center;
			@include g.fz(18);
			line-height: math.div(30, 28);
			@include g.tabletP {
				@include g.fz(15);
			}
			&::before {
				content: "";
				border-top: 2px solid currentColor;
				border-right: 2px solid currentColor;
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				width: 8px;
				height: 8px;
				transform: translateY(6px) rotate(45deg);
				@include g.tabletP {
					border-top-width: 1px;
					border-right-width: 1px;
				}
			}
		}

		&__sub{
			display: block;
			margin-top: 10px;
			text-align: center;
		}

		&__over {
			background: rgba(nth(g.$mainColorProducts, 1), .65);
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			position: absolute;
			top: 0;
			left: 0;
			color: #fff;
			@include g.fz(16);
			line-height: 1;
			width: 100%;
			height: 100%;
			pointer-events: none;
			transition: opacity .2s;
			z-index: 2;
			opacity: 0;
			&-icon {
				background-color: #fff;
				border: 1px solid #fff;
				border-radius: 50%;
				display: block;
				position: relative;
				width: 52px;
				height: 52px;
				margin-top: 12px;
				@include g.tabletP {
					width: 35px;
					height: 35px;
					margin-top: 10px;
				}
				&::before {
					content: "";
					background-color: nth(g.$mainColorProducts, 1);
					mask: url(#{g.$cmnPath}icon_arrow02.svg) no-repeat center;
					mask-size: 22px;
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					@include g.tabletP {
						mask-size: 18px;
					}
				}
			}
			@include g.tabletPmin {
				@media(hover: hover) {
					@at-root #{$_root} #{$_}__link:hover #{$_}__over {
						opacity: 1;
					}
				}
			}
			@include g.tabletP {
				display: none;
			}
		}
	}

}
