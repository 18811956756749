@charset "utf-8";

/*
#overview
loading
*/

@use "global" as g;
@use 'sass:math';

@keyframes rotateLoading {
	0% {
		transform: rotate(0deg) scale(1);
	}
	50% {
		transform: rotate(180deg) scale(0.6);
	}
	100% {
		transform: rotate(360deg) scale(1);
	}
}

.modLoading {
	background-color:rgba(255,255,255,0.8);
	bottom:0;
	left:0;
	position:fixed;
	right:0;
	top:0;
	z-index: 1000;
	&__inner{
		bottom: 0;
		height: 42px;
		left: 0;
		margin: auto;
		position: absolute;
		right: 0;
		top:0;
		width: 42px;
		&::after {
			@include g.animation(rotateLoading,0.75s,linear,infinite);
			-webkit-animation-fill-mode: both;
			animation-fill-mode: both;
			background: transparent !important;
			background-color: #666;
			border: 2px solid #666;
			border-bottom-color: transparent;
			border-radius: 100%;
			content: '';
			display: inline-block;
			height: 40px;
			margin: 2px;
			width: 40px;
		}
	}


}
