@charset "utf-8";


@use "global" as g;
@use 'sass:math';


.mfp-fade.mfp-bg.mfp-ready {
	opacity: 0.6;
}

.mfp-bg{
	background: #000;
	opacity: 0;
	transition: all 0.15s ease-out;
}//.mfp-bg


.mfp-close{
	background-color: #93959f!important;
	background-image: url(#{g.$cmnPath}icon_close.svg)!important;
	background-position: center!important;
	background-size: 12px 12px!important;
	background-repeat: no-repeat!important;
	border-radius: 50%;
	width: 35px!important;
	height: 35px;
	right: -12px!important;
	top: -12px!important;
	opacity: 1;
	padding: 0!important;
    text-indent: -999999px!important;
	text-align: left!important;

	&:active {
		top: -12px;
	}

	&:hover{
	  opacity: 0.7;
	}
}//.mfp-close