@charset "utf-8";

@use "global" as g;
@use 'sass:math';

/* form
------------------------------------------------------------*/
textarea,
input[type="text"],
input[type="search"],
input[type="password"],
input[type="email"],
input[type="tel"],
input[type="url"],
input[type="number"],
select{
	border:solid 1px map-get(g.$formColor, 'border');
	border-radius:5px;
	@include g.fz(14);
	max-width: 100%;
	padding: 13px 20px 12px;
	@include g.tabletP {
		@include g.fz(16);	// iOS：15px以下だと選択時に拡大するため
		padding: 11px 14px 10px;
	}

	&.-error {
		background-color: map-get( map-get(g.$formColor, 'error'), bg );
		border-color: map-get( map-get(g.$formColor, 'error'), border );
	}

	&::placeholder {
		color: nth(map-get(g.$formColor, bg), 2);
	}
}
