@charset "utf-8";

/*
our_strengths
*/


@use "global" as g;
@use 'sass:math';

$_id:our_strengths;
$_imgPath:'../img/#{$_id}/';

body#page_#{$_id} {

	.myBlockLead{
		margin-bottom: 60px;
		@include g.tabletP{
			margin-bottom: 30px;
		}

		& + .myBlockLead{
			margin-top: 100px;
			@include g.tabletP{
				margin-top: 50px;
			}
		}


		&__movie{
			&-inner{
				width: 100%;
				aspect-ratio: 16 / 9;

				iframe{
					width: 100%;
					height: 100%;
				}
			}
		}
	}//.myBlockLead

	.myBlockAbout{
		margin-bottom: 120px;
		@include g.tabletP{
			margin-bottom: 60px;
		}
	}//.myBlockAbout














}