@charset "utf-8";
/*
#overview
modBtn
*/

@use "global" as g;
@use 'sass:math';

/*
#styleguide
.modBtn01

```
<a href="#" class="modBtn01">ボタン<span class="modBtn01__icon"></span></a>
<a href="#" class="modBtn01">ボタン</a>
<a href="#" class="modBtn01 -mail">ボタン</a>
<a href="#" class="modBtn01 -mail -products">ボタン</a>
<a href="#" class="modBtn01 -tel">000-0000-0000</a>
<a href="#" class="modBtn01 -iconLeft">ボタン<span class="modBtn01__icon"></span></a>
<a href="#" class="modBtn01 -color02">ボタン<span class="modBtn01__icon"></span></a>
<a href="#" class="modBtn01 -color03">ボタン<span class="modBtn01__icon"></span></a>
<a href="#" class="modBtn01 -products">ボタン<span class="modBtn01__icon"></span></a>
<a href="#" class="modBtn01 -products">ボタン</a>
<a href="#" class="modBtn01 -products -mail">ボタン</a>
<a href="#" class="modBtn01 -products -tel">000-0000-0000</a>
```
*/
.modBtn01 {
	$_: &;
	border: 1px solid g.$baseFontColor;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	@include g.fz(16);
	line-height: math.div(30, 28);
	text-align: center;
	text-decoration: none;
	max-width: g.$btnWidth;
	width: 100%;
	min-height: 70px;
	padding: 10px 55px 10px;
	&::before {
		background-color: currentColor;
		mask-repeat: no-repeat;
		mask-position: center;
		mask-size: cover;
		display: block;
		flex-shrink: 0;
	}
	&.-color02 {
		background-color: g.$baseFontColor;
		color: #fff;
	}
	&.-color03 {
		border-color: currentColor;
		color: #fff;
		@media(hover: hover) {
			&:hover{
				background-color: rgba(#fff,0.5);
				color: #fff;
				#{$_}__icon{
					background-color: #fff;
					&::before{
						background-color: currentColor;
					}
				}
			}
		}
		#{$_}__icon{
			color: g.$baseFontColor;
		}
	}
	&.-products {
		background-color: nth(g.$mainColorProducts, 1);
		border-color: nth(g.$mainColorProducts, 1);
		color: #fff;
	}
	&.-products02 {
		color: nth(g.$baseFontColorProducts, 1);
		@media(hover: hover) {
			&:hover{
				color: nth(g.$mainColorProducts, 1);
			}
		}
	}
	@include g.tabletPmin {
		@media(hover: hover) {
			&:hover {
				border-color: currentColor;
				opacity: 1;
				color: nth(g.$mainColor, 1);
				&.-color02 {
					border-color: nth(g.$mainColor, 1);
					background-color: nth(g.$mainColor, 1);
					color: #fff;
				}
				&.-products {
					background-color: nth(g.$subColorProducts, 1);
					border-color: nth(g.$subColorProducts, 1);
					color: #fff;
				}
			}
		}
	}
	@include g.tabletP {
		@include g.fz(14.06);
		min-height: 50px;
		padding-left: 37px;
		padding-right: 37px;
	}
	&__icon {
		background-color: #fff;
		border: 1px solid #fff;
		border-radius: 50%;
		position: absolute;
		top: 0;
		bottom: 0;
		width: 35px;
		height: 35px;
		margin: auto;
		@include g.tabletP {
			width: 24px;
			height: 24px;
		}
		&::before {
			content: "";
			background-color: currentColor;
			mask: url(#{g.$cmnPath}icon_arrow01.svg) no-repeat center;
			mask-size: 17px;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			#{$_}.-color02 & {
				background-color: g.$baseFontColor;
			}
			#{$_}.-products & {
				background-color: nth(g.$mainColorProducts, 1);
			}
			@include g.tabletP {
				mask-size: 12px;
			}
		}
		@include g.tabletPmin {
			@media(hover: hover) {
				#{$_}:hover & {
					background-color: currentColor;
					&::before {
						background-color: #fff;
					}
				}
				#{$_}.-color02:hover & {
					&::before {
						background-color: nth(g.$mainColor, 1);
					}
				}
				#{$_}.-products:hover & {
					background-color: #fff;
					&::before {
						background-color: nth(g.$mainColorProducts, 1);
					}
				}
			}
		}
	}
	&.-mail {
		padding-left: g.$containerPadding;
		padding-right: g.$containerPadding;
		&::before {
			content: "";
			mask-image: url(#{g.$cmnPath}icon_mail.svg);
			width: 29px;
			height: 24px;
			margin-right: 15px;
			#{$_}.-products & {
				background-color: nth(g.$mainColorProducts, 1);
			}
			@include g.tabletP {
				width: 26px;
				height: 21px;
				margin-right: 13px;
			}
		}
	}
	&.-tel {
		@include g.fz(30);
		font-family: g.$fontAlpha;
		font-weight: 300;
		padding-left: g.$containerPadding;
		padding-right: g.$containerPadding;
		@include g.tabletP {
			@include g.fz(26.35);
		}
		&::before {
			content: "";
			mask-image: url(#{g.$cmnPath}icon_tel.svg);
			width: 24px;
			height: 24px;
			margin-right: 6px;
			#{$_}.-products & {
				background-color: nth(g.$mainColorProducts, 1);
			}
			@include g.tabletP {
				width: 21px;
				height: 21px;
				margin-right: 5px;
			}
		}
	}
	&:not(.-iconLeft) {
		#{$_}__icon {
			right: 22px;
			@include g.tabletP {
				right: 15px;
			}
		}
	}
	&.-iconLeft {
		#{$_}__icon {
			left: 22px;
			transform: scaleX(-1);
			@include g.tabletP {
				left: 15px;
			}
		}
	}
}//.modBtn01

/*
#styleguide
.modBtn02

```
<a href="#" class="modBtn02">ボタン<span class="modBtn02__icon"></span></a>
<a href="#" class="modBtn02 -products">ボタン<span class="modBtn02__icon"></span></a>
<a href="#" class="modBtn02 -products -lock">ボタン<span class="modBtn02__icon"></span></a>
<a href="#" class="modBtn02 -products_cleaning_machine">導入事例<span class="modBtn02__icon"></span></a>
<a href="#" class="modBtn02 -support">サポート＆サービス<span class="modBtn02__icon"></span></a>
<a href="/maintenance/errorcode/" class="modBtn02 -maintenance_errorcode -products">エラーコード検索<span class="modBtn02__icon"></span></a>
<a href="/maintenance/partslist/" class="modBtn02 -maintenance_partslist -products -lock">パーツリスト<span class="modBtn02__icon"></span></a>
<a href="/download/manual/" class="modBtn02 -download_manual -products">
							<span class="modBtn02__inner">ダウンロード検索<br><span class="modBtn02__sub">(取扱説明書・外観図・CAD)</span></span>
							<span class="modBtn02__icon"></span></a>
```
*/
.modBtn02 {
	$_: &;
	background-color: #fff;
	border: 1px solid currentColor;
	display: flex;
	align-items: center;
	position: relative;
	@include g.fz(18);
	line-height: math.div(30, 28);
	text-decoration: none;
	max-width: g.$btnWidth;
	width: 100%;
	padding: 20px 60px 20px 42px;
	@include g.tabletPmin {
		min-height: 105px;
	}
	@include g.tabletP {
		@include g.fz(15);
		min-height: 70px;
		padding: 10px 40px 10px 32px;
	}
	&::before {
		display: block;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		flex-shrink: 0;
		margin-right: 19px;
		@include g.tabletP {
		}
	}
	@include g.tabletPmin {
		@media(hover: hover) {
			&:hover {
				border-color: currentColor;
				opacity: 1;
				color: nth(g.$mainColor, 1);
			}
		}
	}

	&__sub{
		font-size: math.percentage(math.div(15,18));
	}

	&__icon {
		background-color: #fff;
		border: 1px solid currentColor;
		border-radius: 50%;
		position: absolute;
		top: 0;
		bottom: 0;
		right: 22px;
		width: 35px;
		height: 35px;
		margin: auto;
		@include g.tabletP {
			right: 15px;
			width: 24px;
			height: 24px;
		}
		&::before {
			content: "";
			background-color: currentColor;
			mask: url(#{g.$cmnPath}icon_arrow01.svg) no-repeat center;
			mask-size: 17px;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			@include g.tabletP {
				mask-size: 12px;
			}
		}
		@include g.tabletPmin {
			@media(hover: hover) {
				#{$_}:hover & {
					background-color: currentColor;
					&::before {
						background-color: #fff;
					}
				}
			}
		}
	}

	&.-products{
		@media(hover: hover) {
			&:hover{
				color: nth(g.$mainColorProducts, 1);
			}
		}
	}



	$_icon:(
		products_cleaning_machine:(
			name:'products_cleaning_machine',
			width:44,
			height:55,
			width_sp:35,
			height_sp:44,
		),
		support:(
			name:'support',
			width:51,
			height:55,
			width_sp:41,
			height_sp:44,
		),
		maintenance_errorcode:(
			name:'error',
			width:42,
			height:47,
			width_sp:38,
			height_sp:44,
		),
		maintenance_partslist:(
			name:'parts',
			width:49,
			height:51,
			width_sp:42,
			height_sp:44,
		),
		download_manual:(
			name:'manual',
			width:50,
			height:52,
			width_sp:42,
			height_sp:44,
		),
	);


	@each $name,$data in $_icon {
		&.-#{$name}{
			&::before {
				content: "";
				background-image: url(#{g.$cmnPath}icon_#{map-get( $data , name )}.svg);
				width: #{map-get( $data , width )}px;
				height: #{map-get( $data , height )}px;
				@include g.tabletP{
					width: #{map-get( $data , width_sp )}px;
					height: #{map-get( $data , height_sp )}px;
				}
			}
		}
	}

	&.-support {
		&::before {
			margin-right: 13px;
			@include g.tabletP {
				margin-right: 12px;
			}
		}
	}

	&.-lock{
		&::after{
			content:"";
			background-image: url(#{g.$cmnPath}icon_lock.svg);
			background-repeat: no-repeat;
			background-position: 0 0;
			background-size: 100% auto;
			width: 13px;
			height: 18px;
			display: inline-block;
			margin-left: 12px;
		}
	}


}//.modBtn02

/*
#styleguide
.modBtn03

```
<a href="#" class="modBtn03">ダウンロード</a>
<a href="#" target="_blank" class="modBtn03 -blank">ダウンロード</a>
<a href="#" target="_blank" class="modBtn03 -pdf">ダウンロード</a>
<a href="#" target="_blank" class="modBtn03 -word">ダウンロード</a>
<a href="#" target="_blank" class="modBtn03 -excel">ダウンロード</a>
```
*/
.modBtn03 {
	$_: &;
	border: 1px solid #B8B8B8;
	border-radius: 5px;
	display: block;
	position: relative;
	color: inherit;
	@include g.fz(14);
	line-height: 1;
	text-decoration: none;
	max-width: 100%;
	width: 225px;
	padding: 18px 22px 19px;
	&::before,
	&::after {
		position: absolute;
		top: 0;
		bottom: 0;
		margin: auto;
	}
	&::before {
		background-color: g.$baseFontColorProducts;
		mask-position: center;
		mask-repeat: no-repeat;
		mask-size: cover;
		left: 21px;
		width: 18px;
		height: 23px;
	}
	&:not(.-blank) {
		&::after {
			content: "";
			border-top: 1px solid currentColor;
			border-right: 1px solid currentColor;
			right: 14px;
			width: 8px;
			height: 8px;
			transform: rotate(45deg);
		}
	}

	&.-inline {
		border: none;
		display: inline-block;
		text-decoration: underline;
		max-width: none;
		width: auto;
		padding-top: 0;
		padding-bottom: 0;
		padding-left: 0;
		&::before {
			left: 0;
		}
		&:not(.-blank) {
			padding-right: 0;
			&::after {
				content: none;
			}
		}
	}

	&.-blank {
		&::after {
			content: "";
			filter: brightness(0) saturate(100%) invert(75%) sepia(9%) saturate(358%) hue-rotate(190deg) brightness(89%) contrast(86%);
			background: url(#{g.$cmnPath}icon_blank.svg) no-repeat center;
			background-size: 16px;
			right: 14px;
			width: 16px;
			height: 20px;
		}
		&.-inline {
			&::after {
				right: 0;
			}
		}
	}
	@each $_var in (pdf, word, excel) {
		&.-#{$_var} {
			padding-left: 47px;
			&::before {
				content: "";
				mask-image: url(#{g.$cmnPath}icon_#{$_var}.svg);
			}
			&.-inline {
				padding-top: 4px;
				padding-bottom: 5px;
				padding-left: 47px - 22px;
			}
		}
	}

	&.-products{
		@media(hover: hover) {
			&:hover{
				border-color: nth(g.$mainColorProducts, 1);
				color: nth(g.$mainColorProducts, 1);
			}
		}
	}
}//.modBtn03

/*
#styleguide
.modBtnBox01

```
<div class="modBtnBox01">
	<a href="#" class="modBtn01 -mail">ボタン</a>
	<a href="tel:0848485300" class="modBtn01 -mail">0848-48-5300</a>
</div>

<div class="modBtnBox01">
	<a href="#" class="modBtn02 -products_cleaning_machine">導入事例</a>
	<a href="#" class="modBtn02 -support">サポート＆サービス</a>
</div>
```
*/
.modBtnBox01 {
	display: grid;
	grid-template: auto / repeat(auto-fit, minmax(auto, 377px));
	justify-content: center;
	gap: 17px 17px;

	*:not([class]) + &{
		margin-top: 30px;
	}

	& + section:not([class]){
		margin-top: 56px;
	}


}//.modBtnBox01
