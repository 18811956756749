@charset "utf-8";

/*
recruit index
*/


@use "global" as g;
@use 'sass:math';

$_idParent:recruit;
$_id:index;
$_imgPath:'../img/#{$_idParent}/#{$_id}/';

body#page_#{$_idParent}.-#{$_id} {

	.myWrapper{
		padding-bottom: 120px;
		@include g.tabletP{
			padding-bottom: 60px;
		}
	}

	.myMainvisual{
		position: relative;

		&__inner{
			max-width: 1200px;
			margin: 0 auto;
			@include g.tabletP{
				padding: 0 15px;
			}
		}

		&__image{
			display: flex;
			flex-wrap: wrap;
			@include g.tabletP{
				height: calc(100vh - 76px);
				height: calc(100dvh - 76px);
			}

			&-item{
				width: 25%;
				text-align: center;
				@include g.tabletP{
					width: 50%;
					height: 50%;
				}
				img{
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}
		}

		&__catch{
			position: absolute;
			bottom: 85px;
			font-weight: 500;
			@include g.fz(40);
			line-height: 1.4;
			@include g.tabletP{
				bottom: 10vh;
				font-size: 7vw;
			}

			&-inner{
			}

			&-item{
				background-color: #fff;
				display: inline-block;
				padding: 4px 6px 4px 15px;
				&:not(:last-child){
					margin-bottom: 10px;
				}
			}
		}

	}//.myMainvisual

	.myBlockMessage{
		@include g.tabletP{
			padding-top: 50px;
		}

		&__inner{
			max-width: 1900px;
			margin: 0 auto;

			@include g.tabletPmin{
				display: flex;
				flex-wrap: wrap;
			}
		}

		&__image{
			@include g.tabletPmin{
				width: 50%;
				order:1;
			}

			img{
				text-align: center;
				@include g.tabletPmin{
					height: 100%;
					object-fit: cover;
				}
			}
		}

		&__detail{
			padding: 170px #{g.$containerPadding};
			text-align: center;
			@include g.tabletPmin{
				width: 50%;
				order:2;
			}
			@include g.tabletP{
				padding-top: 0;
				padding-bottom: 0;
				margin-bottom: 50px;
			}
		}

		&__catch{
			font-weight: 500;

		}

		&__btn{
			margin-top: 40px;
			@include g.tabletP{
				margin-top: 30px;
			}

			.modBtn01{
				margin-right: auto;
				margin-left: auto;
			}

		}
	}//.myBlockMessage

	.myBlockMenu{
		text-align: center;

		&__inner{
			max-width: 1900px;
			margin: 0 auto;
			@include g.tabletPmin{
				display: flex;
				flex-wrap: wrap;
				align-items: center;
			}
		}

		&__box{
			background-repeat: no-repeat;
			background-size: cover;
			background-position: 0 0;
			padding: 180px #{g.$containerPadding} 190px;
			color:#fff;
			@include g.tabletPmin{
				width: 50%;
			}

			@include g.tabletP{
				padding-top: 50px;
				padding-bottom: 50px;
			}

			&.-company{
				background-image: url(#{$_imgPath}img_whats.webp);
				@include g.retina{
					background-image: url(#{$_imgPath}img_whats@2x.webp);
				}
			}

			&.-development{
				background-image: url(#{$_imgPath}img_benefits.webp);
				@include g.retina{
					background-image: url(#{$_imgPath}img_benefits@2x.webp);
				}
			}
		}

		&__body{
			width: 100%;

		}

		&__title{
			margin-bottom: 25px;
			&-title {
				@include g.fz(16);
				font-weight: 500;
				line-height: math.div(45, 16);
				font-weight: 500;
				@include g.tabletP {
					@include g.fz(13);
				}
			}
			&-en {
				@include g.fz(50);
				font-weight: 600;
				line-height: 0.8;
				font-family: g.$fontAlpha;
				@include g.tabletP {
					@include g.fz(40);
				}
			}

		}

		&__txt{
			font-weight: 500;
		}

		&__btn{
			margin-top: 40px;
			@include g.tabletP{
				margin-top: 30px;
			}
			.modBtn01{
				margin-right: auto;
				margin-left: auto;
			}
		}
	}//.myBlockMenu

	.myBlockInterview{

		&__inner{
			max-width: 1900px;
			margin: 0 auto;
			@include g.tabletPmin{
				display: flex;
				flex-wrap: wrap;
			}
		}

		&__image{
			@include g.tabletPmin{
				width: 50%;
			}

			img{
				text-align: center;
				@include g.tabletPmin{
					height: 100%;
					object-fit: cover;
				}
			}
		}

		&__detail{
			padding: 160px #{g.$containerPadding} 180px;
			text-align: center;
			@include g.tabletPmin{
				width: 50%;
			}
			@include g.tabletP{
				padding-top: 50px;
				padding-bottom: 50px;
			}
		}

		&__catch{
			font-weight: 500;

		}

		&__btn{
			margin-top: 40px;
			@include g.tabletP{
				margin-top: 30px;
			}

			.modBtn01{
				margin-right: auto;
				margin-left: auto;
			}

		}
	}//.myBlockInterview

	.myBlockRequirements{
		background-color: #93959F;
		padding: 130px 0;
		@include g.tabletP{
			padding: 50px 0;
		}

		&__inner{
			@include g.tabletPmin{
				display: flex;
				flex-wrap: wrap;
			}
		}

		&__title{
			color:#fff;

			@include g.tabletPmin{
				width: 50%;
			}
			@include g.tabletP{
				margin-bottom: 40px;
			}
			&-title {
				@include g.fz(16);
				font-weight: 500;
				line-height: math.div(45, 16);
				font-weight: 500;
				@include g.tabletP {
					@include g.fz(13);
				}
			}
			&-en {
				@include g.fz(50);
				font-weight: 600;
				line-height: 0.8;
				font-family: g.$fontAlpha;
				@include g.tabletP {
					@include g.fz(40);
				}
			}

		}

		&__detail{

			@include g.tabletPmin{
				width: 50%;
			}
		}

	}//.myBlockRequirements

	.myBlockMovie{
		background-color: #EBEBEB;
		padding: 80px 0 110px;
		@include g.tabletP{
			padding: 40px 0 60px;
		}

		&__movie{
			max-width: 800px;
			margin: 0 auto;

			&-inner{
				width: 100%;
				aspect-ratio: 16 / 9;

				iframe{
					width: 100%;
					height: 100%;
				}
			}
		}

	}//.myBlockMovie

}
