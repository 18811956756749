@charset "utf-8";
/*
#overview
modList
*/

@use "global" as g;
@use 'sass:math';

/*
#styleguide
modListNormal

```
<ul class="modListNormal">
    <li>リスト</li>
    <li>リスト</li>
</ul>
<ol class="modListNormal">
    <li>リスト</li>
    <li>リスト</li>
</ol>
```
*/

.modListNormal { margin: 0 0 0 2em; }

ul.modListNormal {
	li{
		&:before {
			content: "\0030fb";
			display: inline-block;
			margin-left:-2em;
			text-align:center;
			width:2em;
		}
	}
}

ol.modListNormal {
	li{
		list-style:decimal;
	}
}

/*
#styleguide
modListIndent

```
<ul class="modListIndent01">
    <li>※テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。</li>
    <li>※リスト</li>
</ul>
```
*/
.modListIndent01 {
	li{
		margin-left: 1em;
		text-indent: -1em;
	}
}

/*
#styleguide
.modListProducts01

```
<ul class="modListProducts01">
	<li class="modListProducts01__item">
		<a href="/products/cleaning_machine/laundry_machines/wn123h/" class="modListProducts01__link -wn123h">
			<p class="modListProducts01__image">
				<img src="/assets/img/products/cleaning_machine/laundry_machines/img_wn123h.webp" srcset="/assets/img/products/cleaning_machine/laundry_machines/img_wn123h@2x.webp 2x" alt="">
			</p>
			<p class="modListProducts01__feature">洗濯容量：12kg</p>
			<p class="modListProducts01__type">全自動洗濯機</p>
			<p class="modListProducts01__title">WN123H</p>
			<ul class="modListProducts01__iconlist">
				<li class="modListProducts01__icon">NEW</li>
			</ul>
		</a>
	</li>
	<li class="modListProducts01__item">
		<a href="/products/cleaning_machine/laundry_machines/wn203h/" class="modListProducts01__link -wn203h">
			<p class="modListProducts01__image">
				<img src="/assets/img/products/cleaning_machine/laundry_machines/img_wn203h.webp" srcset="/assets/img/products/cleaning_machine/laundry_machines/img_wn203h@2x.webp 2x" alt="">
			</p>
			<p class="modListProducts01__feature">洗濯容量：20kg</p>
			<p class="modListProducts01__type">全自動洗濯機【揺れ抑制】</p>
			<p class="modListProducts01__title">WN203H</p>
			<ul class="modListProducts01__iconlist">
				<li class="modListProducts01__icon">COMING SOON</li>
			</ul>
		</a>
	</li>
	<li class="modListProducts01__item">
		<a href="/products/cleaning_machine/laundry_machines/wn263h/" class="modListProducts01__link -wn263h">
			<p class="modListProducts01__image">
				<img src="/assets/img/products/cleaning_machine/laundry_machines/img_wn263h.webp" srcset="/assets/img/products/cleaning_machine/laundry_machines/img_wn263h@2x.webp 2x" alt="">
			</p>
			<p class="modListProducts01__feature">洗濯容量：26kg</p>
			<p class="modListProducts01__type">全自動洗濯機</p>
			<p class="modListProducts01__title">WN263H</p>
		</a>
	</li>
	<li class="modListProducts01__item">
		<a href="/products/cleaning_machine/laundry_machines/wn353h/" class="modListProducts01__link -wn353h">
			<p class="modListProducts01__image">
				<img src="/assets/img/products/cleaning_machine/laundry_machines/img_wn353h.webp" srcset="/assets/img/products/cleaning_machine/laundry_machines/img_wn353h@2x.webp 2x" alt="">
			</p>
			<p class="modListProducts01__feature">洗濯容量：35kg</p>
			<p class="modListProducts01__type">全自動洗濯機【揺れ抑制】</p>
			<p class="modListProducts01__title">WN353H</p>
		</a>
	</li>
	<li class="modListProducts01__item">
		<a href="/products/cleaning_machine/laundry_machines/wn403h/" class="modListProducts01__link -wn403h">
			<p class="modListProducts01__image">
				<img src="/assets/img/products/cleaning_machine/laundry_machines/img_wn403h.webp" srcset="/assets/img/products/cleaning_machine/laundry_machines/img_wn403h@2x.webp 2x" alt="">
			</p>
			<p class="modListProducts01__feature">洗濯容量：40kg</p>
			<p class="modListProducts01__type">全自動洗濯機【揺れ抑制】</p>
			<p class="modListProducts01__title">WN403H</p>
		</a>
	</li>
	<li class="modListProducts01__item">
		<a href="/products/cleaning_machine/laundry_machines/wn502/" class="modListProducts01__link -wn502">
			<p class="modListProducts01__image">
				<img src="/assets/img/products/cleaning_machine/laundry_machines/img_wn502.webp" srcset="/assets/img/products/cleaning_machine/laundry_machines/img_wn502@2x.webp 2x" alt="">
			</p>
			<p class="modListProducts01__feature">洗濯容量：50kg</p>
			<p class="modListProducts01__type">全自動洗濯機</p>
			<p class="modListProducts01__title">WN502</p>
		</a>
	</li>
	<li class="modListProducts01__item">
		<a href="/products/cleaning_machine/laundry_machines/wn1001z/" class="modListProducts01__link -wn1001z">
			<p class="modListProducts01__image">
				<img src="/assets/img/products/cleaning_machine/laundry_machines/img_wn1001z.webp" srcset="/assets/img/products/cleaning_machine/laundry_machines/img_wn1001z@2x.webp 2x" alt="">
			</p>
			<p class="modListProducts01__feature">洗濯容量：100kg</p>
			<p class="modListProducts01__type">全自動洗濯機</p>
			<p class="modListProducts01__title">WN1001Z</p>
		</a>
	</li>
	<li class="modListProducts01__item">
		<a href="/products/cleaning_machine/laundry_machines/wn000/" class="modListProducts01__link -wn000">
			<p class="modListProducts01__image">
				<img src="/assets/img/products/cleaning_machine/laundry_machines/img_wn000.webp" srcset="/assets/img/products/cleaning_machine/laundry_machines/img_wn000@2x.webp 2x" alt="">
			</p>
			<p class="modListProducts01__feature">洗濯容量：000kg</p>
			<p class="modListProducts01__type">全自動洗濯機</p>
			<p class="modListProducts01__title">WN000</p>
		</a>
	</li>
</ul><!-- /.modListProducts01 -->
```
*/
.modListProducts01 {
	$_: &;
	display: grid;
	grid-template: auto / repeat(auto-fit, 225px);
	gap: 37px 33px;
	justify-content: center;
	position: relative;
	margin: 0 auto;
	@include g.tabletP {
		gap: 25px 22px;
	}
	@include g.sphoneP {
		grid-template-columns: repeat(2, 1fr);
		max-width: 225px*2+22px;
	}
	&:not(:last-child) {
		margin-bottom: 42px;
		@include g.tabletP {
			margin-bottom: 28px;
		}
	}
	&__link {
		display: block;
		position: relative;
		color: g.$baseFontLinkColorProducts;
		line-height: 1.4;
		text-decoration: none;
		height: 100%;
	}
	&__image {
		border-radius: 8px;
		position: relative;
		margin-bottom: 17px;
		padding-top: percentage(math.div(166, 225));
		overflow: hidden;
		img {
			object-fit: contain;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 1;
		}
	}
	&__feature {
		background-color: #6F83B5;
		color: #fff;
		@include g.fz(15);
		text-align: center;
		padding: 3px 10px 9px;
		margin-bottom: 12px;
	}
	&__type {
		@include g.fz(14);
		margin-bottom: 1px;
	}
	&__title {
		@include g.fz(28);
		font-weight: 500;
		line-height: math.div(30, 28);
	}
	&__link.-active,
	body.-wn403h &__link.-wn403h {
		color: nth(g.$mainColorProducts, 1);
		#{$_}__image {
			&::before {
				content: "";
				background-color: rgba(nth(g.$mainColorProducts, 1), .2);
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: 2;
			}
		}
		#{$_}__feature {
			background-color: nth(g.$mainColorProducts, 1);
		}
	}
	&__iconlist {
		display: flex;
		flex-wrap: wrap;
		margin: 9px -7px -5px 0;
		overflow: hidden;
	}
	&__icon {
		background-color: #F19E5D;
		color: #fff;
		@include g.fz(12);
		line-height: 1;
		margin: 0 7px 5px 0;
		padding: 4px 8px 5px;
	}
}//.modListProducts01

/*
#styleguide
.modListProducts02

```
<ul class="modListProducts02">
	<li class="modListProducts02__item">
		<a href="/products/cleaning_machine/hotwater_laundry_machines/" class="modListProducts02__link -cleaning_machine_hotwater_laundry_machines">
			<p class="modListProducts02__image">
				<img src="/assets/img/products/img_cleaning_machine_hotwater_laundry_machines.webp" srcset="/assets/img/products/img_cleaning_machine_hotwater_laundry_machines@2x.webp 2x" alt="">
			</p>
			<p class="modListProducts02__title">熱水洗濯対応洗濯機</p>
		</a>
	</li>
	<li class="modListProducts02__item">
		<a href="/products/cleaning_machine/soilremoval_machines/" class="modListProducts02__link -cleaning_machine_soilremoval_machines">
			<p class="modListProducts02__image">
				<img src="/assets/img/products/img_cleaning_machine_soilremoval_machines.webp" srcset="/assets/img/products/img_cleaning_machine_soilremoval_machines@2x.webp 2x" alt="">
			</p>
			<p class="modListProducts02__title">全自動汚物除去機</p>
		</a>
	</li>
	<li class="modListProducts02__item">
		<a href="/products/cleaning_machine/laundry_machines/" class="modListProducts02__link -cleaning_machine_laundry_machines">
			<p class="modListProducts02__image">
				<img src="/assets/img/products/img_cleaning_machine_laundry_machines.webp" srcset="/assets/img/products/img_cleaning_machine_laundry_machines@2x.webp 2x" alt="">
			</p>
			<p class="modListProducts02__title">全自動洗濯機</p>
		</a>
	</li>
	<li class="modListProducts02__item">
		<a href="/products/cleaning_machine/gas_dryer/" class="modListProducts02__link -cleaning_machine_gas_dryer">
			<p class="modListProducts02__image">
				<img src="/assets/img/products/img_cleaning_machine_gas_dryer.webp" srcset="/assets/img/products/img_cleaning_machine_gas_dryer@2x.webp 2x" alt="">
			</p>
			<p class="modListProducts02__title">洗濯乾燥機</p>
		</a>
	</li>
	<li class="modListProducts02__item">
		<a href="/products/cleaning_machine/dryer/" class="modListProducts02__link -cleaning_machine_dryer">
			<p class="modListProducts02__image">
				<img src="/assets/img/products/img_cleaning_machine_dryer.webp" srcset="/assets/img/products/img_cleaning_machine_dryer@2x.webp 2x" alt="">
			</p>
			<p class="modListProducts02__title">乾燥機</p>
		</a>
	</li>
	<li class="modListProducts02__item">
		<a href="/products/linen_supply/" class="modListProducts02__link -linen_supply">
			<p class="modListProducts02__image">
				<img src="/assets/img/products/img_linen_supply.webp" srcset="/assets/img/products/img_linen_supply@2x.webp 2x" alt="">
			</p>
			<p class="modListProducts02__title">リネンサプライ</p>
		</a>
	</li>
</ul><!-- /.modListProducts02 -->
<ul class="modListProducts02 -max" role="tablist">
	<li class="modListProducts02__item">
		<button type="button" role="tab" aria-controls="maintenance01" class="modListProducts02__link -tab" aria-expanded="false">
			<span class="modListProducts02__image">
				<img src="/assets/img/maintenance/maintenance/img_products01.webp" srcset="/assets/img/maintenance/maintenance/img_products01@2x.webp 2x" alt="">
			</span>
			<span class="modListProducts02__title">洗濯乾燥機</span>
		</button>
	</li>
	<li class="modListProducts02__item">
		<button type="button" role="tab" aria-controls="maintenance02" class="modListProducts02__link -tab" aria-expanded="false">
			<span class="modListProducts02__image">
				<img src="/assets/img/maintenance/maintenance/img_products02.webp" srcset="/assets/img/maintenance/maintenance/img_products02@2x.webp 2x" alt="">
			</span>
			<span class="modListProducts02__title">洗濯機</span>
		</button>
	</li>
	<li class="modListProducts02__item">
		<button type="button" role="tab" aria-controls="maintenance03" class="modListProducts02__link -tab" aria-expanded="false">
			<span class="modListProducts02__image">
				<img src="/assets/img/maintenance/maintenance/img_products03.webp" srcset="/assets/img/maintenance/maintenance/img_products03@2x.webp 2x" alt="">
			</span>
			<span class="modListProducts02__title">乾燥機（蒸気式）</span>
		</button>
	</li>
	<li class="modListProducts02__item">
		<button type="button" role="tab" aria-controls="maintenance04" class="modListProducts02__link -tab" aria-expanded="false">
			<span class="modListProducts02__image">
				<img src="/assets/img/maintenance/maintenance/img_products04.webp" srcset="/assets/img/maintenance/maintenance/img_products04@2x.webp 2x" alt="">
			</span>
			<span class="modListProducts02__title">乾燥機（ガス式）</span>
		</button>
	</li>
	<li class="modListProducts02__item">
		<button type="button" role="tab" aria-controls="maintenance05" class="modListProducts02__link -tab" aria-expanded="false">
			<span class="modListProducts02__image">
				<img src="/assets/img/maintenance/maintenance/img_products05.webp" srcset="/assets/img/maintenance/maintenance/img_products05@2x.webp 2x" alt="">
			</span>
			<span class="modListProducts02__title">乾燥機（電気式）</span>
		</button>
	</li>
</ul>
```
*/
.modListProducts02 {
	$_: &;
	display: grid;
	grid-template: auto / repeat(auto-fit, 179px);
	justify-content: center;
	position: relative;
	margin: 0 auto;
	@include g.max(450) {
		grid-template-columns: repeat(2, 1fr);
		max-width: 179px*2;
	}

	&.-max{
		grid-template: auto / repeat(auto-fit, calc(100% / 5));
		@include g.tabletP{
			grid-template: auto / repeat(auto-fit, calc(100% / 3));
		}
	}

	&:not(:last-child) {
		margin-bottom: 65px;
		@include g.tabletP {
			margin-bottom: 44px;
		}
	}

	section:not([class]) &{
		margin-bottom: 60px;
		@include g.tabletP{
			margin-bottom: 40px;
		}
	}

	&__link {
		display: block;
		border:none;
		position: relative;
		background-color: #fff;
		width: 100%;
		height: 100%;
		padding-bottom: 24px;
		color: g.$baseFontLinkColorProducts;
		line-height: math.div(30, 28);
		text-decoration: none;
		&.-tab{
			#{$_}__title{
				padding-bottom: 22px;
				&::before{
					border-bottom-style: solid;
					border-top-style: none;
					position: absolute;
					bottom: 0;
					left: 0;
					right: 0;
					margin: 0 auto;
				}

			}
		}
	}

	&__image {
		position: relative;
		z-index: 1;
		img {
			width: 100%;
		}
	}

	&__title {
		display: flex;
		justify-content: center;
		position: relative;
		@include g.fz(14.25);
		z-index: 1;
		&::before {
			content: "";
			border-width: 1px;
			border-color: currentColor;
			border-top-style: solid ;
			border-right-style: solid ;
			flex-shrink: 0;
			display: block;
			width: 8px;
			height: 8px;
			margin-right: 10px;
			transform: translateY(#{8px * .5}) rotate(45deg);
		}
	}

	&__link.-active,
	&__link[aria-expanded="true"],
	body.-hotwater_laundry_machines &__link.-hotwater_laundry_machines,
	body.-laundry_machines &__link.-laundry_machines,
	body.-gas_dryer &__link.-gas_dryer,
	body.-electric_dryer &__link.-electric_dryer,
	body.-steam_dryer &__link.-steam_dryer,
	body.-gas_washer_dryer &__link.-gas_washer_dryer,
	body.-soilremoval_machines &__link.-soilremoval_machines,
	body.-folder &__link.-folder,
	body.-rollironer &__link.-rollironer,
	body.-barrier &__link.-barrier {
		color: nth(g.$mainColorProducts, 1);
		&::after {
			content: "";
			background-color: rgba(nth(g.$mainColorProducts, 1), .2);
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 2;
		}
	}
}//.modListProducts02

/*
#styleguide
.modListProducts03

```
<ul class="modListProducts03 -column03">
	<li class="modListProducts03__item"><a href="/download/manual/detail/" class="modListProducts03__link">
		RSF200-BXA
	</a></li>
	<li class="modListProducts03__item"><a href="/download/manual/detail/" class="modListProducts03__link">
		RSF200-BXA
	</a></li>
	<li class="modListProducts03__item"><a href="/download/manual/detail/" class="modListProducts03__link">
		RSF200-BXA
	</a></li>
</ul>
```
*/
.modListProducts03{
	$_:&;
	margin-top: 15px;

	& + *{
		margin-top: 15px;
	}

	@include g.tabletPmin{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		&::after {
			content: "";
			display: block;
		}
	}

	&.-column03{
		@include g.tabletPmin{
			gap:26px 30px;
		}
		&::after,
		#{$_}__item{
			@include g.tabletPmin{
				width: calc((100% - 30px*2)/3);
			}
		}
	}

	&__item{
		@include g.tabletP{
			&:not(:last-child){
				margin-bottom: 15px;
			}
		}

	}

	&__link{
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		position: relative;
		border-radius: 5px;
		border:solid 1px #b8b8b8;
		transition:border-color 0.3s ease;
		padding: 16px 27px;
		text-decoration: none;
		font-weight: 500;
		@include g.fz(20);
		@include g.tabletPmin{
			min-height: 80px;
		}
		@include g.tabletP{
			@include g.fz(16);
		}
		&::before {
			content: "";
			border-width: 1px;
			border-top-style:solid;
			border-right-style:solid;
			position: absolute;
			top: 0;
			bottom: 0;
			right: 20px;
			width: 8px;
			height: 8px;
			margin: auto;
			transform: rotate(45deg);
		}
		@media(hover: hover) {
			&:hover{
				opacity: 1;
				border-color: nth(g.$mainColorProducts, 1);
				color: nth(g.$mainColorProducts, 1);
			}
		}
	}

}//.modListProducts03

/*
#styleguide
.modListProductsPickup01

```
<dl class="modListProductsPickup01">
	<div class="modListProductsPickup01__item">
		<p class="modListProductsPickup01__image"><img src="/assets/img/products/cleaning_machine/laundry_machines/index/img_pickup01.svg" alt=""></p>
		<div class="modListProductsPickup01__body">
			<div class="modListProductsPickup01__head">
				<p class="modListProductsPickup01__catch">オプション装備により、熱水洗濯の<br><span class="modListProductsPickup01__catchStrong01"><span class="modListProductsPickup01__catchStrong02">80℃、10分</span>を可能にします。</span></p>
				<p class="modListProductsPickup01__caution"><span class="modListProductsPickup01__caution-icon">※</span>オプション装着に際し、ボイラー設備への接続が必要になります。</p>
			</div>
			<p class="modListProductsPickup01__txt">安定した高速回転に高い防音・防振性を兼ね備え、従来の全自動洗濯機から安定性・耐久性を大幅にUP。</p>
		</div>
	</div>
	<div class="modListProductsPickup01__item">
		<p class="modListProductsPickup01__image"><img src="/assets/img/products/cleaning_machine/laundry_machines/index/img_pickup01.svg" alt=""></p>
		<div class="modListProductsPickup01__body">
			<div class="modListProductsPickup01__head">
				<p class="modListProductsPickup01__catch">オプション装備により、熱水洗濯の<br><span class="modListProductsPickup01__catchStrong01"><span class="modListProductsPickup01__catchStrong02">80℃、10分</span>を可能にします。</span></p>
				<p class="modListProductsPickup01__caution"><span class="modListProductsPickup01__caution-icon">※</span>オプション装着に際し、ボイラー設備への接続が必要になります。</p>
			</div>
			<p class="modListProductsPickup01__txt">安定した高速回転に高い防音・防振性を兼ね備え、従来の全自動洗濯機から安定性・耐久性を大幅にUP。</p>
		</div>
	</div>
</dl>
```
*/
.modListProductsPickup01 {
	margin-top: 50px;
	@include g.tabletP {
		margin-top: 34px;
	}
	&__item {
		border: 1px solid #B8B8B8;
		@include g.tabletPmin {
			display: flex;
			padding: 48px 40px 35px;
		}
		@include g.tabletP {
			padding: 20px g.$containerPadding 20px;
		}
		&:not(:last-child) {
			margin-bottom: 20px;
			@include g.tabletP {
				margin-bottom: 16px;
			}
		}
	}
	&__image {
		flex-shrink: 0;
		width: 99px;
		margin: -8px 25px 0;
		@include g.tabletP {
			width: 60px;
			margin: 0 auto 20px;
		}
		img {
			width: 100%;
		}
	}
	&__body {
		flex-grow: 1;
	}
	&__head {
		margin-bottom: 16px;
		@include g.tabletP {
			margin-bottom: 13px;
		}
	}
	&__catch {
		@include g.fz(20);
		font-weight: 500;
		line-height: math.div(30, 20);
		@include g.tabletP {
			@include g.fz(16);
		}
		&Strong01 {
			font-size: math.div(35, 20) * 1em;
		}
		&Strong02 {
			color: #F49D39;
		}
	}
	&__caution {
		display: flex;
		@include g.fz(14);
		line-height: math.div(30, 28);
		margin-top: 11px;
		@include g.tabletP {
			@include g.fz(12);
			margin-top: 9px;
		}
		&-icon {
			flex-shrink: 0;
		}
	}
	&__txt {
		@include g.fz(16);
		@include g.tabletP {
			@include g.fz(13);
		}
	}
}//.modListProductsPickup01

/*
#styleguide
.modListProductsFeature01

```
<ol class="modListProductsFeature01">
	<li class="modSection01 -color02 modListProductsFeature01__item">
		<div class="container">
			<div class="modListProductsFeature01__inner">
				<p class="modListProductsFeature01__image"><img src="/assets/img/products/cleaning_machine/laundry_machines/index/img_feature01.webp" srcset="/assets/img/products/cleaning_machine/laundry_machines/index/img_feature01@2x.webp 2x" alt=""></p>
				<div class="modListProductsFeature01__body">
					<p class="modListProductsFeature01__title">ドラムの揺れを極小化</p>
					<p class="modListProductsFeature01__txt">6本の衝撃吸収装置が揺れを抑制します。通常4本の衝撃吸収装置に加えてドラム前後の動きを抑制させる2本の衝撃吸収装置を増設し振動を最小限に食い止めます。</p>
					<ul class="modListProductsFeature01__taglist">
						<li class="modListProductsFeature01__tag">353、403に標準装備</li>
						<li class="modListProductsFeature01__tag">203、263はメーカーオプション</li>
					</ul>
				</div>
			</div>
		</div>
	</li>
	<li class="modSection01 modListProductsFeature01__item">
		<div class="container">
			<div class="modListProductsFeature01__inner">
				<p class="modListProductsFeature01__image"><img src="/assets/img/products/cleaning_machine/laundry_machines/index/img_feature02.webp" srcset="/assets/img/products/cleaning_machine/laundry_machines/index/img_feature02@2x.webp 2x" alt=""></p>
				<div class="modListProductsFeature01__body">
					<p class="modListProductsFeature01__title">コースを選んで運転ボタンを<br>押すだけ</p>
					<p class="modListProductsFeature01__txt">工程の進み具合や残り時間を表示します。</p>
				</div>
			</div>
		</div>
	</li>
</ol>
```
*/
.modListProductsFeature01 {
	$_: &;
	&__item {
		counter-increment: modListProductsFeature01;
	}
	&__inner {
		@include g.tabletPmin {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
		@include g.tabletP {
			@include g.tabletP {
				max-width: 450px;
				margin: 0 auto;
			}
		}
	}
	&__image {
		text-align: center;
		@include g.tabletPmin {
			max-width: 535px;
			width: calc(100% - 396px - 30px);
			#{$_}__item:nth-child(even) & {
				order: 1;
			}
		}
		@include g.tabletP {
			margin-bottom: 25px;
		}
		img {
			@include g.tabletPmin {
				width: 100%;
			}
		}
	}
	&__body {
		@include g.tabletPmin {
			display: flex;
			flex-direction: column;
			justify-content: center;
			flex-shrink: 0;
			width: 396px;
			margin-top: -6px;
		}
		&::before {
			content: counter(modListProductsFeature01, decimal-leading-zero);
			display: block;
			color: nth(g.$mainColorProducts, 1);
			@include g.fz(90);
			font-family: g.$fontAlpha;
			font-weight: 300;
			line-height: 1;
			margin-bottom: 14px;
			@include g.tabletP {
				@include g.fz(60);
				margin-bottom: 12px;
			}
		}
	}
	&__title {
		@include g.fz(30);
		line-height: math.div(40, 30);
		margin-bottom: 16px;
		@include g.tabletP {
			@include g.fz(20);
			margin-bottom: 11px;
		}
	}
	&__txt {
		@include g.fz(16);
		@include g.tabletP {
			@include g.fz(13);
		}
		&:not(:last-child) {
			margin-bottom: 17px;
			@include g.tabletP {
				margin-bottom: 14px;
			}
		}
	}
	&__taglist {
		display: flex;
		align-items: flex-start;
		flex-direction: column;
		&:not(:last-child) {
			margin-bottom: 17px;
			@include g.tabletP {
				margin-bottom: 14px;
			}
		}
	}
	&__tag {
		border: 1px solid currentColor;
		border-radius: 3px;
		color: nth(g.$mainColorProducts, 1);
		@include g.fz(22.4);
		line-height: 1;
		padding: 5px 14px 7px;
		@include g.tabletP {
			@include g.fz(16);
			padding: 3px 10px 4px;
		}
		&:not(:last-child) {
			margin-bottom: 8px;
		}
	}
}//.modListProductsFeature01

/*
#styleguide
.modListProductsFeature02

```
<ul class="modListProductsFeature02">
	<li class="modListProductsFeature02__item">
		<a href="#" class="modListProductsFeature02__link">
			<p class="modListProductsFeature02__image"><img src="/assets/img/products/cleaning_machine/laundry_machines/wn403h/img_feature01.webp" srcset="/assets/img/products/cleaning_machine/laundry_machines/wn403h/img_feature01@2x.webp 2x" alt=""></p>
			<p class="modListProductsFeature02__txt">6本の衝撃吸収装置がドラムの揺れを最小化</p>
		</a>
	</li>
	<li class="modListProductsFeature02__item">
		<a href="#" class="modListProductsFeature02__link">
			<p class="modListProductsFeature02__image"><img src="/assets/img/products/cleaning_machine/laundry_machines/wn403h/img_feature01.webp" srcset="/assets/img/products/cleaning_machine/laundry_machines/wn403h/img_feature01@2x.webp 2x" alt=""></p>
			<p class="modListProductsFeature02__txt">ドアの開閉方向が選べる</p>
		</a>
	</li>
	<li class="modListProductsFeature02__item">
		<a href="#" class="modListProductsFeature02__link">
			<p class="modListProductsFeature02__image"><img src="/assets/img/products/cleaning_machine/laundry_machines/wn403h/img_feature01.webp" srcset="/assets/img/products/cleaning_machine/laundry_machines/wn403h/img_feature01@2x.webp 2x" alt=""></p>
			<p class="modListProductsFeature02__txt">ドラム撥水がステンレスでサビに強く長持ち</p>
		</a>
	</li>
	<li class="modListProductsFeature02__item">
		<a href="#" class="modListProductsFeature02__link">
			<p class="modListProductsFeature02__image"><img src="/assets/img/products/cleaning_machine/laundry_machines/wn403h/img_feature01.webp" srcset="/assets/img/products/cleaning_machine/laundry_machines/wn403h/img_feature01@2x.webp 2x" alt=""></p>
			<p class="modListProductsFeature02__txt">給水弁、給湯弁にストレーナーを標準装備</p>
		</a>
	</li>
	<li class="modListProductsFeature02__item">
		<a href="#" class="modListProductsFeature02__link">
			<p class="modListProductsFeature02__image"><img src="/assets/img/products/cleaning_machine/laundry_machines/wn403h/img_feature01.webp" srcset="/assets/img/products/cleaning_machine/laundry_machines/wn403h/img_feature01@2x.webp 2x" alt=""></p>
			<p class="modListProductsFeature02__txt">コースを選んで運転ボタンを押すだけ</p>
		</a>
	</li>
	<li class="modListProductsFeature02__item">
		<a href="#" class="modListProductsFeature02__link">
			<p class="modListProductsFeature02__image"><img src="/assets/img/products/cleaning_machine/laundry_machines/wn403h/img_feature01.webp" srcset="/assets/img/products/cleaning_machine/laundry_machines/wn403h/img_feature01@2x.webp 2x" alt=""></p>
			<p class="modListProductsFeature02__txt">点検口の開閉は蝶ねじなので工具は不要</p>
		</a>
	</li>
	<li class="modListProductsFeature02__item">
		<a href="#" class="modListProductsFeature02__link">
			<p class="modListProductsFeature02__image"><img src="/assets/img/products/cleaning_machine/laundry_machines/wn403h/img_feature01.webp" srcset="/assets/img/products/cleaning_machine/laundry_machines/wn403h/img_feature01@2x.webp 2x" alt=""></p>
			<p class="modListProductsFeature02__txt">エプロンを標準装備</p>
		</a>
	</li>
</ul><!-- /.modListProductsFeature02 -->
```
*/
.modListProductsFeature02 {
	$_: &;
	display: grid;
	grid-template: auto / repeat(2, calc(50% - 7px));
	gap: 12px 0;
	justify-content: space-between;
	@include g.tabletP {
		grid-template-columns: repeat(1, 100%);
	}
	&:not(:last-child) {
		border-bottom: 1px solid #B8B8B8;
		margin-bottom: 54px;
		padding-bottom: 73px;
		@include g.tabletP {
			margin-bottom: 36px;
			padding-bottom: 49px;
		}
	}
	&__item {
		position: relative;
	}
	&__link {
		background-color: #FAFAFA;
		border: 1px solid #B8B8B8;
		border-radius: 5px;
		display: flex;
		align-items: center;
		color: g.$baseFontLinkColorProducts;
		@include g.fz(18);
		line-height: math.div(30, 18);
		text-decoration: none;
		height: 100%;
		padding: 15px 40px 15px 20px;
		&::before {
			content: "";
			border-top: 1px solid currentColor;
			border-right: 1px solid currentColor;
			position: absolute;
			top: 0;
			bottom: 0;
			right: 20px;
			width: 8px;
			height: 8px;
			margin: auto;
			transform: rotate(45deg);
		}
		@include g.tabletPmin {
			@media(hover: hover) {
				&:hover {
					background-color: #f6f8fd;
					border-color: currentColor;
					opacity: 1;
					color: nth(g.$mainColorProducts, 1);
				}
			}
		}
	}
	&__image {
		flex-shrink: 0;
		max-width: 165px;
		width: percentage(math.div(165+27, 493));
		margin-right: 27px;
		img {
			width: 100%;
		}
	}
}//.modListProductsFeature02

/*
#styleguide
modListPickup01

```
<div class="modListPickup01">
	<div class="container">
		<div class="modTitleProducts01">
			<h2 class="modTitleProducts01__en">PICK UP</h2>
		</div>
		<div class="modListPickup01__inner" data-pickup>
			<a href="#" target="_blank" class="modListPickup01__item -blank">
				<div class="modListPickup01__head">
					<p class="modListPickup01__image"><img src="/assets/img/products/cleaning_machine/index/img_pickup01.webp" srcset="/assets/img/products/cleaning_machine/index/img_pickup01@2x.webp 2x" alt=""></p>
					<div class="modListPickup01__over">詳しくはこちら</div>
				</div>
				<p class="modListPickup01__title">内製率98%</p>
			</a>
			<a href="#" target="_blank" class="modListPickup01__item -pdf">
				<div class="modListPickup01__head">
					<p class="modListPickup01__image"><img src="/assets/img/products/cleaning_machine/index/img_pickup02.webp" srcset="/assets/img/products/cleaning_machine/index/img_pickup02@2x.webp 2x" alt=""></p>
					<div class="modListPickup01__over">詳しくはこちら</div>
				</div>
				<p class="modListPickup01__title">私たちのSDGs宣言</p>
			</a>
			<a href="#" class="modListPickup01__item">
				<div class="modListPickup01__head">
					<p class="modListPickup01__image"><img src="/assets/img/products/cleaning_machine/index/img_pickup03.webp" srcset="/assets/img/products/cleaning_machine/index/img_pickup03@2x.webp 2x" alt=""></p>
					<div class="modListPickup01__over">詳しくはこちら</div>
				</div>
				<p class="modListPickup01__title">ユーザー様からのお手紙</p>
			</a>
		</div>
		<div class="modListPickup01__bar">
			<div class="modListPickup01__bar-inner" data-pickup_bar></div>
		</div>
	</div><!-- /.modListPickup01 -->
```
*/
.modListPickup01 {
	$_: &;
	overflow: hidden;
	@include g.tabletP {
		padding: 0 g.$containerPadding;
	}
	&__inner {
		.slick-list {
			margin-right: -40px;
			overflow: visible;
		}
		.slick-arrow {
			background-color: nth(g.$mainColorProducts, 1);
			background-size: 17px;
			border: 1px solid nth(g.$mainColorProducts, 1);
			border-radius: 50%;
			position: absolute;
			top: 0;
			bottom: 0;
			width: 52px;
			height: 52px;
			margin: auto;
			transition: opacity .2s, visibility .2s;
			z-index: 2;
			@include g.tabletP {
				width: 35px;
				height: 35px;
			}
			&::before {
				content: "";
				background-color: #fff;
				mask: url(#{g.$cmnPath}icon_arrow02.svg) no-repeat center;
				mask-size: 22px;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				@include g.tabletP {
					mask-size: 18px;
				}
			}
			&.slick-disabled {
				opacity: 0;
				pointer-events: none;
				visibility: hidden;
			}
		}
		.slick-prev {
			left: -26px;
			transform: scaleX(-1);
			@include g.max(1080) {
				left: -(g.$containerPadding);
			}
		}
		.slick-next {
			right: -26px;
			@include g.max(1080) {
				right: -(g.$containerPadding);
			}
		}
	}
	&__item {
		display: block;
		color: inherit;
		text-decoration: none;
		max-width: 520px;
		width: 50vw;
		padding-right: 40px;
		@include g.tabletPmin {
			@media(hover: hover) {
				&:hover {
					opacity: 1;
				}
			}
		}
		@include g.tabletP {
			width: calc(50vw - #{g.$containerPadding});
		}
		@include g.sphoneP {
			width: calc(100vw - #{g.$containerPadding * 2});
		}
	}
	&__head {
		box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
		display: block;
		position: relative;
		text-decoration: none;
		padding-top: percentage(math.div(240, 480));
	}
	&__image,
	&__over {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	&__image {
		z-index: 1;
		img {
			position: relative;
			object-fit: cover;
			width: 100%;
			height: 100%;
		}
	}
	&__over {
		background: rgba(nth(g.$mainColorProducts, 1), .65);
		opacity: 0;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		color: #fff;
		@include g.fz(16);
		line-height: 1;
		pointer-events: none;
		transition: opacity .2s;
		z-index: 2;
		&-icon {
			background-color: #fff;
			border: 1px solid #fff;
			border-radius: 50%;
			display: block;
			position: relative;
			width: 52px;
			height: 52px;
			margin-top: 12px;
			@include g.tabletP {
				width: 35px;
				height: 35px;
				margin-top: 10px;
			}
			&::before {
				content: "";
				background-color: nth(g.$mainColorProducts, 1);
				mask: url(#{g.$cmnPath}icon_arrow02.svg) no-repeat center;
				mask-size: 22px;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				@include g.tabletP {
					mask-size: 18px;
				}
			}
		}
		@include g.tabletPmin {
			@media(hover: hover) {
				#{$_}__item:hover & {
					opacity: 1;
				}
			}
		}
	}
	&__title {
		@include g.fz(18);
		font-weight: 500;
		line-height: math.div(30, 28);
		margin-top: 16px;
		@include g.tabletP {
			@include g.fz(15);
			margin-top: 13px;
		}
		#{$_}__item.-blank &,
		#{$_}__item.-pdf & {
			&::after {
				content: "";
				filter: brightness(0) saturate(100%) invert(75%) sepia(9%) saturate(358%) hue-rotate(190deg) brightness(89%) contrast(86%);
				background-position: center;
				background-repeat: no-repeat;
				display: inline-block;
				vertical-align: -.1667em;
				width: 16px;
				height: 20px;
				margin-left: 13px;
				@include g.tabletP {
					width: 13px;
					height: 17px;
					margin-left: 11px;
				}
			}
		}
		#{$_}__item.-blank & {
			&::after {
				background-image: url(#{g.$cmnPath}icon_blank.svg);
				background-size: 16px;
				@include g.tabletP {
					background-size: 13px;
				}
			}
		}
		#{$_}__item.-pdf & {
			&::after {
				background-image: url(#{g.$cmnPath}icon_pdf.svg);
				background-size: 15px;
				@include g.tabletP {
					background-size: 12px;
				}
			}
		}
	}
	&__bar {
		background-color: #D6D6D6;
		max-width: 450px;
		width: calc(100% - 40px * 2);
		margin: 62px auto 0;
		overflow: hidden;
		@include g.tabletP {
			margin-top: 36px;
		}
		@keyframes pickup01_bar {
			from {
				width: 10px;
			}
			to {
				width: calc(100% + 10px);
			}
		}
		&-inner {
			background-color: nth(g.$mainColorProducts, 1);
			width: 0;
			height: 3px;
			&[data-pickup_bar = "active"] {
				animation: pickup01_bar 3s linear 0s 1 normal;
			}
		}
	}
}//.modListPickup01



/*
#styleguide
modListNews

```
<ul class="modListNews">
	<li class="modListNews__item">
		<a href="/news/detail/" class="modListNews__link">
			<div class="modListNews__image">
				<img src="/assets/img/common/img_noimage01.webp" srcset="/assets/img/common/img_noimage01@2x.webp 2x" alt="">
			</div>
			<div class="modListNews__detail">
				<p class="modListNews__date">2023.00.00</p>
				<p class="modListNews__category">お知らせ</p>
				<p class="modListNews__txt">記事タイトルが入ります記事タイトルが入り記事タイトルが入ります記事タイトルが入ります記事タイトルが入ります記事タイトルが入りますます記事タイトルが入ります記事タイトルが入ります記事タイトルが入ります記事タイトルが入ります記事タイトルが入ります記事タイトルが入ります</p>
			</div>
		</a>
	</li>
	<li class="modListNews__item">
		<a href="/news/detail/" class="modListNews__link">
			<div class="modListNews__image">
				<img src="/assets/img/common/img_noimage01.webp" srcset="/assets/img/common/img_noimage01@2x.webp 2x" alt="">
			</div>
			<div class="modListNews__detail">
				<p class="modListNews__date">2023.00.00</p>
				<p class="modListNews__category">新製品</p>
				<p class="modListNews__txt">記事タイトルが入ります記事タイトルが入ります</p>
			</div>
		</a>
	</li>
	<li class="modListNews__item">
		<a href="/news/detail/" class="modListNews__link">
			<div class="modListNews__image">
				<img src="/assets/img/common/img_noimage01.webp" srcset="/assets/img/common/img_noimage01@2x.webp 2x" alt="">
			</div>
			<div class="modListNews__detail">
				<p class="modListNews__date">2023.00.00</p>
				<p class="modListNews__category">新製品</p>
				<p class="modListNews__txt">記事タイトルが入ります記事タイトルが入ります</p>
			</div>
		</a>
	</li>
</ul>
```
*/

.modListNews{
	$_:&;

	&__item{
		position: relative;

		&::after{
			content:"";
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			height: 2px;
			background-color: #EBEBEB;
		}

		&:not(:first-child){
			#{$_}__link{
				padding-top: 25px;
				@include g.tabletP{
					padding-top: 15px;
				}
			}
		}

	}

	&__link{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: flex-start;
		gap: 0 28px;
		padding-bottom: 25px;
		text-decoration: none;
		@include g.tabletP{
			gap:0 10px;
			padding-bottom: 15px;
		}
	}

	&__image{
		width: 14.5%;
		text-align: center;
		@include g.tabletP{
			width:35%;
		}
	}

	&__detail{
		width: calc(100% - 14.5% - 28px);
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		gap:12px 20px;
		@include g.tabletP{
			width: calc(100% - 35% - 10px);
			gap:5px 10px;
		}
	}

	&__date{
		color: nth(g.$mainColor,1);
		font-weight: 500;
		font-family: g.$fontAlpha;
		line-height: 1.5;
	}

	&__category{
		border:solid 1px nth(g.$baseFontColor,1);
		padding: 5px ;
		min-width: 95px;
		@include g.fz(13);
		font-weight: 500;
		line-height: 1.2;
		text-align: center;
		@include g.tabletP{
			padding: 3px ;
			min-width: 60px;
			@include g.fz(10);
			line-height: 1;
		}
	}

	&__txt{
		width: 100%;
		line-height: math.div(30,16);
		@include g.tabletP{
			line-height: 1.5;
		}
	}
}//.modListNews

/*
#styleguide
modListTagCase01

```
<div class="modListTagCase01">
	<p class="modListTagCase01__item">民間企業事業所</p>
	<p class="modListTagCase01__item">病院</p>
</div>

<div class="modListTagCase01 -sizeLarge">
	<p class="modListTagCase01__item">民間企業事業所</p>
	<p class="modListTagCase01__item">病院</p>
</div>
```
*/

.modListTagCase01 {
	$_: &;
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	margin: 0 -7px -5px 0;
	overflow: hidden;
	@include g.tabletPmin {
		&.-sizeLarge {
			margin: 0 -8px -5px 0;
		}
	}
	&__item {
		background-color: #6F83B5;
		border-radius: 13px;
		@include g.fz(14);
		display: block;
		color: #fff;
		line-height: 1;
		text-decoration: none;
		margin: 0 7px 5px 0;
		padding: 4px 22px 7px;
		@include g.tabletPmin {
			#{$_}.-sizeLarge & {
				border-radius: 16px;
				@include g.fz(16);
				margin: 0 8px 5px 0;
				padding: 7px 25px 9px;
			}
		}
	}
}//.modListTagCase01

/*
#styleguide
.modListDocument01

```
<div class="modListDocument01">
	<div class="modListDocument01__group">
		<p class="modListDocument01__item"><a href="#" target="_blank" class="modBtn03 -pdf">ダウンロード</a></p>
	</div>
	<div class="modListDocument01__group">
		<p class="modListDocument01__item"><a href="#" target="_blank" class="modBtn03 -word">ダウンロード</a></p>
		<p class="modListDocument01__item"><a href="#" target="_blank" class="modBtn03 -excel">ダウンロード</a></p>
	</div>
</div><!-- /.modListDocument01 -->
```
*/
.modListDocument01 {
	$_: &;
	$_margin: (
		pc: 34px,
		sp: 23px,
	);
	// margin-top: 68px;
	// @include g.tabletP {
	// 	margin-top: 46px;
	// }
	&__group {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		margin: 0 math.div(map-get($_margin, pc), 2) * -1 map-get($_margin, pc) * -1;
		overflow: hidden;
		@include g.tabletP {
			margin: 0 math.div(map-get($_margin, sp), 2) * -1 map-get($_margin, sp) * -1;
		}
		& + & {
			margin-top: map-get($_margin, pc);
			@include g.tabletP {
				margin-top: map-get($_margin, sp);
			}
		}
	}
	&__item {
		margin: 0 math.div(map-get($_margin, pc), 2) map-get($_margin, pc);
		@include g.tabletP {
			margin: 0 math.div(map-get($_margin, sp), 2) map-get($_margin, sp);
		}
	}
}//.modListDocument01

/*
#styleguide
.modListCase01

```
```
*/
.modListCase01 {
	$_: &;
	display: grid;
	grid-template: auto / repeat(auto-fit, minmax(auto, 314px));
	justify-content: center;
	position: relative;
	gap: 38px 28px;
	&__item {
		position: relative;
		height: 100%;
	}
	&__link {
		display: block;
		position: relative;
		color: inherit;
		text-decoration: none;
		height: 100%;
	}
	&__head {
		margin-bottom: 17px;
	}
	&__image {
		position: relative;
		margin-bottom: 28px;
		padding-top: percentage(math.div(210, 314));
		img {
			object-fit: cover;
			object-position: center;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
	&__title {
		@include g.fz(16);
		line-height: math.div(28, 16);
		&:not(:last-child) {
			margin-bottom: 12px;
		}
	}
	&__category {
		@include g.fz(14);
		line-height: math.div(24.5, 14);
	}
}//.modListCase01


/*
#styleguide
.modListCoin
```
<ul class="modListCoin">
	<li class="modListCoin__item">
		<a href="#" class="modListCoin__link">
			<div class="modListCoin__img">
				<img src="http://placehold.jp/132x100.png" alt="">
			</div>
			<div class="modListCoin__detail">
				<p class="modListCoin__txt">記事タイトルが入ります記事タイトルが入ります</p>
			</div>
		</a>
	</li>
	<li class="modListCoin__item -blank">
		<a href="#" class="modListCoin__link">
			<div class="modListCoin__img">
				<img src="http://placehold.jp/132x100.png" alt="">
			</div>
			<div class="modListCoin__detail">
				<p class="modListCoin__txt">記事タイトルが入ります記事タイトルが入ります</p>
			</div>
		</a>
	</li>
	<li class="modListCoin__item -pdf">
		<a href="#" class="modListCoin__link">
			<div class="modListCoin__img">
				<img src="http://placehold.jp/132x100.png" alt="">
			</div>
			<div class="modListCoin__detail">
				<p class="modListCoin__txt">記事タイトルが入ります記事タイトルが入ります</p>
			</div>
		</a>
	</li>
</ul><!-- /.modListCoin -->
```
*/
.modListCoin {
	$_: '.modListCoin';
	display: flex;
	flex-wrap: wrap;
	justify-content: start;
	gap: 0 14px;
	width: 100%;

	@include g.tabletP{
		flex-direction: column;
	}

	&__item {
		position: relative;
		width: calc((100% - 14px) / 2);

		@include g.tabletP{
			width: 100%;
		}

		&::after {
			content: "";
			background-color: #B8B8B8;
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 1px;
		}

		&.-blank {
			#{$_}__link {
				&::after {
					content: "";
					background-color: #A4A8B7;
					mask: url(#{g.$cmnPath}icon_blank.svg) no-repeat center;
					mask-size: 16px;
					width: 16px;
				}
			}
		}
		&.-pdf {
			#{$_}__link {
				&::after {
					content: "";
					background-color: #A4A8B7;
					mask: url(#{g.$cmnPath}icon_pdf.svg) no-repeat center;
					mask-size: 15px;
					width: 15px;
				}
			}
		}
	}

	&__link {
		position: relative;
		display: flex;
		gap: 29px;
		padding: 15px 16.5px 16px;
		text-decoration: none;

		@include g.tabletP{
			gap: 15px;
			padding: 10px 0 11px;
		}

		&::after {
			content: "";
			background-color: currentColor;
			mask: url(#{g.$cmnPath}icon_arrow01.svg) no-repeat center;
			mask-size: 17px;
			width: 17px;
			position: absolute;
			top: 0;
			bottom: 0;
			right: 16px;
			margin: auto;
			@include g.tabletP {
				mask-size: 12px;
				right: 5px;
			}
		}
	}

	&__img {
		width: 28.5%;
		img {
			width: 100%;
		}
	}
	&__detail {
		align-self: center;
		flex: 1;
	}
	&__txt {
		color: #0b163e;
		@include g.fz(16);
		letter-spacing: normal;
		padding-right: 70px;

		@include g.tabletP{
			@include g.fz(14);
			padding-right: 32px;
		}
	}
}

/*
#styleguide
.modListRecruitLink

インクルードしてください。
```
				<%- include(`${myPath}include/_recruit_link.ejs`, { path:myPath,link:'01' }) %>
				<%- include(`${myPath}include/_recruit_link.ejs`, { path:myPath,link:'02' }) %>
```
*/
.modListRecruitLink{
	$_:&;

	max-width: 770px;
	margin: 100px auto 0;
	@include g.tabletPmin{
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		gap:20px;
	}
	@include g.tabletP{
		margin-top: 50px;
	}


	&__item{
		@include g.tabletPmin{
			width: calc((100% - 20px*1)/2);
		}
		@include g.tabletP{
			&:not(:last-child){
				margin-bottom: 15px;
			}
		}
	}

	&__link{
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		padding: 10px 44px;
		min-height: 120px;
		background-color: #fff;
		border:solid 1px nth(g.$baseFontColor,1);
		text-decoration: none;
		text-align: center;

		&.-mynavi{
			#{$_}__btn{
				background-color: #ff870c;
			}
		}

		&.-blank{
			#{$_}__btn{
				&::after{
					content:"";
					background-image: url(#{g.$cmnPath}icon_blank.svg);
					background-position: 0 0;
					background-repeat: no-repeat;
					background-size: 100% auto;
					width: 15px;
					height: 11px;
					display: inline-block;
					margin-left: 10px;
				}
			}
		}
	}

	&__inner{
		display: block;
		width: 100%;
	}

	&__logo{
		display: block;
		margin-bottom: 10px;

	}

	&__btn{
		display: block;
		background-color: #f8bb00;
		border-radius: 5px;
		padding: 5px;
		color:#fff;
		@include g.fz(15);
		font-weight: 500;
		line-height: 1.4;
		@include g.tabletP{
			@include g.fz(13);
		}
	}


}//.modListRecruitLink