@charset "utf-8";

/*
coin_laundry topics
*/


@use "global" as g;
@use 'sass:math';

$_idParentTop:products;
$_idParent:coin_laundry;
$_id:topics;

body#page_#{$_idParentTop}.-#{$_idParent}.-#{$_id} {

	$_root: &;

// --- 共通
	.myTxtCaseLead {
		text-align: center;
		margin-bottom: 25px;
		@include g.tabletP {
			text-align: left;
			margin-bottom: 21px;
		}
	}//.myTxtCaseLead

	.myBlockService {
		$_: ".myBlockService";
		@at-root .mySmartlaundryBlockMv + #{$_} {
			margin-top: 29px;
			@include g.tabletP {
				margin-top: 22px;
			}
		}
		@at-root .modBtnBox01 + #{$_} {
			margin: 51px -#{g.$containerPadding} 0;
			@include g.tabletP {
				margin-top: 49px;
			}
		}
		&__inner {
			display: grid;
			grid-template-columns: / repeat(2, 1fr);
			gap: 0 24px;
			justify-content: space-between;
			@include g.tabletP {
				grid-template-columns: repeat(1, 1fr);
			}
		}
		&__title {
			border-left: 2px solid currentColor;
			@include g.fz(18);
			font-weight: 500;
			letter-spacing: 0.02em;
			line-height: 1.2;
			margin-bottom: 10px;
			padding: 0 0 3px 10px;
			@include g.tabletPmin {
				grid-column: 1 / 3;
			}
		}
		&__item {
			@include g.tabletP {
				&:not(:last-child) {
					margin-bottom: 10px;
				}
			}
			img {
				width: 100%;
			}
		}
	}//.myBlockService

// --- cat
	$_id: cat;
	$_imgPath:'../img/#{$_idParentTop}/#{$_idParent}/#{$_id}/';

	.myCatBlockDetailIntro {
		$_: ".myCatBlockDetailIntro";
		margin-bottom: 95px;
		@include g.tabletP {
			margin-bottom: 64px;
		}
	}//.myCatBlockDetailIntro

	.myCatBlockDetailIntroTitle {
		$_: ".myCatBlockDetailIntroTitle";
		display: flex;
		margin-bottom: 47px;
		@include g.tabletP {
			margin-bottom: 32px;
		}
		&__image {
			margin-right: 33px;
			@include g.tabletP {
				margin-right: 22px;
			}
		}
		&__txt01,
		&__txt02 {
			display: block;
		}
		&__txt01 {
			@include g.fz(18);
			line-height: 1.4;
			margin-bottom: 7px;
			@include g.tabletP {
				@include g.fz(15);
				margin-bottom: 6px;
			}
		}
		&__txt02 {
			@include g.fz(36);
			font-weight: 500;
			line-height: 1.4;
			@include g.tabletP {
				@include g.fz(24);
			}
		}
	}//.myCatBlockDetailIntroTitle

	.myCatBlockDetailIntroMv {
		$_: ".myCatBlockDetailIntroMv";
		display: flex;
		justify-content: center;
		text-align: center;
		margin-bottom: 57px;
		@include g.tabletP {
			margin-bottom: 40px;
		}
		&__caution {
			@include g.fz(14);
			margin-top: 13px;
			@include g.tabletP {
				@include g.fz(12);
				margin-top: 11px;
			}
		}
	}//.myCatBlockDetailIntroMv

	.myCatBlockDetailIntroImage {
		$_: ".myCatBlockDetailIntroImage";
		text-align: center;
		margin-bottom: 52px;
		@include g.tabletP {
			margin-bottom: 35px;
		}
		&__title {
			@include g.fz(28);
			line-height: 1.4;
			text-align: center;
			margin-bottom: 30px;
			@include g.tabletP {
				@include g.fz(23);
				margin-bottom: 20px;
			}
		}
	}//.myCatBlockDetailIntroImage

	.myCatBlockDetailIntroFeature {
		$_: ".myCatBlockDetailIntroFeature";
		text-align: center;
	}//.myCatBlockDetailIntroFeature

	.myCatTxtDetailFeatureLead {
		$_: ".myCatTxtDetailFeatureLead";
		@include g.fz(16);
		line-height: math.div(30, 16);
		text-align: center;
		@include g.tabletP {
			@include g.fz(13);
		}
	}//.myCatTxtDetailFeatureLead

	.myCatListDetailFeature {
		$_: ".myCatListDetailFeature";
		border-bottom: 1px solid #B8B8B8;
		&__item {
			&::before {
				content: none;
			}
		}
		&__inner {
			@include g.tabletPmin {
				display: flex;
				align-items: center;
				justify-content: space-between;
			}
			@include g.tabletP {
				@include g.tabletP {
					max-width: 450px;
					margin: 0 auto;
				}
			}
		}
		&__image {
			text-align: center;
			@include g.tabletPmin {
				max-width: 535px;
				width: calc(100% - 396px - 30px);
				@at-root #{$_root} #{$_}__item:nth-child(even) #{$_}__image {
					order: 1;
				}
			}
			@include g.tabletP {
				margin-bottom: 25px;
			}
			img {
				@include g.tabletPmin {
					width: 100%;
				}
			}
		}
		&__body {
			@include g.tabletPmin {
				display: flex;
				flex-direction: column;
				justify-content: center;
				flex-shrink: 0;
				width: 396px;
				margin-top: -6px;
			}
		}
		&__count {
			display: flex;
			align-items: center;
			color: nth(g.$mainColorProducts, 1);
			font-family: g.$fontAlpha;
			@include g.fz(26);
			font-weight: 300;
			line-height: 1;
			margin-bottom: 14px;
			@include g.tabletP {
				@include g.fz(22);
				margin-bottom: 12px;
			}
			&Num {
				@include g.fz(90);
				margin-left: 10px;
				@include g.tabletP {
					@include g.fz(60);
					margin-left: 9px;
				}
			}
		}
		&__title {
			@include g.fz(30);
			line-height: math.div(40, 30);
			margin-bottom: 16px;
			@include g.tabletP {
				@include g.fz(20);
				margin-bottom: 11px;
			}
		}
		&__txt {
			@include g.fz(16);
			@include g.tabletP {
				@include g.fz(13);
			}
		}
	}//.myCatListDetailFeature

	.myCatListDetailVoice {
		$_: ".myCatListDetailVoice";
		&__item {
			@include g.tabletPmin {
				display: flex;
			}
		}
		&__head {
			position: relative;
			@include g.tabletPmin {
				flex-shrink: 0;
				border-right: 1px solid currentColor;
				margin-right: 64px;
				padding-right: 81px;
			}
			@include g.tabletP {
				border-bottom: 1px solid currentColor;
				margin-bottom: 25px;
				padding-bottom: 35px;
			}
			&::before {
				content: "";
				background-color: #fff;
				position: absolute;
				margin: auto;
				@include g.tabletPmin {
					border-bottom: 1px solid currentColor;
					border-left: 1px solid currentColor;
					top: 63px;
					right: -17px;
					width: 32px;
					height: 32px;
					transform-origin: right top;
					transform: scale(.75) rotate(45deg);
				}
				@include g.tabletP {
					border-top: 1px solid currentColor;
					border-left: 1px solid currentColor;
					bottom: -13px;
					left: 0;
					right: 0;
					width: 25px;
					height: 25px;
					transform: scale(.75) rotate(45deg);

				}
			}
		}
		&__image {
			display: block;
			text-align: center;
			margin-bottom: 12px;
			@include g.tabletP {
				width: 85px;
				margin: 0 auto 10px;
			}
		}
		&__author {
			display: block;
			@include g.fz(15);
			font-weight: 500;
			line-height: math.div(25, 15);
			text-align: center;
			@include g.tabletP {
				@include g.fz(13);
			}
		}
		&__body {
			@include g.tabletPmin {
				margin-top: -9px;
			}
		}
		&__title {
			@include g.fz(26);
			font-weight: 500;
			line-height: math.div(40, 26);
			margin-bottom: 25px;
			@include g.tabletP {
				@include g.fz(22);
				margin-bottom: 21px;
			}
		}
		&__txt {
			@include g.fz(16);
			line-height: math.div(30, 16);
			@include g.tabletP {
				@include g.fz(13);
			}
			& + #{$_}__txt {
				margin-top: 0.5em;
			}
		}
		&__caution {
			@include g.fz(14);
			line-height: math.div(25, 14);
			margin-top: 15px;
			@include g.tabletP {
				@include g.fz(12);
				margin-top: 13px;
			}
		}
	}//.myCatListDetailVoice

// --- touch_panel
	$_id: touch_panel;
	$_imgPath:'../img/#{$_idParentTop}/#{$_idParent}/#{$_id}/';

	.myTouchpanelBlockDetailIntro {
		$_: ".myTouchpanelBlockDetailIntro";
		margin-bottom: 119px;
		@include g.tabletP {
			margin-bottom: 80px;
		}
	}//.myTouchpanelBlockDetailIntro

	.myTouchpanelBlockDetailIntroTitle {
		$_: ".myTouchpanelBlockDetailIntroTitle";
		display: flex;
		margin-bottom: 47px;
		@include g.tabletP {
			margin-bottom: 32px;
		}
		&__image {
			margin-right: 33px;
			@include g.tabletP {
				margin-right: 22px;
			}
		}
		&__txt01,
		&__txt02 {
			display: block;
		}
		&__txt01 {
			@include g.fz(18);
			line-height: 1.4;
			margin-bottom: 7px;
			@include g.tabletP {
				@include g.fz(15);
				margin-bottom: 6px;
			}
		}
		&__txt02 {
			@include g.fz(36);
			font-weight: 500;
			line-height: 1.4;
			@include g.tabletP {
				@include g.fz(24);
			}
			&Strong {
				color: nth(g.$mainColorProducts, 1);
			}
		}
	}//.myTouchpanelBlockDetailIntroTitle

	.myTouchpanelBlockDetailIntroFeature {
		$_: ".myTouchpanelBlockDetailIntroFeature";
		text-align: center;
	}//.myTouchpanelBlockDetailIntroFeature

	.myTouchpanelListDetailFeature {
		$_: ".myTouchpanelListDetailFeature";
		border-bottom: 1px solid #B8B8B8;
		&__item {
			&::before {
				content: none;
			}
		}
		&__inner {
			@include g.tabletPmin {
				display: flex;
				align-items: center;
				justify-content: space-between;
			}
			@include g.tabletP {
				@include g.tabletP {
					max-width: 450px;
					margin: 0 auto;
				}
			}
		}
		&__image,
		&__imagelist {
			@include g.tabletPmin {
				max-width: 535px;
				width: calc(100% - 396px - 30px);
				@at-root #{$_root} #{$_}__item:nth-child(even) #{$_}__image {
					order: 1;
				}
				@at-root #{$_root} #{$_}__item:nth-child(odd) #{$_}__imagelist {
					order: -1;
				}
			}
			img {
				@include g.tabletPmin {
					width: 100%;
				}
			}
		}
		&__image {
			text-align: center;
			@include g.tabletP {
				margin-bottom: 25px;
			}
		}
		&__imagelist {
			@include g.tabletP {
				margin-top: 12px;
			}
			&-item {
				&:not(:last-child) {
					margin-bottom: 16px;
					@include g.tabletP {
						margin-bottom: 13px;
					}
				}
			}
			&-title {
				@include g.fz(16);
				margin-bottom: 7px;
				@include g.tabletP {
					@include g.fz(13);
					margin-bottom: 6px;
				}
			}
		}
		&__body {
			@include g.tabletPmin {
				display: flex;
				flex-direction: column;
				justify-content: center;
				flex-shrink: 0;
				width: 396px;
				margin-top: -6px;
			}
		}
		&__count {
			display: flex;
			align-items: center;
			color: nth(g.$mainColorProducts, 1);
			font-family: g.$fontAlpha;
			@include g.fz(26);
			font-weight: 300;
			line-height: 1;
			margin-bottom: 14px;
			@include g.tabletP {
				@include g.fz(22);
				margin-bottom: 12px;
			}
			&Num {
				@include g.fz(90);
				margin-left: 10px;
				@include g.tabletP {
					@include g.fz(60);
					margin-left: 9px;
				}
			}
		}
		&__title {
			@include g.fz(30);
			line-height: math.div(40, 30);
			margin-bottom: 16px;
			@include g.tabletP {
				@include g.fz(20);
				margin-bottom: 11px;
			}
		}
		&__txt {
			@include g.fz(16);
			@include g.tabletP {
				@include g.fz(13);
			}
		}
		&__caution {
			@include g.fz(15);
			line-height: math.div(25, 15);
			margin-top: 15px;
			@include g.tabletP {
				@include g.fz(13);
				margin-top: 13px;
			}
		}
		&__infobox {
			border: 1px solid currentColor;
			@include g.fz(15);
			line-height: math.div(25, 15);
			margin-top: 17px;
			padding: 13px 23px 14px;
			@include g.tabletP {
				@include g.fz(12);
				margin-top: 14px;
				padding: 11px 19px 12px;
			}
			&-txt01 {
				color: nth(g.$mainColorProducts, 1);
				margin-bottom: 4px;
				@include g.tabletP {
					margin-bottom: 5px;
				}
			}
		}
	}//.myTouchpanelListDetailFeature

	.myTouchpanelTxtDetailViewLead {
		$_: ".myTouchpanelTxtDetailViewLead";
	}//.myTouchpanelTxtDetailViewLead

	.myTouchpanelBlockDetailView {
		$_: ".myTouchpanelBlockDetailView";
		&__title {
			@include g.fz(28);
			line-height: 1.4;
			text-align: center;
			margin-bottom: 22px;
			@include g.tabletP {
				@include g.fz(23);
				margin-bottom: 22px;
			}
		}
		&__lead {
			@include g.fz(16);
			line-height: math.div(30, 16);
			text-align: center;
			margin-bottom: 22px;
			@include g.tabletP {
				@include g.fz(13);
				margin-bottom: 22px;
			}
		}
		&__list {
			display: grid;
			grid-template: auto / repeat(2, 1fr);
			gap: 34px 90px;
			justify-content: space-between;
			@include g.tabletP {
				gap: 34px 60px;
			}
			@include g.max(450) {
				gap: 34px 45px;
			}
		}
		&__item {
			position: relative;
			&::before {
				content: none;
			}
			&::after {
				background: url(#{g.$cmnPath}icon_arrow01.svg) no-repeat center;
				background-size: 17px;
				border: 1px solid #fff;
				border-radius: 50%;
				filter: brightness(0) saturate(100%) invert(9%) sepia(40%) saturate(2196%) hue-rotate(203deg) brightness(98%) contrast(103%);
				position: absolute;
				top: 55px;
				bottom: 0;
				left: calc(100% + 28px);
				width: 35px;
				height: 35px;
				margin: auto;
				@include g.tabletP {
					background-size: 12px;
					left: calc(100% + 19px);
					width: 24px;
					height: 24px;
				}
				@include g.max(450) {
					background-size: 10px;
					left: calc(100% + 13px);
					width: 20px;
					height: 20px;
				}
			}
			&:nth-child(odd) {
				&::after {
					content: "";
				}
			}
		}
		&__head {
			line-height: 1;
			@include g.tabletPmin {
				display: flex;
				align-items: center;
				margin-bottom: 15px;
			}
			@include g.tabletP {
				margin-bottom: 13px;
			}
			&-count {
				display: flex;
				align-items: center;
				color: nth(g.$mainColorProducts, 1);
				font-family: g.$fontAlpha;
				@include g.fz(16);
				font-weight: 300;
				margin-right: 13px;
				@include g.tabletP {
					@include g.fz(13);
					margin-right: 11px;
				}
				&Num {
					@include g.fz(40);
					margin-left: 8px;
					@include g.tabletP {
						@include g.fz(27);
						margin-left: 7px;
					}
					@include g.max(450) {
						@include g.fz(22);
						margin-left: 6px;
					}
				}
			}
			&-title {
				@include g.fz(20);
				@include g.tabletP {
					@include g.fz(17);
					margin-top: 4px;
				}
				@include g.max(450) {
					@include g.fz(14);
				}
			}
		}
		&__image {
			img {
				width: 100%;
			}
		}
	}//.myTouchpanelBlockDetailView

// --- smart_laundry
	$_id: smart_laundry;
	$_imgPath:'../img/#{$_idParentTop}/#{$_idParent}/#{$_id}/';

	.mySmartlaundryBlockMv {
		$_: ".mySmartlaundryBlockMv";
		text-align: center;
	}//.mySmartlaundryBlockMv

	.mySmartlaundryBlock01 {
		$_: ".mySmartlaundryBlock01";
		&__title {
			@include g.fz(36);
			font-weight: 500;
			line-height: math.div(50, 36);
			margin-bottom: 29px;
			margin-right: -10px;
			@include g.tabletP {
				@include g.fz(24);
				margin-bottom: 21px;
				margin-right: 0;
			}
			&Strong {
				color: nth(g.$mainColorProducts, 1);
			}
		}
		& + #{$_} {
			border-top: 1px solid #B8B8B8;
		}
	}//.mySmartlaundryBlock01

	.mySmartlaundryBlock01Child {
		$_: ".mySmartlaundryBlock01Child";
		&:not(:last-child) {
			margin-bottom: 65px;
			@include g.tabletP {
				margin-bottom: 44px;
			}
		}
		&__phonebox {
			@include g.tabletPmin {
				display: flex;
				justify-content: space-between;
			}
		}
		&__phone {
			@include g.tabletPmin {
				text-align: right;
				width: 245px;
				margin-left: 30px;
			}
			@include g.tabletP {
				text-align: center;
				margin-top: 26px;
			}
			&.-type02 {
				@include g.tabletPmin {
					padding-top: 57px;
					padding-right: 41px;
				}
			}
		}
		&__detail {
			&:not(:last-child) {
				margin-bottom: 42px;
				@include g.tabletP {
					margin-bottom: 28px;
				}
			}
			&.-type02 {
				@include g.tabletPmin {
					display: flex;
					flex-wrap: wrap;
					justify-content: space-between;
				}
			}
			@at-root #{$_root} #{$_}__phonebox #{$_}__detail {
				@include g.tabletPmin {
					flex: 1;
					max-width: 692px;
				}
			}
		}
		&__imagebox {
			@include g.tabletPmin {
				display: grid;
				grid-template: 1fr / repeat(2, 1fr);
				gap: 28px 28px;
				justify-content: space-between;
				margin-bottom: 23px;
				@at-root #{$_root} #{$_}__detail.-type02 #{$_}__imagebox {
					display: block;
					max-width: 486px;
					width: calc(100% - 426px - 30px);
					margin-bottom: 0;
				}
			}
			@include g.tabletP {
				margin-bottom: 19px;
			}
		}
		&__image {
			text-align: center;
			@include g.tabletP {
				&:not(:last-child) {
					margin-bottom: 23px;
				}
			}
			img {
				@include g.tabletPmin {
					width: 100%;
				}
			}
		}
		&__body {
			@include g.tabletPmin {
				@at-root #{$_root} #{$_}__detail.-type02 #{$_}__body {
					width: 426px;
				}
			}
		}
		&__txt {
			@include g.fz(16);
			line-height: math.div(30, 16);
			@include g.tabletP {
				@include g.fz(13);
			}
			&:not(:last-child) {
				margin-bottom: 33px;
				@include g.tabletP {
					margin-bottom: 22px;
				}
			}
		}
	}//.mySmartlaundryBlock01Child

	.mySmartlaundryBlock01Child02 {
		$_: ".mySmartlaundryBlock01Child02";
		margin-top: 71px;
		@include g.tabletP {
			margin-top: 48px;
		}
	}//.mySmartlaundryBlock01Child02

	.mySmartlaundryBlockDetailVoice {
		$_: ".mySmartlaundryBlockDetailVoice";
		&__title {
			margin-bottom: 19px;
			@include g.tabletP {
				margin-bottom: 16px;
			}
		}
		&__lead {
			@include g.fz(16);
			line-height: math.div(30, 16);
			margin-bottom: 32px;
			@include g.tabletP {
				@include g.fz(13);
				margin-bottom: 22px;
			}
		}
	}//.mySmartlaundryBlockDetailVoice

	.mySmartlaundryListDetailVoice {
		$_: ".mySmartlaundryListDetailVoice";
		&__item {
			@include g.tabletPmin {
				display: flex;
			}
		}
		&__head {
			position: relative;
			@include g.tabletPmin {
				flex-shrink: 0;
				border-right: 1px solid currentColor;
				margin-right: 35px;
				padding-top: 14px;
				padding-right: 45px;
			}
			@include g.tabletP {
				border-bottom: 1px solid currentColor;
				margin-bottom: 25px;
				padding-bottom: 35px;
			}
			&::before {
				content: "";
				background-color: #fff;
				position: absolute;
				margin: auto;
				@include g.tabletPmin {
					border-bottom: 1px solid currentColor;
					border-left: 1px solid currentColor;
					top: 63px;
					right: -17px;
					width: 32px;
					height: 32px;
					transform-origin: right top;
					transform: scale(.75) rotate(45deg);
				}
				@include g.tabletP {
					border-top: 1px solid currentColor;
					border-left: 1px solid currentColor;
					bottom: -13px;
					left: 0;
					right: 0;
					width: 25px;
					height: 25px;
					transform: scale(.75) rotate(45deg);

				}
			}
		}
		&__image {
			display: block;
			text-align: center;
			margin-bottom: 12px;
			@include g.tabletP {
				width: 85px;
				margin: 0 auto 10px;
			}
		}
		&__body {
			@include g.tabletPmin {
				margin-top: -2px;
			}
		}
		&__title {
			@include g.fz(20);
			line-height: math.div(30, 20);
			margin-bottom: 15px;
			@include g.tabletP {
				@include g.fz(17);
				margin-bottom: 13px;
			}
		}
		&__txt {
			@include g.fz(16);
			line-height: math.div(30, 16);
			@include g.tabletP {
				@include g.fz(13);
			}
		}
	}//.mySmartlaundryListDetailVoice

	.mySmartlaundryTable01 {
		$_: ".mySmartlaundryTable01";
		th {
			text-align: center;
		}
		td {
			text-align: left;
		}
		&__title {
			display: inline-block;
			font-size: 1.125em;
			margin-bottom: 13px;
			@include g.tabletP {
				margin-bottom: 11px;
			}
		}
	}//.mySmartlaundryTable01

	.mySmartlaundryBlockMedal {
		$_: ".mySmartlaundryBlockMedal";
		border: 1px solid #B8B8B8;
		@include g.tabletPmin {
			display: flex;
			align-items: flex-end;
			justify-content: space-between;
			margin-bottom: 39px;
			padding: 26px 35px 0 41px;
		}
		@include g.tabletP {
			margin-bottom: 26px;
			padding: 23px 24px 0;
		}
		&__image {
			text-align: center;
			@include g.tabletPmin {
				flex-shrink: 0;
				margin-right: 18px;
			}
			@include g.tabletP {
				margin-bottom: -20px;
			}
		}
		&__body {
			@include g.tabletPmin {
				flex: 1;
				padding-bottom: 32px;
			}
			@include g.tabletP {
				padding-bottom: 22px;
			}
		}
		&__title {
			@include g.fz(20);
			line-height: math.div(30, 20);
			margin-bottom: 9px;
			@include g.tabletP {
				@include g.fz(17);
				margin-bottom: 8px;
			}
		}
		&__txt {
			@include g.fz(15);
			line-height: math.div(25, 15);
			@include g.tabletP {
				@include g.fz(13);
			}
		}
	}//.mySmartlaundryBlockMedal

	.mySmartlaundryBlockUpdate {
		$_: ".mySmartlaundryBlockUpdate";
		&__image {
			text-align: center;
			margin-bottom: 62px;
			@include g.tabletP {
				margin-bottom: 42px;
			}
		}
		&__lead {
			@include g.fz(28);
			line-height: math.div(30, 28);
			text-align: center;
			margin-bottom: 29px;
			@include g.tabletP {
				@include g.fz(23);
				margin-bottom: 24px;
			}
		}
		&__list {
			margin: 0 auto;
			@include g.tabletPmin {
				display: grid;
				grid-template: 1fr / repeat(2, 1fr);
				gap: 30px 30px;
				justify-content: space-between;
				max-width: 870px;
			}
			@include g.tabletP {
				max-width: 400px;
			}
		}
		&__item {
			border: 1px solid #B8B8B8;
			text-align: center;
			@include g.tabletPmin {
				padding: 15px 25px 18px;
			}
			@include g.tabletP {
				padding: 13px 21px 15px;
				&:not(:last-child) {
					margin-bottom: 26px;
				}
			}
			&-txt {
				@include g.fz(18);
				font-weight: 500;
				line-height: math.div(30, 18);
				margin-bottom: 9px;
				@include g.tabletP {
					@include g.fz(15);
					margin-bottom: 8px;
				}
				&Strong {
					color: nth(g.$mainColorProducts, 1);
				}
			}
			&-name {
				background-color: #6F83B5;
				color: #fff;
				@include g.fz(22);
				font-weight: 500;
				line-height: math.div(30, 22);
				padding: 3px 10px 7px;
				@include g.tabletP {
					@include g.fz(18);
					padding: 3px 9px 6px;
				}
			}
		}
	}//.mySmartlaundryBlockUpdate

	.mySmartlaundryListDetailFeature {
		$_: ".mySmartlaundryListDetailFeature";
		&__item {
			&::before {
				content: none;
			}
		}
		&__inner {
			@include g.tabletPmin {
				display: flex;
				align-items: center;
				justify-content: space-between;
			}
			@include g.tabletP {
				@include g.tabletP {
					max-width: 450px;
					margin: 0 auto;
				}
			}
		}
		&__image {
			text-align: center;
			@include g.tabletPmin {
				max-width: 535px;
				width: calc(100% - 396px - 30px);
				@at-root #{$_root} #{$_}__item:nth-child(even) #{$_}__image {
					order: 1;
				}
			}
			@include g.tabletP {
				margin-bottom: 25px;
			}
			img {
				@include g.tabletPmin {
					width: 100%;
				}
			}
		}
		&__body {
			@include g.tabletPmin {
				display: flex;
				flex-direction: column;
				justify-content: center;
				flex-shrink: 0;
				width: 396px;
				margin-top: -6px;
			}
		}
		&__count {
			display: flex;
			align-items: center;
			color: nth(g.$mainColorProducts, 1);
			font-family: g.$fontAlpha;
			@include g.fz(26);
			font-weight: 300;
			line-height: 1;
			margin-bottom: 14px;
			@include g.tabletP {
				@include g.fz(22);
				margin-bottom: 12px;
			}
			&Num {
				@include g.fz(90);
				margin-left: 10px;
				@include g.tabletP {
					@include g.fz(60);
					margin-left: 9px;
				}
			}
		}
		&__title {
			@include g.fz(30);
			line-height: math.div(40, 30);
			margin-bottom: 16px;
			@include g.tabletP {
				@include g.fz(20);
				margin-bottom: 11px;
			}
		}
		&__catch {
			@include g.fz(20);
			line-height: math.div(30, 20);
			margin-bottom: 3px;
			@include g.tabletP {
				@include g.fz(17);
				margin-bottom: 2px;
			}
		}
		&__txt {
			@include g.fz(16);
			@include g.tabletP {
				@include g.fz(13);
			}
		}
	}//.mySmartlaundryListDetailFeature


	.mySmartlaundryBlockCase {
		$_: ".mySmartlaundryBlockCase";
		border-bottom: 1px solid #B8B8B8;
	}//.mySmartlaundryBlockCase

	.mySmartlaundryBlockApp {
		$_: ".mySmartlaundryBlockApp";
		background-color: #F1F2F4;
		@include g.tabletPmin {
			display: flex;
			align-items: flex-end;
			justify-content: center;
			margin-bottom: 69px;
			padding: 40px 40px 40px;
		}
		@include g.tabletP {
			max-width: 450px;
			margin: 0 auto 46px;
			padding: 27px 27px 27px;
		}
		&__image {
			text-align: center;
			@include g.tabletPmin {
				width: percentage(math.div(228, 836));
				margin-bottom: -40px;
				margin-right: 40px;
			}
			@include g.tabletP {
				margin-bottom: 30px;
			}
		}
		&__body {
			background-color: #fff;
			@include g.tabletPmin {
				display: grid;
				grid-template: auto / repeat(2, auto);
				gap: 0 27px;
				justify-content: center;
				max-width: 558px;
				width: percentage(math.div(558, 836));
				padding: 40px 40px 40px;
			}
			@include g.tabletP {
				padding: 26px 26px 26px;
			}
		}
		&__item {
			text-align: center;
			@include g.tabletP {
				&:not(:last-child) {
					margin-bottom: 40px;
				}
			}
			&-qr {
				margin-bottom: 7px;
			}
			&-txt {
				@include g.fz(16);
				line-height: math.div(30, 16);
				margin-bottom: 12px;
				@include g.tabletP {
					@include g.fz(13);
					margin-bottom: 9px;
				}
			}
		}
	}//.mySmartlaundryBlockApp

// --- prepaid_card
	$_id: prepaid_card;
	$_imgPath:'../img/#{$_idParentTop}/#{$_idParent}/#{$_id}/';

	.myPrepaidCardBlockMv {
		$_: ".myPrepaidCardBlockMv";
		text-align: center;
		margin-bottom: 40px;
	}//.myPrepaidCardBlockMv

	.myPrepaidCardBlockLead {
		$_: ".myPrepaidCardBlockLead";
		@include g.fz(36);
		font-weight: 500;
		line-height: math.div(50, 36);
		@include g.tabletP {
			@include g.fz(24);
		}
		&__strong {
			color: nth(g.$mainColorProducts, 1);
		}
	}//.myPrepaidCardBlockLead

	.myPrepaidCardBlock01 {
		$_: ".myPrepaidCardBlock01";
		border-bottom: 1px solid #B8B8B8;
		&__txt {
			letter-spacing: 0;
			line-height: math.div(30, 16);
			margin-bottom: 72px;
			@include g.tabletP {
				margin-bottom: 24px;
			}
		}
		&__imagebox {
			display: flex;
			justify-content: space-between;
			text-align: center;
			gap: 30px 19px;
			@include g.tabletPmin {
				align-items: center;
			}
			@include g.tabletP {
				flex-direction: column;
			}
		}
	}//.myPrepaidCardBlock01

	.myPrepaidCardBlock02 {
		$_: ".myPrepaidCardBlock02";
		&Section01 {
			$_2: #{$_}Section01;
			&:not(:last-child) {
				margin-bottom: 61px;
			}
			&__image {
				text-align: center;
			}
			&__contents01 {
				display: grid;
				justify-content: space-between;
				gap: 39px;
				@include g.tabletPmin {
					grid-template-columns: percentage(math.div(535, 1000)) auto;
				}
				@include g.tabletP {
					grid-template-columns: 1fr;
				}
			}
			&__contents02 {
				background-color: nth(g.$subColorProducts, 4);
				display: grid;
				gap: 21px 76px;
				margin-top: 49px;
				padding: 53px 36px 45px;
				@include g.tabletP {
					grid-template-columns: 1fr;
					padding: 30px g.$containerPadding 26px;
				}
			}
		}//.myPrepaidCardBlock02Section01

		&Section02 {
			$_2: #{$_}Section02;
		}//.myPrepaidCardBlock02Section02

		&Order {
			$_2: #{$_}Order;
			letter-spacing: 0;
			line-height: math.div(30, 16);
			margin-top: 29px;
		}//.myPrepaidCardBlock02Order

		&Info {
			$_2: #{$_}Info;
			background-color: #fff;
			letter-spacing: 0;
			line-height: math.div(30, 16);
			@include g.tabletPmin {
				grid-column: span 2;
				padding: 23px 33px 22px;
			}
			@include g.tabletP {
				padding: 15px g.$containerPadding 14px;
			}
		}//.myPrepaidCardBlock02Info
	}//.myPrepaidCardBlock02


// --- dynamic_pricing
	$_id: dynamic_pricing;
	$_imgPath:'../img/#{$_idParentTop}/#{$_idParent}/#{$_id}/';

	.myDynamicPricingBlockMv {
		$_: ".myDynamicPricingBlockMv";
		text-align: center;
		margin-bottom: 40px;
	}//.myDynamicPricingBlockMv

	.myDynamicPricingBlockLead {
		$_: ".myDynamicPricingBlockLead";
		@include g.fz(36);
		font-weight: 500;
		line-height: math.div(50, 36);
		margin-bottom: 52px;
		@include g.tabletP {
			@include g.fz(24);
			margin-bottom: 30px;
		}
		&__strong {
			color: nth(g.$mainColorProducts, 1);
		}
	}//.myDynamicPricingBlockLead

	.myDynamicPricingBlockIntro {
		$_: ".myDynamicPricingBlockIntro";
		border-bottom: 1px solid #B8B8B8;
		&__txt {
			margin-bottom: 50px;
		}
		&__movie {
			max-width: 487px;
			margin: 0 auto;
			> * {
				aspect-ratio: 487 / 306;
				max-width: 100%;
				height: auto;
			}
		}
	}//.myDynamicPricingBlockIntro

	.myDynamicPricingBlock01 {
		$_: ".myDynamicPricingBlock01";
		border-bottom: 1px solid #B8B8B8;
		&__intro {
			display: grid;
			grid-template-columns: 1fr auto;
			gap: 29px;
			justify-content: space-between;
			margin-bottom: 69px;
			@include g.sphoneP {
				grid-template-columns: 1fr;
			}
			&-txt {
				@include g.sphonePmin {
					grid-row: 1;
					grid-column: 1;
				}
			}
			&-image01 {
				text-align: center;
				@include g.sphonePmin {
					grid-row: 1;
					grid-column: 2;
				}
			}
			&-image02 {
				@include g.sphonePmin {
					grid-row: 2;
					grid-column: 1 / 3;
				}
				@include g.tabletPmin {
					margin: 0 -1px;
				}
				img {
					text-align: center;
				}
			}
		}
		&Section {
			$_2: #{$_}Section;
			&:not(:last-child) {
				margin-bottom: 78px;
			}
			&__contents01 {
				display: grid;
				grid-template-columns: minmax(auto, 486px) 426px;
				gap: 24px 28px;
				justify-content: space-between;
				@include g.tabletP {
					grid-template-columns: 1fr;
				}
				&:not(:last-child) {
					margin-bottom: 70px;
				}
			}
			&__contents02 {
				display: grid;
				grid-template-columns: repeat(2, 1fr);
				gap: 24px 28px;
				#{$_2}__txt {
					grid-column: 1 / 3;
				}
			}
			&__image {
				text-align: center;
			}
		}//.myDynamicPricingBlock01Section
	}//.myDynamicPricingBlock01

	.myDynamicPricingBlock02 {
		$_: ".myDynamicPricingBlock02";
		&Section {
			$_2: #{$_}Section;
			&__contents {
				display: grid;
				grid-template-columns: minmax(auto, 486px) 426px;
				gap: 24px 28px;
				justify-content: space-between;
				@include g.tabletP {
					grid-template-columns: 1fr;
				}
				&:not(:last-child) {
					margin-bottom: 70px;
				}
			}
			&__image {
				text-align: center;
			}
		}//.myDynamicPricingBlock02Section
		&Info {
			$_2: #{$_}Info;
			background-color: nth(g.$subColorProducts, 4);
			display: grid;
			grid-template: auto 1fr / auto 1fr;
			gap: 32px 42px;
			margin-top: -22px;
			margin-bottom: 61px;
			padding: 36px 43px 44px;
			@include g.tabletP {
				gap: 18px 22px;
				padding: 20px 26px 22px;
			}
			@include g.sphoneP {
				align-items: center;
			}
			&__title {
				grid-row: 1;
				grid-column: 2;
				color: g.$mainColorProducts;
				@include g.fz(22);
				font-weight: 500;
				letter-spacing: 0;
				line-height: math.div(32, 22);
				padding-top: 10px;
				@include g.tabletP {
					@include g.fz(18);
				}
				@include g.sphoneP {
					grid-row: 1;
					grid-column: 1 / 3;
					display: flex;
					justify-content: center;
					padding-top: 0;
				}
			}
			&__image {
				grid-row: 1 / 3;
				grid-column: 1;
				@include g.sphoneP {
					grid-row: 2;
					width: 90px;
				}
			}
			&__txt {
				grid-row: 2;
				grid-column: 2;
			}
		}//.myDynamicPricingBlock02Info
	}//.myDynamicPricingBlock02

	.myDynamicPricingBlock03 {
		$_: ".myDynamicPricingBlock03";
		&__intro {
			display: grid;
			grid-template-rows: auto 1fr;
			grid-template-columns: minmax(auto, 486px) 426px;
			gap: 24px 28px;
			justify-content: space-between;
			margin-bottom: 70px;
			@include g.tabletP {
				grid-template: auto / 1fr;
			}
			&-image01 {
				text-align: center;
				@include g.tabletPmin {
					grid-row: 1 / 3;
					grid-column: 1;
				}
			}
			&-txt {
				@include g.tabletPmin {
					grid-row: 1;
					grid-column: 2;
				}
			}
			&-image02 {
				@include g.tabletPmin {
					grid-row: 2;
					grid-column: 2;
				}
				@include g.tabletP {
					text-align: center;
				}
			}
		}
		&Section {
			$_2: #{$_}Section;
			&__contents {
				display: grid;
				grid-template-rows: auto 1fr;
				grid-template-columns: minmax(auto, 486px) 426px;
				gap: 24px 28px;
				justify-content: space-between;
				@include g.tabletP {
					grid-template: auto / 1fr;
				}
			}
			&__image {
				text-align: center;
				@include g.tabletPmin {
					grid-row: 1 / 3;
					grid-column: 1;
				}
			}
			&__catch {
				color: #000;
				font-size: math.div(22, 16) * 1em;
				font-weight: 700;
				letter-spacing: 0;
				line-height: math.div(32, 22);
				@include g.tabletPmin {
					grid-row: 1;
					grid-column: 2;
				}
			}
			&__txt {
				@include g.tabletPmin {
					grid-row: 2;
					grid-column: 2;
				}
			}
		}//.myDynamicPricingBlock03Section
	}//.myDynamicPricingBlock03
}
