@charset "utf-8";

/*
#overview
modTxt
*/

@use "global" as g;
@use 'sass:math';

/*
#styleguide
modTxtProductsOption

```
<p class="modTxtProductsOption">熱水洗濯　80℃、10分</p>
<p class="modTxtProductsOption">シーツ投入、仕上げ、タタミまで1台でこなす<span class="modTxtProductsOption__txtStrong">オールインワン</span>タイプ</p>
<p class="modTxtProductsOptionInfo">※RS90はオールインワンタイプではありません。</p>
```
*/
.modTxtProductsOption {
	color: nth(g.$mainColorProducts, 1);
	@include g.fz(28);
	line-height: math.div(30, 28);
	margin-bottom: 34px;
	@include g.tabletP {
		@include g.fz(22.4);
		margin-bottom: 23px;
	}
	&__txtStrong {
		font-weight: bold;
	}
	&Info {
		@include g.fz(15);
		margin-top: -19px;
		margin-bottom: 34px;
		@include g.tabletP {
			@include g.fz(13);
			margin-top: -13px;
			margin-bottom: 23px;
		}
	}//.modTxtProductsOptionInfo
}//.modTxtProductsOption

/*
#styleguide
modTxtLinkMovie

```
<a href="#" class="modTxtLinkMovie" target="_blank">動画を見る</a>
```
*/
.modTxtLinkMovie{

	display: inline-block;
	position: relative;
	padding-top: 35px;
	text-decoration: none;
	@include g.fz(12);
	line-height: math.div(28,12);
	@include g.tabletP{
		padding-top: 25px;
		@include g.fz(10);
		line-height: 1.4;
	}

	&::after{
		content:"";
		width: 49px;
		height: 35px;
		background-image: url(#{g.$cmnPath}icon_movie.svg);
		background-position: 0 0;
		background-size: 100% auto;
		background-repeat: no-repeat;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		margin: 0 auto;
		@include g.tabletP{
			width: 30px;
			height: 21px;
		}
	}


	&::before {
		content: "";
		border-top: 1px solid currentColor;
		border-right: 1px solid currentColor;
		width: 8px;
		height: 8px;
		transform: rotate(45deg);
		display: inline-block;
		margin-right: 10px;
		position: relative;
		top:-0.1em;
		@include g.tabletP{
			width: 6px;
			height: 6px;
			margin-right: 6px;
		}
	}
}//.modTxtLinkMovie

/*
#styleguide
modTxtLink01

```
<a href="#" class="modTxtLink01">
	<span class="modTxtLink01__inner">Win161の取扱説明書・外観図・CADを検索する</span>
</a>
```
*/
.modTxtLink01{
	$_:&;

	display: inline-block;
	position: relative;
	text-decoration: none;
	line-height: 1.7;
	@include g.tabletP{
	}

	@media(hover: hover) {
		&:hover{
			#{$_}__inner{
				border-bottom:none;
			}
		}
	}

	&::before {
		content: "";
		border-top: 1px solid currentColor;
		border-right: 1px solid currentColor;
		width: 8px;
		height: 8px;
		transform: rotate(45deg);
		display: inline-block;
		margin-right: 10px;
		position: relative;
		top:-0.1em;
		@include g.tabletP{
			width: 6px;
			height: 6px;
			margin-right: 6px;
		}
	}

	&__inner{
		border-bottom: 1px solid nth(g.$baseFontColorProducts,1);
	}
}//.modTxtLink01